<template>
  <p
    :class="textCenteredAligned ? 'text-center w-100' : 'text-left'"
    :style="customStyles"
    v-if="isPara"
  >
    {{ elementText }} <b>{{ elementValue }}</b>
  </p>
  <small
    :class="textCenteredAligned ? 'text-center w-100' : 'text-left'"
    v-if="isSmall"
  >
    {{ elementText }} {{ elementValue }}
  </small>
  <div
    class="small-text-element"
    :class="textCenteredAligned ? 'text-center w-100' : 'text-left'"
    v-if="isLinkSmall"
    :style="customStyles"
  >
    <span>{{ elementText }}</span>
    <button
      class="v-btn v-btn--size-small v-btn--variant-plain text-primary text-lowercase pl-1"
      @click="handleAction"
    >
      {{ elementLinkVal }}
    </button>
  </div>
  <div
    class="small-text-element__icon"
    :class="textCenteredAligned ? 'text-center w-100' : 'text-left'"
    v-if="hasIcon"
    :style="customStyles"
  >
    <img class="mr-3" :src="getImage(imageName)" width="24" height="24" />
    <span>{{ elementValue }}</span>
  </div>
  <div
    class="small-text-element"
    :class="textCenteredAligned ? 'text-center w-100' : 'text-left'"
    v-if="hasIconWithElementText"
    :style="customStyles"
  >
    <img class="mr-3" :src="getImage(imageName)" width="24" height="24" />
    <span>{{ elementText }}</span>
  </div>
  <div
    class="small-text-element"
    :class="textCenteredAligned ? 'text-center w-100' : 'text-left'"
    v-if="hasIconWithElementValue"
    :style="customStyles"
  >
    <span v-if="elementText"> {{ elementText }}</span>
    <p style="display: flex; align-items: center">
      <img
        v-if="prependIcon"
        class="ml-3"
        :src="getImage(imageName)"
        width="24"
        height="24"
      />
      <span :style="customStylesElement">{{ elementValue }}</span>
      <img
        v-if="appendIcon"
        class="ml-3"
        :src="getImage(imageName)"
        width="24"
        height="24"
      />
    </p>
  </div>
</template>

<script>
import { reactive, computed } from "@vue/reactivity";
import { useStore } from "vuex";
import ActionHandler from "@/handlers/action";
import { getImage } from "@/utils/utils";
import useRuleParser from "@/composables/useRuleParser";

export default {
  name: "TextElementWValueWidget",
  props: {
    id: String,
    params: Object,
  },
  setup(props) {
    const {
      elementText,
      isPara,
      isSmall,
      textCenteredAligned,
      customStyles,
      elementKey,
      elementLinkVal,
      isLinkSmall,
      onAction,
      imageName,
      visibleButtonWhen,
      hasIcon,
      hasIconWithElementText,
      customStylesElement,
      prependIcon,
      appendIcon,
      hasIconWithElementValue,
    } = reactive(props.params);
    const store = useStore();
    const widgetId = reactive(props.id);

    const elementValue = computed({
      get() {
        return store.state.formData[elementKey];
      },
    });
    function handleAction() {
      ActionHandler.handleAction(onAction, widgetId, {});
    }

    return {
      elementText,
      isPara,
      isSmall,
      textCenteredAligned,
      customStyles,
      elementKey,
      elementValue,
      elementLinkVal,
      isLinkSmall,
      handleAction,
      onAction,
      getImage,
      imageName,
      useRuleParser,
      visibleButtonWhen,
      hasIconWithElementText,
      hasIcon,
      customStylesElement,
      prependIcon,
      appendIcon,
      hasIconWithElementValue,
    };
  },
};
</script>

<style lang="scss">
p {
  font-size: $font-size-base;
  line-height: 24px;
  font-weight: 400;
}

.small-text-element {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: $font-size-sm;

  span {
    font-size: $font-size-sm;
  }

  button {
    letter-spacing: normal !important;
  }
}

.small-text-element__icon {
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: $font-size-sm;

  span {
    font-size: $font-size-sm;
  }

  button {
    letter-spacing: normal !important;
  }
}
</style>
