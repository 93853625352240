import ActionHandler from "@/handlers/action";
import clevertap from "clevertap-web-sdk";
import { getCookieValue } from "@/services/HttpClient";
import store from "@/store/store";
class CleaverTap {
  constructor() {
    const CT_ACCOUNT_ID = import.meta.env.VITE_CLEVERTAP_ID;
    const CT_REGION = "in1";
    // const CT_DOMAIN = "kyc.angelone.in";
    clevertap.init(CT_ACCOUNT_ID, CT_REGION);
    sessionStorage["WZRK_D"] = "";
    if (import.meta.env.VITE_BUILD_RELEASE != "PROD") {
      clevertap.setLogLevel(3);
    } else {
      sessionStorage.removeItem("WZRK_D");
    }
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(function (position) {
    //     clevertap.getLocation(
    //       position.coords.latitude,
    //       position.coords.longitude
    //     );
    //   });
    // } else {
    //   console.error("Sorry, your browser does not support HTML5 geolocation.");
    // }
  }

  /**
   * @param {string} eventName string
   * @param {object} eventPayload  object
   */
  static addEvents(eventName = "", eventPayload = {}) {
    const payload = CleaverTap.dataBuilder(eventPayload);
    clevertap.event.push(eventName, { ...payload });
  }

  /**
   * @param {object} payload  object
   */
  static cleaverTapUserlogin(payload = {}) {
    const value = CleaverTap.dataBuilder(payload);
    clevertap.onUserLogin.push({
      Site: {
        // optional fields. controls whether the user will be sent email, push etc.
        "MSG-email": true, // Disable email notifications
        "MSG-push": true, // Enable push notifications
        "MSG-sms": true, // Enable sms notifications
        "MSG-whatsapp": true, // Enable WhatsApp notifications
        ...value,
      },
    });
  }

  /**
   * @param {object} payload  object
   */
  static clevertapUpdateProfile(payload) {
    const value = CleaverTap.dataBuilder(payload);
    clevertap.profile.push({
      Site: { ...value },
    });
  }

  /**
   * @param {object} payload  object
   */
  static userprofile(payload) {
    switch (payload?.type) {
      case "createUser":
        CleaverTap.cleaverTapUserlogin(payload?.data);
        break;
      case "updateUser":
        CleaverTap.clevertapUpdateProfile(payload?.data);
        break;
      default:
        break;
    }
  }

  static logout() {
    return clevertap.logout();
  }

  static dataBuilder(params) {
    const parsedBody = {};
    for (const key in params) {
      const value = params[key];
      if (value && value.toString().includes(":")) {
        let [type, keyParam] = value.split(":");
        if (type == "STATE" && store.state.formData[keyParam]) {
          let k;
          switch (key) {
            case "Gender":
              k = store.state.formData[keyParam].charAt(0).toUpperCase();
              parsedBody[key] = k;
              break;
            case "Phone":
              k =
                import.meta.env.VITE_COUNTRY_CODE +
                store.state.formData[keyParam];
              parsedBody[key] = k;
              break;
            case "Identity":
              k =
                import.meta.env.VITE_COUNTRY_CODE +
                store.state.formData[keyParam];
              parsedBody[key] = k;
              break;
            // case "panEndsWith":
            //    k =  store.state.formData[keyParam];
            //   parsedBody[key] = k;
            //   break;

            default:
              parsedBody[key] = store.state.formData[keyParam];
              break;
          }
        }
      } else {
        parsedBody[key] = value;
      }
    }

    //     function showLast4Digits(input) {
    //   // Convert input to string to handle both numbers and strings
    //   let inputString = String(input);

    //   // Check if the input has at least 4 characters
    //   if (inputString.length >= 4) {
    //     // Use slice to get the last 4 characters
    //     let last4Digits = inputString.slice(-4);

    //     // Display the result
    //     console.log(last4Digits);
    //   } else {
    //     console.log("Input should have at least 4 characters");
    //   }
    // }
    // parsedBody.AUC = sto
    parsedBody["DeviceId"] =
      getCookieValue("platform") || ActionHandler.fnGenerateUniqueID();
    parsedBody["appName"] = "Kyc-web";
    parsedBody["App_ID"] = "Kyc-web";
    parsedBody["platform"] = "web";
    return parsedBody;
  }
}

export default CleaverTap;
