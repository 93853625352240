<template>
  <div class="container">
    <v-radio-group v-model="value">
      <section
        v-for="(option, idx) in FUND_SETTLEMENT_OPTIONS"
        :key="idx + option.value"
        style="margin-top: 4px"
      >
        <v-radio
          :value="option.value"
          style="color: #3f5bd9"
          @click="closeFrequencyPopup(option.value)"
        >
          <template v-slot:label>
            <div class="labelContainer">
              <div class="labelWithImg">
                <span class="label">{{ option.label }}</span>
                <img
                  v-if="option?.labelImg"
                  :src="option?.labelImg"
                  style="margin-left: 16px; margin-top: 4px"
                />
              </div>
              <p
                class="subLabel"
                :style="{
                  color: value === option.value ? 'black' : '#69717a',
                }"
              >
                {{ option.subLabel }}
              </p>
              <p v-if="option.value === 'BILLTOBILL'" class="warningContainer">
                Daily fund addition is required prior to trading. This option is
                less preferred among regular traders.
              </p>
            </div>
          </template>
        </v-radio>
      </section>
    </v-radio-group>
  </div>
</template>

<script setup>
import useFormValue from "@/composables/useFormValue";
import { computed, reactive, ref } from "vue";
import { getImage } from "@/utils/utils";
import { useStore } from "vuex";

const props = defineProps({
  params: Object,
  id: String,
  layoutData: Object,
});
const widgetId = reactive(props.id);
const { value } = useFormValue(widgetId);

const store = useStore();

const FUND_SETTLEMENT_OPTIONS = [
  {
    label: "90 Days",
    subLabel:
      "Get back your unused funds in your bank account on first Friday of every quarter",
    value: "QUARTERLY",
    labelImg: getImage("mostPrefered.svg"),
  },
  {
    label: "30 Days",
    subLabel:
      "Get back your unused funds in your bank account on first Friday of every month",
    value: "MONTHLY",
  },
  {
    label: "Bill to Bill Settlement",
    subLabel: "Get back your unused funds in your bank account daily.",
    value: "BILLTOBILL",
  },
];

const closeFrequencyPopup = (optionSelected) => {
  value.value = optionSelected;
  store.dispatch("closePopup", "fundSettlementFrequency");
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
}

.labelContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 4px;
  color: black;
}

.label {
  color: black;
  font-weight: 800;
  text-align: left;
}

.subLabel {
  text-align: left;
}

.labelWithImg {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3px;
  gap: 4px;
}

.warningContainer {
  background-color: #feeceb;
  padding: 6px;
  border-radius: 8px;
}

.v-selection-control {
  align-items: start;
}
</style>
