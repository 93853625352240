<template>
  <div style="margin: auto">
    <img :src="feedBackSubmitted" height="200" width="200" />
    <p
      style="width: 300px; font-size: 24px; margin-left: 20px; margin-top: 10px"
    >
      Thanks for submitting your feedback!
    </p>
    <div style="margin-top: 10px" v-if="isMobile">
      <img
        :src="playStoreImg"
        class="storeImg"
        @click="redirectToStore(true)"
      />
      <img
        :src="appleStoreImg"
        class="storeImg"
        @click="redirectToStore(false)"
      />
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { getImage } from "@/utils/utils";
import useMobileTabletDetector from "@/composables/useMobileDetector";
const props = defineProps({
  params: Object,
  id: String,
  layoutData: Object,
});

const feedBackSubmitted = getImage("slide3.svg");
const playStoreImg = getImage("downloadAppGplay.png");
const appleStoreImg = getImage("downloadAppIos.png");

let isMobile = useMobileTabletDetector();

const APPLE_STORE_LINK =
  "https://apps.apple.com/in/app/id1060530981?shortlink=a31f5ce&c=website_foo_appstore_ios&pid=website_foo_appstore_ios&af_click_lookback=7d";
const PLAY_STORE_LINK =
  "https://play.google.com/store/apps/details?id=com.msf.angelmobile&shortlink=957829bb[…]r_playstore&pid=website_footer_playstore&af_click_lookback=7d";

const widgetId = reactive(props.id);

const redirectToStore = (redirectToPlayStore) => {
  if (redirectToPlayStore) {
    window.location.replace(PLAY_STORE_LINK, "");
  } else {
    window.location.replace(APPLE_STORE_LINK, "");
  }
};
</script>

<style scoped>
.storeImg:hover {
  cursor: pointer;
}
</style>
