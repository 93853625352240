<template>
  <div>
    <div class="img-cropper">
      <vue-cropper
        ref="cropperImage"
        :guides="true"
        :view-mode="4"
        drag-mode="crop"
        :auto-crop-area="0.5"
        :min-container-width="250"
        :min-container-height="180"
        :background="true"
        :rotatable="true"
        :src="image"
        alt="Source Image"
        @cropend="crop"
      >
      </vue-cropper>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import VueCropper from "vue-cropperjs";
export default {
  name: "ImageCropper",
  props: {
    img: String,
    params: Object,
    id: {
      type: String,
      requred: true,
    },
    fileExtension: String,
  },

  setup(props, { emit }) {
    const cropperImage = ref(null);
    const image = computed({
      get() {
        return props.img;
      },
    });
    function crop() {
      let img = cropperImage.value
        .getCroppedCanvas()
        .toDataURL(`image/${props.fileExtension}`);
      emit("onCrop", img);
    }
    return {
      image,
      crop,
      cropperImage,
      // flip,
      // rotate,
    };
  },
  components: {
    VueCropper,
  },
};
</script>

<style lang="scss">
.img-cropper {
  width: 100%;
  max-width: 425px;
  background: #ddd;
}

.cropper-line {
  border-color: rgba(white, 0.8);
}

.cropper-point {
  background-color: transparent !important;
  $length: 16px;
  &.point {
    &-e,
    &-n,
    &-s,
    &-w {
      display: none;
    }
  }
  &.point-nw {
    border-left: solid 2px $black;
    border-top: solid 2px $black;
    height: 15px;
    width: 15px;
  }
  &.point-se {
    border-right: solid 2px $black;
    border-bottom: solid 2px $black;
    height: 15px;
    width: 15px;
  }

  &.point-sw {
    border-left: solid 2px $black;
    border-bottom: solid 2px $black;
    height: 15px;
    width: 15px;
  }
  &.point-ne {
    border-right: solid 2px $black;
    border-top: solid 2px $black;
    height: 15px;
    width: 15px;
  }
}

.cropper-center,
.cropper-dashed {
  display: none;
}
</style>
