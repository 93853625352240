<template>
  <v-row>
    <v-col class="d-flex flex-column" cols="12" :md="webColumns">
      <div
        class="v-field v-field--variant-outlined v-theme--light"
        @change="getGroup($event)"
      >
        <select class="v-field__field" :rules="rules" v-model="value">
          <option value="" disabled>Please select</option>
          <optgroup v-for="(day, i) in items" :key="i" :label="day.title">
            <option
              v-for="(item, j) in day.timeSlot"
              :key="j"
              :value="item.value"
              :disabled="
                item.toTime && timeReactive
                  ? item.toTime <= timeReactive
                  : false
              "
            >
              {{ item.title }}
            </option>
          </optgroup>
        </select>
        <v-icon class="append-calendar" size="24" color="primary"
          >mdi-calendar</v-icon
        >
        <v-icon class="append-dropdown" size="24" color="grey"
          >mdi-chevron-down</v-icon
        >
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { reactive } from "@vue/reactivity";
import useFormValue from "@/composables/useFormValue";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import dayjs from "dayjs";

import useCreateValidationRule from "@/composables/useCreateValidationRule";

export default {
  name: "GroupSelectDropdownWidget",
  props: {
    params: Object,
    id: String,
    validations: Array,
  },
  setup(props) {
    const widgetId = reactive(props.id);
    const { items, webColumns = 6 } = reactive(props.params);
    let { value } = useFormValue(widgetId);
    let time = ref(null);
    let timeReactive = reactive(time);
    const store = useStore();
    value = "";

    function getGroup(event) {
      let index = event.target.selectedIndex;

      const option = event.target.options[index];

      const optgroup = option.parentElement;

      const groupData = optgroup.getAttribute("label");

      const slotTime = option.getAttribute("value");

      store.dispatch("setFormData", {
        key: "slot_picker_day",
        value: groupData,
      });

      store.dispatch("setFormData", {
        key: "slot_picker_value",
        value: slotTime,
      });
      store.dispatch(
        "setButtonToDisable",
        !store.state.formData["slot_picker_value"]
      );
    }

    const validations = reactive(props.validations);
    const { rules } = useCreateValidationRule(validations);

    onMounted(() => {
      time.value = dayjs().format("HH");
    });

    return {
      items,
      value,
      getGroup,
      timeReactive,
      rules,
      webColumns,
    };
  },
};
</script>

<style scoped lang="scss">
.v-field {
  position: relative;
  &__field {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    padding: 15px;
    padding-left: 52px;
    width: 100%;
    position: relative;
    cursor: pointer;
    z-index: 1;
    &:focus-visible {
      border-color: $border-color;
      box-shadow: 0 0 1px rgba($color: #000000, $alpha: 0.02);
      outline: 0;
      transition: ease-in 333ms;
    }
    & * {
      padding: 20px;
    }
  }
}
.append-calendar,
.append-dropdown {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  z-index: 0;
}
.append-dropdown {
  left: auto;
  right: 10px;
}
</style>
