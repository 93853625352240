<template>
  <p class="counterLabelColor" v-if="!showResend && !resendRIghtAlign">
    Resend in: <span class="counterColor">{{ count }}</span>
  </p>
  <p
    @click="submitApi"
    v-if="showResend && !resendRIghtAlign"
    :style="ResendCustomStyle"
  >
    {{ ResendLabel }}
  </p>
  <div
    v-if="resendRIghtAlign"
    :class="[{ resendRIghtAlign: resendRIghtAlign }]"
  >
    <p
      class="counterLabelColor"
      :class="[{ resendRIghtAlign: resendRIghtAlign }]"
      v-if="!showResend"
    >
      Resend in: <span class="counterColor">{{ count }}</span>
    </p>
    <p
      @click="submitApi"
      v-if="showResend"
      :class="[{ resendRIghtAlign: resendRIghtAlign }]"
      :style="ResendCustomStyle"
    >
      {{ ResendLabel }}
    </p>
  </div>
</template>
<script>
import { reactive, onMounted, ref, watch } from "vue";
import ActionHandler from "@/handlers/action";
import useRuleParser from "@/composables/useRuleParser";
import store from "@/store/store";
import { clickStream } from "@/composables/clickStreamAnalytics";
export default {
  name: "ResendWidget",
  props: {
    params: Object,
    id: String,
    validations: Array,
    shouldDisableKey: String,
  },
  setup(props) {
    const widgetId = reactive(props.id);
    let resendTime = null;
    const {
      ResendLabel,
      requestAction,
      ResendCustomStyle,
      resendRIghtAlign,
      widgetName,
    } = reactive(props.params);
    const count = ref(props.params.count);
    const showResend = ref(props.params.showResend);
    const disableResend = ref(parseInt(props.params.disableResendOn));
    const disableResendBtn = ref(0);
    const showResendTimer = ref(props.params.showResendTimer);
    function submitApi() {
      // let clickStream = new ClickStreamAnalytics();
      let csEvents = {
        event_screen: "s-welcomescreen",
        event_type: "click",
        event_sub_type: "button",
        event_name: "resendotp",
        event_id: "73.0.0.50.27",
      };
      clickStream.addEventList(csEvents);
      // if (disableResendBtn.value <= disableResend.value) {
      store.dispatch("setButtonToDisable", true);
      ActionHandler.clearFormData({ [props.id]: widgetId });
      const widgetIdForAction = widgetName ? widgetName : widgetId;
      ActionHandler.handleAction(requestAction, widgetIdForAction, {});
      showResend.value = false;
      // disableResendBtn.value++;
      setTimer();
      // }
    }
    const setTimer = () => {
      if (resendTime) {
        clearInterval(resendTime);
      }
      count.value = props.params.count;
      resendTime = setInterval(timer, 1000); //1000 will  run it every 1 second
    };
    function timer() {
      count.value = count.value - 1;
      if (count.value <= 0) {
        clearInterval(resendTime);
        showResend.value = true;
        return;
      }
    }
    watch(store.state.apiResponses, (currentValue) => {
      if (useRuleParser(showResendTimer.value)) {
        setTimer(); //1000 will  run it every 1 second
      }
    });
    onMounted(async () => {
      if (useRuleParser(showResendTimer.value)) {
        setTimer(); //1000 will  run it every 1 second
      }
    });
    return {
      showResend,
      ResendLabel,
      submitApi,
      resendTime,
      requestAction,
      count,
      showResendTimer,
      disableResend,
      disableResendBtn,
      ResendCustomStyle,
      resendRIghtAlign,
      widgetName,
    };
  },
};
</script>

<style scoped lang="scss">
p {
  text-transform: uppercase;
  color: $primary;
  font-weight: 400;
  cursor: pointer;
}

.counterLabelColor {
  color: $dark;
}

.counterColor {
  color: $info;
}

.resendRIghtAlign {
  width: -webkit-fill-available;
  text-align: end;

  @media (max-width: 600px) {
    margin-right: 0;
    float: right;
    position: relative;
  }
}
</style>
