<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div
    class="d-flex flex-column w-100 v-input v-text-field v-input--horizontal"
  >
    <v-row>
      <v-col
        class="d-flex flex-column v-input__control position-relative"
        cols="12"
        :md="webColumns"
      >
        <v-text-field
          class="date-input"
          :type="textInput"
          :hint="textHint"
          :label="textLabel"
          :variant="textVariant"
          :density="textDensity"
          :disabled="disabledText"
          :readonly="readonlyText"
          :append-icon="appendIcon"
          :rules="rules"
          :placeholder="textPlaceholder"
          @keyup="onKeyUp"
          @change="onChange"
          required
          :maxlength="10"
          pattern="^[0-9/]*$"
          v-model="value"
          ref="textFieldRef"
        >
        </v-text-field>
        <v-icon class="open-date-picker date-icon">mdi-calendar-blank</v-icon>

        <div class="m-datepicker-overlay">
          <div class="m-datepicker">
            <div class="m-datepicker-header">
              <small>SELECT DATE</small>
              <div>
                <p class="m-datepicker-status">
                  <span>Mon</span>,
                  <span>Nov 17</span>
                </p>
                <button class="date-pkr-btn">
                  <v-icon color="white">mdi-pencil</v-icon>
                </button>
              </div>
            </div>
            <div class="m-datepicker-body"></div>
            <div class="m-datepicker-footer">
              <button type="button" class="m-datepicker-cancel">Cancel</button>
              <button type="button" class="m-datepicker-ok">Ok</button>
            </div>
          </div>
        </div>
        <!-- <div class="v-input v-text-field v-input--horizontal">
          <div
            class="v-field v-field--variant-outlined border d-flex"
            :class="[
              { 'v-field--active': isFocus || inputValue?.length > 0 },
              { 'v-field--focused': isFocus },
            ]"
          >
            <div class="v-field__input">
              <input
                ref="inputValue"
                type="text"
                class="date-input"
                @keyup="onKeyUp"
                @change="onChange"
                @focus="isFocused()"
                aria-label="DOB"
                maxlength="10"
                pattern="^[0-9/]*$"
              />
              <v-icon class="open-date-picker">mdi-calendar-blank</v-icon>

              <label class="v-label v-field-label">
                <span>{{ textLabel }}</span>
              </label>
            </div>
          </div>

          <div class="m-datepicker-overlay">
            <div class="m-datepicker">
              <div class="m-datepicker-header">
                <small>SELECT DATE</small>
                <div>
                  <p class="m-datepicker-status">
                    <span>Mon</span>,
                    <span>Nov 17</span>
                  </p>
                  <button class="date-pkr-btn">
                    <v-icon color="white">mdi-pencil</v-icon>
                  </button>
                </div>
              </div>
              <div class="m-datepicker-body"></div>
              <div class="m-datepicker-footer">
                <button type="button" class="m-datepicker-cancel">
                  Cancel
                </button>
                <button type="button" class="m-datepicker-ok">Ok</button>
              </div>
            </div>
          </div>
          <div class="v-input__details" :v-for="item in rules">
            <div class="v-messages text-left" :v-if="item">
              {{ item }}
            </div>
          </div>
                </div> -->
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { reactive, ref } from "@vue/reactivity";
import { onMounted, watch } from "vue";
import useFormValue from "@/composables/useFormValue";
import { select, DatePicker } from "@/plugins/m-datepicker";
import useCreateValidationRule from "@/composables/useCreateValidationRule";
import { useStore } from "vuex";

export default {
  name: "NewDatepickerWidget",
  props: {
    params: Object,
    id: String,
    validations: Array,
  },
  emits: ["input"],
  setup(props) {
    const store = useStore();
    const widgetId = reactive(props.id);
    const { value } = useFormValue(widgetId);
    const validations = reactive(props.validations);
    const { rules } = useCreateValidationRule(validations);
    const rules1 = ref([...rules]);
    const textFieldRef = ref(null);
    let newVal = ref(value);
    let isblurr = ref(false);
    let isFocus = ref(false);

    watch(
      () => store.state.formData[props.params.reactiveOn],
      (currentValue) => {
        if (currentValue) {
          const validateSilently = !textFieldRef?.value?.value;
          textFieldRef?.value?.validate(validateSilently);
        }
      }
    );
    async function onKeyUp(e) {
      if (e.keyCode < 47 || e.keyCode > 57) {
        return e.preventDefault();
      }

      if (isNumber(e)) {
        var len = await e.target.value.length;
        let v = e.target.value;
        if (len !== 1 || len !== 3) {
          if (e.keyCode == 47) {
            e.preventDefault();
          }
        }
        value.value = await v;

        if (len === 2 || len === 5) {
          e.target.value += await "/";
          value.value = await v;
        }
      }
    }
    function isBlurred() {
      isblurr.value = true;
    }
    function isFocused() {
      isFocus.value = true;
    }
    function isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode == 8 ||
        charCode == 9 ||
        (charCode > 36 && charCode < 45) ||
        (charCode > 45 && charCode < 65) ||
        (charCode > 95 && charCode < 106)
      ) {
        return true;
      }
      return false;
    }
    function onChange(e) {
      if (isNumber(e)) {
        value.value = e.target.value;
      }
    }

    onMounted(() => {
      select(".open-date-picker").onclick = () => {
        let input = document.querySelectorAll(".date-input input")[0];
        new DatePicker(input, widgetId);
      };
    });
    const {
      textTitle,
      textCounter,
      textHint,
      textLabel,
      textVariant,
      textDensity,
      disabledText,
      readonlyText,
      prependIcon,
      prependInnerIcon,
      appendIcon,
      appendOuterIcon,
      textPrefix,
      textSuffix,
      isFieldRequired,
      isUppercase,
      textInput,
      webColumns = 6,
      textPlaceholder = "",
      isFilled,
    } = reactive(props.params);

    return {
      textTitle,
      textCounter,
      textHint,
      textLabel,
      textVariant,
      textDensity,
      disabledText,
      readonlyText,
      prependIcon,
      prependInnerIcon,
      appendIcon,
      appendOuterIcon,
      textPrefix,
      textSuffix,
      textPlaceholder,
      textInput,
      isUppercase,
      isFieldRequired,
      webColumns,
      isFilled,
      value,
      onKeyUp,
      onChange,
      isBlurred,
      isFocused,
      isFocus,
      isblurr,
      newVal,
      rules,
      rules1,
      // inputMask,
      textFieldRef,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-text-field {
  position: relative;

  .v-icon {
    &.date-icon {
      position: absolute;
      right: 24px;
      top: 30px;
      color: $gray-600;
    }
  }

  :deep {
    .v-field--variant-outlined {
      &:not(.v-field--active) {
        .v-field-label {
          margin-left: 24px;
          margin-top: -0.3rem;
        }
      }

      .v-field__input {
        min-height: 30px !important;
      }
    }

    .v-input__details {
      text-align: left;
    }

    .v-field {
      &__field {
        min-height: 46px;
      }

      .v-field__outline {
        .v-field__outline__start,
        .v-field__outline__end,
        .v-field__outline__notch::before,
        .v-field__outline__notch::after {
          border-color: $border-color;
          opacity: 1;
        }
      }

      &--active {
        .v-field__outline {
          .v-field__outline__notch::before {
            border-color: $white;
          }
        }

        &.v-field--error {
          .v-field__outline__notch::before {
            border-color: $white !important;
          }
        }
      }

      &--error {
        .v-field__outline {
          .v-field__outline__start,
          .v-field__outline__end,
          .v-field__outline__notch::before,
          .v-field__outline__notch::after {
            border-color: $danger;
          }
        }

        .date-icon-error {
          color: $danger;
        }
      }
    }
  }
}

.open-date-picker {
  cursor: pointer;
}
</style>
