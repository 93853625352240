/* eslint-disable no-unused-vars */
import { http, showErrorMessage } from "@/services/HttpClient";
import ActionHandler from "@/handlers/action";
import store from "@/store/store";

export default function useDigilocker() {
  async function onFinishDigilocker(route, to) {
    const currentUrl = window.location;
    const urlQuery = currentUrl.toString().split("?");
    if (currentUrl.toString().includes("aadhaar")) {
      const urlParams = new URLSearchParams(urlQuery[urlQuery.length - 1]);
      let hmac = urlParams.get("hmac");
      let state = urlParams.get("state");
      let code = urlParams.get("code");
      let error_description = urlParams.get("error_description");
      if (state && !error_description) {
        store.dispatch("setDigiLocader", false);
        // http
        //   .post("/v1/kyc/aadhaar/digilocker", {
        //     data: { hmac: hmac, code: code, state: state },
        //   })
        //   .then((res) => {
        //     ActionHandler.handleAPIData(res?.data?.data);
        //     ActionHandler.handleAPIAction(res?.data?.action);
        //     store.dispatch("setDigiLocader", true);
        //   })
        //   .catch((e) => {
        //     store.dispatch("setDigiLocader", true);
        //   })
        //   .finally(() => {
        //     store.dispatch("setDigiLocader", true);
        //   });
        try {
          const { res } = await http.post("/v1/kyc/aadhaar/digilocker", {
            data: { hmac: hmac, code: code, state: state },
          });
          ActionHandler.handleAPIData(res?.data?.data);
          ActionHandler.handleAPIAction(res?.data?.action);
          store.dispatch("setDigiLocader", true);
        } catch (e) {
          console.log("digilocker error ", e);
          store.dispatch("setDigiLocader", true);
        } finally {
          store.dispatch("setDigiLocader", true);
        }
      }
    }
  }

  return {
    onFinishDigilocker,
  };
}
