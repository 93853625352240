<template>
  <div class="ma">
    <div class="circle">
      <p class="circle-inner">{{ initial }}</p>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "AvatarWidget",
  props: {
    params: Object,
  },
  setup(props) {
    const store = useStore();
    const { key } = reactive(props.params);

    const initial = computed({
      get() {
        let INITIALNAME = store.state.formData[key];
        if (INITIALNAME) {
          let names = INITIALNAME.split(" ");

          let initials = names[0].substring(0, 1).toUpperCase();

          if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
          }
          return initials.toUpperCase();
        } else {
          return "UU";
        }
      },
    });

    return {
      initial,
    };
  },
};
</script>

<style scoped lang="scss">
.ma {
  margin: auto;
}

.circle {
  display: inline-block;
  background-color: rgba(63, 91, 217, 0.12);
  margin: 10px;
  border-radius: 50%;
}

.circle-inner {
  color: #3f5bd9;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  height: 100px;
  width: 100px;
  font-size: 30px;
}
</style>
