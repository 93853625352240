import dayjs from "dayjs";
// import { http } from "../services/HttpClient";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
export function isValidDate(date) {
  const dateValid = dayjs(date);
  return dateValid.isValid();
}

// export const  emailRegex = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

export function validAccountNumber(banknumber) {
  if (banknumber) {
    // eslint-disable-next-line no-useless-escape
    var regex = /^\d{9,18}$/;
    return regex.test(banknumber);
  }
  return false;
}
export function phoneNumber(number) {
  if (number) {
    // eslint-disable-next-line no-useless-escape
    var regex = /^[6-9]\d{9}$/gi;
    return regex.test(number);
  }
  return false;
}
export function numberonly(number) {
  if (number) {
    // eslint-disable-next-line no-useless-escape
    var regex = /^[0-9]*$/;
    return regex.test(number);
  }
  return false;
}
export function characterSpace(char) {
  if (char) {
    if (/^\s/.test(char)) return false;
    // eslint-disable-next-line no-useless-escape
    var regex = /^[a-zA-Z\s]*$/g;
    return regex.test(char);
  }
  return false;
}
/* no longer used */

// export async function checkUniqeAccountNumber(banknumber, path) {
//   if (!banknumber) return true;
//   const response = await http.get(path, {
//     params: {
//       banknumber: banknumber,
//     },
//   });
//   return Boolean(await response);
// }

export function validIfsc(ifsc) {
  if (ifsc && typeof ifsc === "string") {
    ifsc = ifsc.toUpperCase();
    // eslint-disable-next-line no-useless-escape
    var regex = /[a-zA-Z]{4}0[a-zA-Z0-9]{6}$/;
    return regex.test(ifsc);
  }
  return false;
}
export function validEmail(email) {
  if (email) {
    const regex = new RegExp(
      "^.+@[a-zA-Z]+\\.{1}[a-zA-Z]+(\\.{0,1}[a-zA-Z]+)+$"
    );
    return regex.test(email);
  }
  return false;
}
export function alphaNum(value) {
  // eslint-disable-next-line no-useless-escape
  var regex = /^[A-Za-z0-9]+$/;
  return regex.test(value) || false;
}
export function pincode(value) {
  if (value) {
    // eslint-disable-next-line no-useless-escape
    var regex = /^(\d{4}|\d{6})$/;
    return regex.test(value);
  }
  return false;
}
/* no longer used */

// export async function uniqeEmailAddress(email, path) {
//   if (!email) return true;
//   const response = await http.get(path, {
//     params: {
//       email: email,
//     },
//   });
//   return Boolean(await response);
// }

export function validPAN(pan) {
  if (pan) {
    pan = pan.toUpperCase();
    // eslint-disable-next-line no-useless-escape
    var regex = /^[a-zA-Z]{3}[P]{1}[A-Za-z]{1}([0-9]){4}([a-zA-Z]){1}?$/;
    return regex.test(pan);
  }
  return false;
}
export function validAadhaar(value) {
  if (value) {
    value = value.toUpperCase();
    // eslint-disable-next-line no-useless-escape
    var regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
    return regex.test(value);
  }
  return false;
}
export function addressValidators(value) {
  if (value) {
    // eslint-disable-next-line no-useless-escape
    var regex = /^[-@,.\/#&+\w\s]*$/;
    return regex.test(value);
  }
  return false;
}
export function nameValidators(value) {
  if (value) {
    // eslint-disable-next-line no-useless-escape
    var regex = /^[a-z ,.'-]+$/i;
    return regex.test(value);
  }
  return false;
}
/* no longer used */

// export async function checkUniqePan(banknumber, path) {
//   if (!banknumber) return true;
//   const response = await http.get(path, {
//     params: {
//       banknumber: banknumber,
//     },
//   });
//   return Boolean(await response);
// }

export function dateValidators(value) {
  if (value) {
    // eslint-disable-next-line no-useless-escape
    var regex = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/;
    return regex.test(value);
  }
  return false;
}
export function futureDateValidators(value) {
  if (value) {
    let todayDate = dayjs(); //Today Date
    let dateOne = dayjs(value, "DD/MM/YYYY");
    if (todayDate > dateOne) {
      return true;
    } else {
      return false;
    }
  }
}

export function is18yearsOld(dob) {
  let dateofBirth = dayjs(dob, "DD/MM/YYYY").format("YYYY/MM/DD");
  let ageVal = calculateAge(dateofBirth);
  return ageVal >= 18;
}
export function is99yearsOld(dob) {
  let dateofBirth = dayjs(dob, "DD/MM/YYYY").format("YYYY/MM/DD");
  let today = new Date();
  let birthDate = new Date(dateofBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  let dateGap = today.getDate() - birthDate.getDate();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  let ageVal;
  if (age >= 99 && m > 0) {
    ageVal = false;
  } else if (age < 99) {
    ageVal = true;
  } else if (age == 99 && m < 0) {
    ageVal = true;
  } else if (age <= 99 && m == 0 && dateGap <= 0) {
    ageVal = true;
  }
  return ageVal;
}
export function isMonthValid(dob) {
  const month = dob.split("/");
  if (month[1] > 0 && month[1] <= 12) {
    return true;
  }
  return false;
}
export function isDayValid(dob) {
  const dayy = dob.split("/");
  if (dayy[0] > 0 && dayy[0] <= 31) {
    return true;
  }
  return false;
}
export function isYearValid(dob) {
  const yearr = dob.split("/");
  const d = dayjs().format("YYYY");
  if (parseInt(yearr[2]) <= parseInt(d)) {
    return true;
  }
  return false;
}
export function isYearRequired(dob) {
  const yearr = dob.split("/");
  if (yearr[2] && yearr[2].length == 4) {
    return true;
  }
  return false;
}

export function calculateAge(birthday) {
  const ageDifMs = Date.now() - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}
