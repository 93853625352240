<template>
  <v-snackbar
    v-model="obj.snackbar"
    :timeout="obj.timeout"
    :multi-line="obj.multiLine || false"
  >
    <span v-html="obj.text"></span>
    <template v-slot:actions>
      <v-btn color="blue" variant="text" @click="closeSnackBar">Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "SnackBarWidget",
  props: {
    params: Object,
  },
  setup(props) {
    const store = useStore();
    const obj = computed({
      get() {
        return store.state.snack_bar;
      },
    });
    function closeSnackBar() {
      store.dispatch("closeSnackBar");
    }
    return { props, closeSnackBar, obj };
  },
};
</script>

<style lang="scss" scoped>
:deep .v-snackbar__wrapper {
  @media (max-width: 768px) {
    bottom: 50px !important;
  }
}
</style>
