import { decodeBase64, getActiveSegments, setNominee } from "@/utils/utils";
import { getCookieValue, http, showErrorMessage } from "@/services/HttpClient";

import ActionHandler from "@/handlers/action";
import appJson from "../../layout.json";
import { clickStream } from "@/composables/clickStreamAnalytics";
import { getHttpErrorForAnalytics } from "@/utils/httpUtils";
import store from "@/store/store";
import { setCookie } from "@/services/HttpClient";

const getPayload = (payload) => {
  const currentSearch = window.location.toString().split("?");

  const urlQuery = currentSearch;

  const urlParams = new URLSearchParams(`${currentSearch[1]}`);
  let referralCode =
    urlParams.get("referrer") ||
    urlParams.get("refferalPromoCode") ||
    urlParams.get("sbTag") ||
    urlParams.get("SbTag");
  // if (!referralCode && sessionStorage.getItem("referralCode")) {
  //   referralCode = sessionStorage
  //     .getItem("referralCode")
  //     .toString()
  //     .replace(/"/g, "");
  // }
  if (urlParams.has("name")) {
    store.dispatch("setFormData", {
      key: "fullName",
      value: decodeBase64(urlParams.get("name")),
    });
  }
  if (urlParams.has("journey-type")) {
    setCookie("journey-type", urlParams.get("journey-type").split("#")[0]);
  }
  if (referralCode) {
    store.dispatch("setTextFieldDisable", {
      referralCode: true,
    });
  }
  if (!sessionStorage.getItem("referralCode") && urlQuery[1]) {
    payload.referralString = urlQuery[1];
  } else if (sessionStorage.getItem("referralCode")) {
    let sessionReferralCode;
    if (sessionStorage.getItem("referralCode").includes('"')) {
      sessionReferralCode = sessionStorage
        .getItem("referralCode")
        .toString()
        .replace(/"/g, "");
    } else {
      sessionReferralCode = sessionStorage.getItem("referralCode");
    }

    payload.referralString = "referrer=" + sessionReferralCode;
  }
  // payload.referralString = sessionStorage.getItem("referralCode")
  //   ? "referrer=" + sessionStorage.getItem("referralCode")
  //   : urlQuery[1];
  for (const [key, value] of urlParams.entries()) {
    if (key !== "undefined") {
      payload[key] = value;
    }
  }
  //send full raw url
  payload.rawUrl = window.location.toString();
  return payload;
};

export default function useStartApp() {
  async function getLayout() {
    const csEvents = {
      event_screen: "s-welcomescreen",
      event_type: "api",
      event_sub_type: "apirequest-response",
      event_name: "StartAPIresponse",
      event_id: "73.0.0.2.12",
      event_metaData: "",
    };
    // const clickStream = new ClickStreamAnalytics();
    const startTime = Date.now();
    const networkType = navigator?.connection?.effectiveType || "";
    let START_API_CONFIG = {
      retry: true,
      retryErrorCodes: [500, 502, 503, 408, 400],
      retryCount: 2,
    };
    if (
      getCookieValue("journey-type") === "jointAccount" &&
      sessionStorage.getItem("firstHolderAppNumber")
    ) {
      START_API_CONFIG = {
        ...START_API_CONFIG,
        headers: {
          "X-firstHolderAppNumber": sessionStorage.getItem(
            "firstHolderAppNumber"
          ),
        },
      };
    }
    callOtpLogApi();
    const currentUrl = window.location;
    const urlQuery = currentUrl.toString().split("?");
    const urlParams = new URLSearchParams(urlQuery[urlQuery.length - 1]);
    const otpVal = urlParams.get("otp");

    let otpFromSpark = "";
    if (otpVal?.includes("#")) {
      otpFromSpark = otpVal?.split("#")?.[0];
    } else {
      otpFromSpark = otpVal;
    }

    if (otpFromSpark) {
      await journeyDeciderinStart(
        getCookieValue(import.meta.env.VITE_APP_GUEST_COOKIE)
      );
    }
    try {
      const payload = { isSimCardDetected: false };
      const { data } = await http.post(
        import.meta.env.VITE_START_API,
        {
          data: { ...getPayload(payload) },
        },
        START_API_CONFIG
      );
      const {
        applicationStatus,
        flows,
        events,
        action,
        data: { documents },
        staticConfigs,
      } = data;

      const { features, flows: navStack } = events;
      const nominees = events?.data?.nominees || [];

      if (!events?.data?.pan && sessionStorage.getItem("tandCCheckbox")) {
        calExperianPanAPI(events?.data);
      }

      ActionHandler.handleAPIData(events?.data);
      if (documents) {
        store.dispatch("setDocumentList", documents);
      }

      // if (!sessionStorage.getItem("referralCode")) {
      // if (
      //   store?.state?.formData?.referralCode == undefined ||
      //   store?.state?.formData?.referralCode == null ||
      //   store?.state?.formData?.referralCode == ""
      // ) {
      if (
        events?.data?.clientType == "B2B" &&
        !events?.data?.referralCode &&
        events?.data?.sbTag
      ) {
        store.dispatch("setFormData", {
          key: "referralCode",
          value: events?.data?.sbTag,
        });
        store.dispatch("setTextFieldDisable", {
          referralCode: true,
        });
      } else if (events?.data?.referralCode) {
        store.dispatch("setFormData", {
          key: "referralCode",
          value: events?.data?.referralCode,
        });
        store.dispatch("setTextFieldDisable", {
          referralCode: true,
        });
      } else {
        store.dispatch("setFormData", {
          key: "referralCode",
          value: null,
        });
        store.dispatch("setTextFieldDisable", {
          referralCode: false,
        });
      }
      sessionStorage.removeItem("referralCode");
      // }
      if (import.meta.env.VITE_APP_BUILD_RELEASE == "dev") {
        store.dispatch("setLayout", appJson);
      } else {
        store.dispatch("setLayout", flows);
      }

      if (features) {
        store.dispatch("setFeatures", features);
      }
      if (staticConfigs) {
        store.dispatch("setStaticConfig", staticConfigs);
      }
      if (action) {
        store.dispatch("setAction", action);
      }
      if (navStack) {
        let nvS = [...navStack];
        store.dispatch("setPersistNavStack", nvS);
      }
      if (applicationStatus) {
        store.dispatch("setApplicationStatus", applicationStatus);
      }
      //This is for before Esign
      if (nominees.length && !store?.state?.features["nominee"]) {
        setNominee(nominees);
      }
      if (data?.events?.data) {
        const { isFnO, isCurrency, isCommodity, isEquity } = data.events.data;
        const segmentFromAPI = {
          isEquity,
          isFnO,
          isCurrency,
          isCommodity,
        };
        const activeSegmentsLabel = getActiveSegments(segmentFromAPI);
        activeSegmentsLabel?.length &&
          store.dispatch("setFormData", {
            key: "segmentSelection",
            value: activeSegmentsLabel,
          });
      }

      clickStream.addEventList(
        {
          ...csEvents,
          event_metadata: `status: ${true}, message: API success, reponse-time: ${
            (Date.now() - startTime) / 1000
          }s, network-type: ${networkType}, response: ${JSON.stringify({
            action,
            events,
          })}`,
        },
        true
      );
    } catch (error) {
      showErrorMessage(error?.data?.message);
      clickStream.addEventList(
        {
          ...csEvents,
          event_metadata: `status: ${false}, message: API failure ${
            error?.data?.message
          }, reponse-time: ${
            (Date.now() - startTime) / 1000
          }s, network-type: ${networkType}, error: ${getHttpErrorForAnalytics(
            error
          )}`,
        },
        true
      );
    }
  }

  const journeyDeciderinStart = async (token) => {
    let headers = ActionHandler.getJourneyDeciderHeader();
    return http
      .get("/kyc/journey-decider", { headers })
      .then(async (resp) => {
        console.log("journey deciderr response ", resp);
        const currentSearch = window.location.toString().split("?");

        if (resp?.data?.journeyType == "OLD") {
          return;
        } else if (resp?.data?.journeyType == "NEW") {
          setCookie(import.meta.env.VITE_NEW_GUEST_TOKEN_KEY, token, 500);
          store.dispatch("ignoreBrowserPrompt", true);
          if (currentSearch[1]) {
            window.location.replace(
              `${import.meta.env.VITE_KYC_NEW_JOURNEY}?${currentSearch[1]}`,
              "_self"
            );
          } else {
            window.location.replace(
              import.meta.env.VITE_KYC_NEW_JOURNEY,
              "_self"
            );
          }
        }
      })
      .catch((error) => {
        console.log("error in journey decider ", error);
      });
  };

  const calExperianPanAPI = async (data) => {
    const endpoint = "/v1/experian/initiate_pan";
    const payload = {
      full_name: data?.fullName,
      mobile_no: data?.mobile,
      app_number: data?.appNumber,
    };
    const { respData } = await http.post(endpoint, payload);
    console.log("respData calExperianPanAPI ", respData);
    if (respData.status == "success") {
      store.dispatch("setFormData", {
        key: "pan",
        value: events?.data?.pan,
      });
      store.dispatch("setFormData", {
        key: "dob",
        value: events?.data?.dob,
      });
    }
  };

  async function assignReferralCode() {
    const currentSearch = window.location.toString().split("?");
    if (currentSearch.length > 2) {
      let reconstructedUrl;

      for (let i = 0; i < currentSearch.length; i++) {
        switch (i) {
          case 0:
            reconstructedUrl = currentSearch[i];
            break;
          case 1:
            reconstructedUrl = `${reconstructedUrl}?${currentSearch[i].replace(
              "#/login/",
              ""
            )}`;
            break;
          default:
            reconstructedUrl = `${reconstructedUrl}&${currentSearch[i]}`;
        }
      }
      window.location.replace(reconstructedUrl, "");
    }

    store.state.formData["rawUrl"] = window.location.toString();
    store.state.formData["referral"] = currentSearch[1];

    const urlParams = new URLSearchParams(`${currentSearch[1]}`);
    let referralCode =
      urlParams.get("referrer") ||
      urlParams.get("refferalPromoCode") ||
      urlParams.get("sbTag") ||
      urlParams.get("SbTag");
    if (!referralCode && urlParams.has("deep_link_value")) {
      referralCode = await getReferralCodeColonSeperate(
        urlParams.get("deep_link_value")
      );
    }

    if (!referralCode && urlParams.has("referralCodeValue")) {
      referralCode = await getReferralCodeColonSeperate(
        urlParams.get("deep_link_value")
      );
    }
    //commenetd code
    // if (isBase64(referralCode)) {
    //   referralCode = decodeBase64(referralCode);
    // }

    if (urlParams.has("mobile")) {
      store.dispatch("setFormData", {
        key: "mobileNumber",
        value: decodeBase64(urlParams.get("mobile")),
      });
      referralCode = decodeBase64(referralCode);
      const requestActionOnChange = [
        {
          type: "callAPILogin",
          api: "/login/v3/generateOTPPostCaptcha",
          requestOn: "onChangeVal",
          bodyParameters: {
            mobile: "mobileNumber",
            isOtpResend: false,
          },
        },
      ];
      ActionHandler.handleAction(requestActionOnChange, "mobileNumber", {});
    }

    if (urlParams.has("name")) {
      store.dispatch("setFormData", {
        key: "fullName",
        value: decodeBase64(urlParams.get("name")),
      });
    }

    if (referralCode) {
      store.dispatch("setTextFieldDisable", {
        referralCode: true,
      });
      let referrarCode = referralCode.split("::")[0];
      store.dispatch("setFormData", {
        key: "referralCode",
        value: referrarCode,
      });
    }
    if (urlParams.has("utm_medium")) {
      const utm_medium = urlParams.get("utm_medium").includes("#")
        ? urlParams.get("utm_medium").split("#")[0]
        : urlParams.get("utm_medium");
      if (urlParams.has("utm_medium") && utm_medium == "niyo") {
        const profileData = fnDecompressData(
          getCookieValue("ABGuestProfileCookie")
        );
        console.log("profileData ", profileData);

        store.dispatch("setFormData", {
          key: "mobileNumber",
          value: profileData.mobile_no,
        });

        const requestActionOnChange = {
          type: "callAPILogin",
          api: "/login/v3/generateOTPPostCaptcha",
          requestOn: "onChangeVal",
          requestIdKey: "mobileNumber",
          bodyParameters: {
            mobile: "mobileNumber",
            isOtpResend: false,
          },
        };
        ActionHandler.fetchAPILogin(
          requestActionOnChange,
          requestActionOnChange.bodyParameters,
          {},
          "mobileNumber"
        );
      }
    }
  }

  function getReferralCodeColonSeperate(refParam) {
    let refParams = refParam.split("::");
    let referralCode;

    refParams.forEach((param) => {
      let referralVal = param.split("=");
      if (
        referralVal[0] == "referrer" ||
        referralVal[0] == "refferalPromoCode" ||
        referralVal[0].toUpperCase() == "SBTAG"
      ) {
        referralCode = referralVal[1];
      }
    });
    return referralCode;
  }

  function getIPAddress() {
    fetch("https://icanhazip.com/")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((data) => {
        localStorage.setItem("myip", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const callOtpLogApi = () => {
    const currentUrl = window.location;
    const urlQuery = currentUrl.toString().split("?");
    const urlParams = new URLSearchParams(urlQuery[urlQuery.length - 1]);
    const otpVal = urlParams.get("otp");

    let otpFromSpark = "";
    if (otpVal?.includes("#")) {
      otpFromSpark = otpVal?.split("#")?.[0];
    } else {
      otpFromSpark = otpVal;
    }

    if (otpFromSpark) {
      const endpoint = "v1/otp/mobileOtpLogs";
      const payload = {
        otp: otpFromSpark,
      };
      http.post(endpoint, payload, { showErrorToast: false });
    }
  };

  return { getLayout, assignReferralCode, getIPAddress };
}

export function isDocumentUploaded(document) {
  return document;
}
