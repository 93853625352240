import { http, showErrorMessage } from "@/services/HttpClient";

import ActionHandler from "@/handlers/action";
import LocalNavigator from "./useNavigation";
import { clickStream } from "@/composables/clickStreamAnalytics";
import store from "@/store/store";

// let clickStream = new ClickStreamAnalytics();

export default function useNomineeEsign() {
  async function onFinishNomineeEsign(to) {
    const currentUrl = window.location;
    const urlQuery = currentUrl ? currentUrl.toString().split("?") : "";
    if (currentUrl.toString().includes("nominees")) {
      const urlParams = new URLSearchParams(urlQuery[urlQuery.length - 1]);
      let status = urlParams.get("status");
      let message = urlParams.get("message")
        ? urlParams.get("message").split("#")[0]
        : "";

      let url = to.fullPath;
      let apiValidationAnalytics = {
        event_name: "Esign-getxmlapi",
        event_id: "73.0.0.42.4",
        event_type: "api",
        event_sub_type: "apirequest-response",
        // eslint-disable-next-line
        event_metadata: `{ url: ${url}, status: ${status}, message: ${message} }`,
      };
      if (status === "true" || status === "True") {
        await clickStream.addEventList(apiValidationAnalytics);
        store.dispatch("setEsignLoader", false);
        http
          .post("/v1/kyc/esign/confirmation", {})
          .then(async (res) => {
            ActionHandler.handleAPIData(res?.data?.data);
            ActionHandler.handleAPIAction(res?.data?.action);
            store.dispatch("setEsignLoader", true);
          })
          .then((res) => {
            sessionStorage.removeItem("nominees");
            sessionStorage.removeItem("mobile");
          })
          .catch((error) => {
            showErrorMessage(error.message, "esignError");
          });
      } else if (status === "false" || status === "False") {
        await clickStream.addEventList(apiValidationAnalytics);
        // console.warn("Error message from Nominee Esign ", message);
        if (message != "User cancelled") {
          showErrorMessage(message, "esignError");
        }
        store.dispatch("setEsignLoader", true);
      }
    }
  }

  async function addNomineeRoute() {
    const nomineeData = sessionStorage.getItem("nominees");
    const sessionMobileNumber = sessionStorage.getItem("mobile")
      ? sessionStorage.getItem("mobile").toString().replace(/"/g, "")
      : "";

    const stateMobileNumber = store.state?.formData?.mobile;
    if (
      nomineeData &&
      sessionMobileNumber == stateMobileNumber
      // && store?.state?.features["nomineeBeforeEsign"] == false
    ) {
      LocalNavigator.push("/nominees"); //added line for nominee post esign
      store.dispatch("setFormData", {
        key: "nominees",
        value: JSON.parse(nomineeData),
      });
      store.dispatch("setFormData", {
        key: "totalSharePercent",
        value: parseInt(sessionStorage.getItem("totalSharePercent")),
      });
    } else {
      // ActionHandler.clearSessionData({
      //   mobile: "mobile",
      //   totalSharePercent: "totalSharePercent",
      //   nominees: "nominees",
      // });
    }
  }

  return {
    onFinishNomineeEsign,
    addNomineeRoute,
  };
}
