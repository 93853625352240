import { getCookieValue } from "@/services/HttpClient";
import CONFIGDATA from "../../src/config/configData.json";

export default function useBodyParser(bodyParametersValue) {
  let jsonObj = {};
  if (bodyParametersValue) {
    let bodyData = JSON.parse(JSON.stringify(bodyParametersValue));
    Object.keys(bodyData).forEach((key) => {
      let [type, keyParam] = bodyData[key].split(":");
      if (type === "cookie") {
        let cookieVal = getCookieValue(import.meta.env[keyParam]);
        bodyData[key] = bodyData[key].replace(bodyData[key], cookieVal);
      } else if (type === "config") {
        let configData = CONFIGDATA.CONFIGDATA;
        let configVal = configData[keyParam];
        bodyData[key] = bodyData[key].replace(bodyData[key], configVal);
      } else if (type === "baseUrl") {
        let baseUrl = import.meta.env.VITE_BASEURL;
        let configData = CONFIGDATA.CONFIGDATA;
        let configVal = configData[keyParam];
        let finalUrl = baseUrl + configVal;
        bodyData[key] = bodyData[key].replace(bodyData[key], finalUrl);
      } else {
        bodyData[key].replace(type);
      }
      jsonObj[key] = bodyData[key];
    });
    return jsonObj;
  } else if (!bodyParametersValue) {
    console.error("Body Parsing error, skipping default Body");
    return "Body Parsing error, skipping default Body";
  }
}
