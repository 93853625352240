<template>
  <div class="dynamic_table" v-if="headerData">
    <header class="d-flex">
      <h2>{{ headerLabel }}: {{ headerData }}</h2>
    </header>
    <div class="data-wrap">
      <div class="flex-container" v-for="(value, key, i) in rowDatas" :key="i">
        <div class="flex-item-left">
          {{ key }}
        </div>
        <div v-if="isObject(value)" class="flex-item-left">
          <span
            style="color: #3f5bd9; cursor: pointer"
            @click="onClickAction(key)"
          >
            show
          </span>
        </div>
        <div class="flex-item-right" v-else>
          {{ value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from "@vue/reactivity";
import { useStore } from "vuex";
import ActionHandler from "@/handlers/action";

export default {
  name: "GuidelineDetailsWidget",
  props: {
    id: String,
    params: Object,
  },
  setup(props) {
    const { header, footer, tableData, headerLabel, requestAction, detailKey } =
      reactive(props.params);
    const store = useStore();

    let rowDatas = computed({
      get() {
        if (store.state.apiResponses[props.id] && !detailKey) {
          return store.state.apiResponses[props.id][tableData];
        } else if (detailKey && store.state.apiResponses[props.id]) {
          return store.state.apiResponses[props.id][tableData][
            store.state[detailKey]
          ];
        }
        return {};
      },
    });

    function isObject(value) {
      return typeof value === "object" && value !== null;
    }

    function onClickAction(key) {
      store.dispatch("setGuidelineDetKey", key);
      requestAction && ActionHandler.handleAction(requestAction, "", {});
    }

    const headerData = computed({
      get() {
        if (store.state.apiResponses[props.id]) {
          return store.state.apiResponses[props.id][header];
        }
        return "";
      },
    });
    return {
      header,
      rowDatas,
      footer,
      tableData,
      headerLabel,
      headerData,
      isObject,
      onClickAction,
      requestAction,
      detailKey,
    };
  },
};
</script>

<style scoped lang="scss">
* {
  font-family: $font-base-reg;
  color: $gray-800;
}

.dynamic_table {
  width: 100%;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  header {
    padding-top: 16px;
    h2 {
      font-size: $font-size-base;
      padding: 7px 16px;
      line-height: 1;
    }
  }
  .data-wrap {
    padding: 6px 0 16px;
    .flex-container {
      display: flex;
      justify-content: space-between;
      padding: 8px 6px;
      .flex-item-left,
      .flex-item-right {
        padding: 0px 10px;
        text-align: left;
        font-size: $font-size-sm;
        line-height: 1;
      }
      .flex-item-right {
        text-align: right;
        font-weight: bold;
      }
    }
  }
}
</style>
