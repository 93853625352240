<template>
  <div class="hyperverge">
    <v-btn
      flat
      :color="btnColor"
      :elevation="btnElevation"
      :size="btnSize"
      :variant="btnVariant"
      :rounded="btnRoundedType"
      :style="customStyles"
      @click.prevent="onClick"
      :class="['btn-min-width', { 'w-sm-100': hasFullMobWidth }]"
      :ripple="false"
      :disabled="hasLoadingIndicator && loading == 'progress'"
    >
      <span
        v-if="hasLoadingIndicator && loading == 'progress'"
        class="progress-circle justify-content-center"
      >
        <v-progress-circular
          indeterminate
          width="2"
          size="24"
        ></v-progress-circular>
      </span>
      <span v-else class="d-flex align-center justify-content-center">
        <v-icon v-if="hasIcon">{{ iconName }}</v-icon>
        {{ isDataUrlC ? "CONFIRM" : btnText }}
      </span>
    </v-btn>
    <div id="liveliness"></div>
  </div>
</template>

<script>
import CleaverTap from "@/composables/Clevertap";
import { clickStream } from "@/composables/clickStreamAnalytics";
import ActionHandler from "@/handlers/action";
import { http, showErrorMessage } from "@/services/HttpClient";
import { isDataURL, isValidURL } from "@/utils/url";
import {
  getBrowserName,
  checkPlatform,
  calculateRollOutPercentage,
  // randomString,
  // calculateRollOutPercentage,
} from "@/utils/utils";
import {
  checkToken,
  convertUrlToBase64JPEG,
  getImage,
  urltoFile,
} from "@/utils/utils";
import {
  computed,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  watchEffect,
} from "vue";
import { useStore } from "vuex";
import usePopupManager from "@/composables/usePopupManager";
import useMobileTabletDetector from "@/composables/useMobileDetector";

export default {
  name: "HyperVergeWidget",
  props: {
    params: Object,
    id: {
      type: String,
      requred: true,
    },
    hyperVergeUrl: {
      type: String,
      //   required: true,
      default:
        "https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/hyperverge-web-sdk@@7.5.7/src/sdk.min.js",
    },
  },

  setup(props) {
    let faceImage = reactive("");
    // eslint-disable-next-line no-unused-vars
    let docImage = reactive("");
    let docImageBase64 = reactive(null);
    let faceImageBase64 = reactive(null);
    let c_script = reactive(null);
    // const { openPopup } = usePopupManager();
    const {
      accessKey,
      secretKey,
      btnVariant,
      btnText,
      btnColor,
      btnSize,
      btnElevation = 0,
      btnRoundedType,
      customStyles,
      iconName,
      hasIcon,
      btnIcon,
      hasFullMobWidth,
      hasLoadingIndicator,
    } = reactive(props.params);
    const store = useStore();
    const widgetId = reactive(props.id);
    const hVUrl = reactive(props.hyperVergeUrl);

    let hasCameraAccess = ref(true);
    let hasGeolocationAccess = ref(true);
    let latitude = ref(null);
    let longitude = ref(null);

    let percentRollout = store.state.features.isLocationFeature;

    const imageUrl = computed({
      get() {
        return store.state.formData["selfieImageUrl"]
          ? store.state.formData["selfieImageUrl"]
          : null;
      },
    });
    const loading = computed({
      get() {
        return store.state.loadingIndicators[props.id];
      },
    });
    const isDataUrlC = computed({
      get() {
        return store.state.formData["selfieImageUrl"]
          ? isDataURL(store.state.formData["selfieImageUrl"])
          : false;
      },
    });
    const { openPopup, closePopup } = usePopupManager();

    let isMobile = useMobileTabletDetector();

    onBeforeMount(async () => {
      if (isValidURL(store.state.formData["selfieImageUrl"])) {
        convertUrlToBase64JPEG(
          store.state.formData["selfieImageUrl"],
          "selfieImageUrl"
        );
      }
    });

    const loadingHyperverge = computed({
      get() {
        return store.state.hyperVergeToken;
      },
    });

    const updateHasCamsAndGeoAccess = (permissionStatus, isForCamera) => () => {
      if (permissionStatus.state === "denied") {
        if (isForCamera) {
          hasCameraAccess.value = false;
        } else {
          hasGeolocationAccess.value = false;
        }
      } else if (permissionStatus.state === "granted") {
        if (isForCamera) {
          hasCameraAccess.value = true;
        } else {
          hasGeolocationAccess.value = true;
        }
      }
    };
    const checkCameraAndLocationPermission = async () => {
      try {
        const locationPermission = await navigator.permissions.query({
          name: "geolocation",
        });

        if (locationPermission?.state === "denied") {
          hasGeolocationAccess.value = false;
        } else {
          try {
            let position = await getPosition();

            latitude.value = position?.coords?.latitude;
            longitude.value = position?.coords?.longitude;
          } catch (err) {
            console.log("error in getPosition", err);
          }

          if (latitude.value == null || longitude.value == null) {
            hasGeolocationAccess.value = false;
          }
        }

        locationPermission.onchange = updateHasCamsAndGeoAccess(
          locationPermission,
          false
        );
      } catch (err) {
        console.log(err);
      }

      const platform = getBrowserName();
      if (platform === "Mozilla Firefox" || platform === "Apple Safari") {
        try {
          await navigator.mediaDevices.getUserMedia({ video: true });
        } catch (err) {
          if (err.name === "NotAllowedError") {
            hasCameraAccess.value = false;
          } else {
            console.log(err);
          }
        }
      }

      try {
        const cameraPermission = await navigator.permissions.query({
          name: "camera",
        });

        if (cameraPermission?.state === "denied") {
          hasCameraAccess.value = false;
        }

        cameraPermission.onchange = updateHasCamsAndGeoAccess(
          cameraPermission,
          true
        );
      } catch (err) {
        console.log(err);
      }
    };
    async function onClick() {
      if (percentRollout) {
        await checkCameraAndLocationPermission();

        switch (true) {
          case hasCameraAccess.value === false &&
            hasGeolocationAccess.value === false:
            closePopup("cameraPermission");
            closePopup("locationPermission");
            openPopup("locationAndCameraPermission");
            return;
          case hasCameraAccess.value === false:
            closePopup("cameraPermission");
            closePopup("locationAndCameraPermission");
            openPopup("cameraPermission");

            return;
          case hasGeolocationAccess.value === false:
            closePopup("cameraPermission");
            closePopup("locationAndCameraPermission");
            openPopup("locationPermission");
            return;
        }
      }
      if (
        store.state.hyperVergeToken &&
        checkToken(store.state.hyperVergeToken)
      ) {
        if (isDataUrlC.value) {
          submitSelfie();
        } else {
          runLiveness();
        }
      } else {
        hyperVergeStartSession().then(() => {
          onClick();
        });
      }
    }
    async function getPosition() {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
      );
    }

    async function submitSelfie() {
      store.state.loadingIndicators[props.id] = "progress";
      await urltoFile(imageUrl.value, "selfie.jpeg").then(async function (
        file
      ) {
        store.dispatch("setFormData", {
          key: widgetId,
          value: file,
        });

        store.dispatch("setFormData", {
          key: "latitude",
          value: latitude?.value || "",
        });
        store.dispatch("setFormData", {
          key: "longitude",
          value: longitude?.value || "",
        });

        const requestAction = [
          {
            method: "UPLOAD",
            type: "navigateWithAPI",
            api: "/v1/kyc/selfie",
            requestOn: "onChangeVal",
            bodyParameters: {
              selfie: widgetId,
            },
            headerParams: {
              "X-Latitude": "latitude",
              "X-Longitude": "longitude",
            },
            apiSuccess: true,
            apiSuccessEvent: {
              event_name: "itrade_Selfie_Complete",
              event_category: "itrade_selfie",
              event_action: "itrade_selfie_complete",
            },
            apiValidationSuccess: "success",
            apiValidationEvent: {
              event_name: "selfiecheckandconfirm",
              event_id: "73.0.0.60.49",
            },
            ct: {
              event_name: "KYC_CTA_Clicked",
              bodyParameters: {
                event_category: "KYC2.0",
                eventAction: "selfie",
                event_type: "Confirm Click",
                AUC: store.state.formData.appNumber,
              },
            },
          },
          {
            cs: {
              bodyParameters: {
                event_screen: "s-selfiecheckandconfirm",
                event_type: "click",
                event_sub_type: "button",
                event_name: "confirmandupload",
                event_id: "73.0.0.60.35",
              },
            },
            method: "POST",
            type: "callAnalyitcs",
          },
        ];

        ActionHandler.handleAction(requestAction, {}, props.id);
      });
    }
    function loadScript() {
      c_script = document.createElement("script");
      c_script.async = true;
      c_script.src = hVUrl;
      document.head.appendChild(c_script);
    }

    function removeScript() {
      c_script.parentNode.removeChild(c_script);
    }
    function scriptExists(url) {
      return document.querySelectorAll(`script[src="${url}"]`).length > 0;
    }

    function startSession(token = "") {
      const tokenArray = token.split(" ");
      if (
        typeof token === "string" &&
        token.includes("Bearer") &&
        checkToken(tokenArray[1])
      ) {
        // eslint-disable-next-line no-undef
        HyperSnapSDK.init(token, HyperSnapParams.Region.India);
        // eslint-disable-next-line no-undef
        HyperSnapSDK.startUserSession();
      }
    }

    function setSelfieUrlFn(data) {
      faceImageBase64 = data.selfieURL;
      if (data.selfieURL && data?.Liveness === "yes") {
        store.dispatch("setSelfieURL", data?.selfieURL);
      } else {
        if (data?.Liveness === "no") {
          showErrorMessage("Please use live photo.");
          const currentHash = window.location.hash.replace("#", "");
          const networkType = navigator?.connection?.effectiveType || "";
          const csEvents = {
            event_screen: "s-uploadaselfie",
            event_type: "api",
            event_sub_type: "apirequest-response",
            event_name: "kyc_selfie_error",
            event_id: "73.0.0.24.9",
            event_metadata: `Screen-name: ${currentHash}, network-type: ${networkType} , error: ${"Please use live photo."}, user_agent:${
              window.navigator.userAgent
            }`,
          };
          // const clickStream = new ClickStreamAnalytics();
          clickStream.addEventList(csEvents, true);
        }
      }
    }

    function tokenExpire(error) {
      return (
        error?.errorCode === 401 &&
        (error?.errorMsg === "Token Expired" ||
          error?.errorMessage === "Token Expired")
      );
    }

    function HyperKycErrorHandler(error) {
      // console.log("HyperKycErrorHandler", error);
      const currentHash = window.location.hash.replace("#", "");

      const networkType = navigator?.connection?.effectiveType || "";
      const csEvents = {
        event_screen: "s-uploadaselfie",
        event_type: "api",
        event_sub_type: "apirequest-response",
        event_name: "kyc_selfie_error",
        event_id: "73.0.0.24.9",
        event_metadata: `Screen-name: ${currentHash}, network-type: ${networkType} , error: ${error.errorMessage}, user_agent:${window.navigator.userAgent}`,
      };
      // const clickStream = new ClickStreamAnalytics();

      if (tokenExpire(error)) {
        return window.location.reload();
      }
      if (error) {
        if (error.errorCode === "106") {
          let browser = getBrowserName();
          let appID = store.state.formData?.appNumber || null;

          showErrorMessage(
            "Camera access not provided. Please grant Camera permission to proceed to take Selfie."
          );

          CleaverTap.addEvents("KYC_selfie_permission_error", {
            event_screen: "Selfie Page",
            event_category: "KYC",
            event_action: "kyc_selfie_permission_error",
            event_browser: browser,
            event_appid: appID,
          });
        } else {
          showErrorMessage(
            error.errorMessage || "Face is not captured properly"
          );
        }
        store.dispatch("setSelfieURL", "");
      }
      clickStream.addEventList(csEvents, true);
    }
    const handler = (HyperKycResult) => {
      switch (HyperKycResult.status) {
        // ----Incomplete workflow-----
        case "user_cancelled":
          HyperKycErrorHandler(HyperKycResult);
          break;
        case "error":
          if (HyperKycResult.errorCode === "106" && percentRollout) {
            hasCameraAccess.value = false;
          } else {
            HyperKycErrorHandler(HyperKycResult);
          }
          break;
        case "auto_declined":
          // console.log("HyperKycResult auto_declined =>", HyperKycResult);
          // <<Insert code block 4>>
          HyperKycErrorHandler(HyperKycResult);
          break;
        case "auto_approved":
          // console.log("HyperKycResult auto_approved =>", HyperKycResult);
          // <<Insert code block 3>>
          if (HyperKycResult?.details) {
            setSelfieUrlFn(HyperKycResult?.details);
          }
          break;

        case "needs_review":
          // console.log("HyperKycResult needs_review =>", HyperKycResult);
          // <<Insert code block 5>>
          break;
      }
    };

    function tryAgain() {
      runLiveness();
      let csEvents = {
        event_screen: "s-uploadaselfie",
        event_type: "click",
        event_sub_type: "text",
        event_name: "clickagain",
        event_id: "73.0.0.24.3",
      };
      // const clickStream = new ClickStreamAnalytics();
      clickStream.addEventList(csEvents);
    }

    async function runLiveness() {
      const browser = getBrowserName();
      const appID = store.state.formData?.appNumber || null;
      // if (store.state.features.hypervergeLatest) {
      // eslint-disable-next-line no-undef
      const hyperKycConfig = new HyperKycConfig(
        store.state.hyperVergeToken,
        "ao_live_1",
        btoa(store.state.formData.appNumber)
      );
      hyperKycConfig.setInputs({ idBase64String: "NA" });

      const platform = checkPlatform();

      // eslint-disable-next-line no-undef
      if (percentRollout) {
        await checkCameraAndLocationPermission();
      }
      HyperKYCModule.launch(hyperKycConfig, handler);
      CleaverTap.addEvents("Kyc_Take_A_Selfie_Clicked", {
        event_screen: "Selfie Page",
        event_category: "KYC",
        event_action: "kyc_take_a_selfie_clicked",
      });
    }

    function hyperVergeStartSession() {
      return http.get("/v1/token/hyperverge").then(async (res) => {
        const token = await res?.data?.data?.token;
        if (token) {
          await store.dispatch("setHyperToken", token);
        } else {
          throw new Error("No Token Provided");
        }
        await startSession(token);
        return token;
      });
    }

    // watchEffect(() => {
    //   if (percentRollout) {
    // switch (true) {
    //   case hasCameraAccess.value === false &&
    //     hasGeolocationAccess.value === false:
    //     closePopup("cameraPermission");
    //     closePopup("locationPermission");
    //     openPopup("locationAndCameraPermission");
    //     break;
    //   case hasCameraAccess.value === false:
    //     closePopup("locationPermission");
    //     closePopup("locationAndCameraPermission");
    //     openPopup("cameraPermission");
    //     break;
    //   case hasGeolocationAccess.value === false:
    //     closePopup("locationAndCameraPermission");
    //     closePopup("cameraPermission");
    //     openPopup("locationPermission");
    //     break;
    //   case hasCameraAccess.value === true &&
    //     hasGeolocationAccess.value === true:
    //     closePopup("locationAndCameraPermission");
    //     closePopup("cameraPermission");
    //     closePopup("locationPermission");
    //     break;
    //   case hasCameraAccess.value === true:
    //     closePopup("cameraPermission");
    //     break;
    //   case hasGeolocationAccess.value === true:
    //     closePopup("locationPermission");
    //     break;
    // }
    //   }
    // });
    onMounted(async () => {
      // if (percentRollout) {
      //   await checkCameraAndLocationPermission();

      // try {
      //   let position = await getPosition();

      //   latitude.value = position.coords.latitude;
      //   longitude.value = position.coords.longitude;
      // } catch (err) {
      //   console.log("error in getPosition", err);
      // }

      // if (latitude.value == null || longitude.value == null) {
      //   hasGeolocationAccess.value = false;
      // }
      // }
      hyperVergeStartSession();
      store.state.loadingIndicators[props.id] = "loading";
    });

    return {
      accessKey,
      secretKey,
      faceImage,
      docImage,
      docImageBase64,
      faceImageBase64,
      c_script,
      scriptExists,
      loadScript,
      startSession,
      runLiveness,
      removeScript,

      btnVariant,
      btnText,
      btnColor,
      btnSize,
      btnElevation,
      btnRoundedType,
      customStyles,
      iconName,
      hasIcon,
      btnIcon,
      store,
      hasFullMobWidth,
      submitSelfie,
      isDataUrlC,
      onClick,
      loadingHyperverge,
      hasLoadingIndicator,
      loading,
      getImage,
      tryAgain,
      isMobile,
    };
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 600px) {
  .w-sm-100 {
    width: 100%;
  }
}

.btn-min-width {
  min-width: 190px !important;
}

.re-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $primary;
  font-weight: 600;
  margin-top: -1.5rem;

  .v-img {
    margin-right: 10px;
  }
}

.hyperverge {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  gap: 14px;
}
</style>
