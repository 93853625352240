<template>
  <div :style="customStyles">
    <v-select
      v-model="value"
      :items="items"
      :item-props="itemProps"
      :multiple="isMultiple"
      item-title="label"
      item-value="value"
      item-disabled="disabled"
      :label="label"
      :variant="fieldVariant"
      :density="density"
      class="selectdropdown"
    >
      <template v-slot:item="{ props }" v-if="customSlot">
        <div class="customOptionContainer" v-bind="props">
          <span style="font-size: 16px">
            {{ props.title }}
          </span>
          <span
            :style="props.customSubLabelStyles"
            class="subLabel"
            v-if="props?.subText"
          >
            {{ props.subText }}
          </span>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import useFormValue from "@/composables/useFormValue";
import { reactive } from "@vue/reactivity";
import { ref } from "vue";

export default {
  name: "ComboBoxWidget",
  props: {
    params: Object,
    id: String,
  },
  setup(props) {
    const widgetId = reactive(props.id);
    const selectedItems = ref([]);
    const {
      fieldVariant = "outlined",
      isFieldRequired,
      label,
      items,
      itemProps,
      isMultiple = false,
      density = "comfortable",
      customStyles,
      customSlot = false,
    } = reactive(props.params);
    const { value } = useFormValue(widgetId);

    return {
      fieldVariant,
      isFieldRequired,
      value,
      label,
      items,
      selectedItems,
      itemProps,
      isMultiple,
      density,
      customStyles,
      customSlot,
    };
  },

  computed: {},
};
</script>

<style scoped lang="scss">
.selectdropdown {
  :deep {
    .v-input__control {
      border-radius: $border-radius;
      .v-field__overlay {
        background: $white !important;
      }
    }
    .v-field {
      .v-field__outline {
        .v-field__outline__start,
        .v-field__outline__end,
        .v-field__outline__notch::before,
        .v-field__outline__notch::after {
          border-color: #e8ebf1;
          opacity: 1;
        }
      }
      &--active {
        .v-field__outline {
          .v-field__outline__notch::before {
            border-color: $white;
          }
        }
      }

      .v-field__field {
        .v-text-field__suffix {
          opacity: 1;
          background: $white;
          margin-right: -1.25rem;
          padding: 0;
          cursor: pointer;
          color: $primary;
          z-index: 1;
          font-family: $font-base-med;
        }
      }
    }
  }
  &.v-field--error {
    :deep .v-input__control {
      border-color: $danger;
    }
  }
}
.customOptionContainer {
  padding: 8px 10px 8px 10px;
  cursor: pointer;
  transition: 0.3s;
}

.customOptionContainer:hover {
  background-color: #dddde1;
}

.subLabel {
  font-size: 12px;
}
</style>
