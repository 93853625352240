<template>
  <!-- We will remove margin once we set it up in layout -->
  <div class="alert-box" :class="[{ 'bordered-alert': hasBorder }]">
    <v-img
      :src="getImage('success.svg')"
      v-if="isSuccess"
      width="84"
      height="84"
    ></v-img>
    <v-img
      :src="getImage('failed.svg')"
      v-if="isFailed"
      width="84"
      height="84"
    ></v-img>
    <v-img
      :src="getImage('verify.svg')"
      v-if="isVerified"
      width="84"
      height="84"
    ></v-img>
    <!-- <svg xmlns="http://www.w3.org/2000/svg" v-if="isSuccess"
            width="92" height="92" viewBox="0 0 92 92">
            <g fill="none" fill-rule="evenodd">
                <g stroke="#1EC7B6">
                    <g>
                        <g>
                            <g transform="translate(-638 -194) translate(434 114) translate(59 80) translate(145)">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M34 0L10.737 24 0 13.333" transform="translate(30 34)"/>
                                <circle cx="46" cy="46" r="45.5"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg> -->
    <!-- <svg xmlns="http://www.w3.org/2000/svg"  v-if="isFailed"
            width="92" height="92" viewBox="0 0 92 92">
            <g fill="none" fill-rule="evenodd">
                <g>
                    <g>
                        <g>
                            <g transform="translate(-638 -237) translate(479 165) translate(51 72) translate(108)">
                                <g fill="#F65E5A" fill-rule="nonzero" transform="translate(43 26)">
                                    <rect width="5" height="30" rx="2.5"/>
                                    <circle cx="2.5" cy="36.5" r="2.5"/>
                                </g>
                                <circle cx="46" cy="46" r="45.5" stroke="#F65E5A"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg> -->

    <h1 class="pt-6">
      {{ uploadingTitle }}
    </h1>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { getImage } from "@/utils/utils";

export default {
  name: "AlertWidget",
  props: {
    params: Object,
  },
  setup(props) {
    const { isSuccess, isFailed, isVerified, hasBorder, uploadingTitle } =
      reactive(props.params);
    return {
      isSuccess,
      isFailed,
      isVerified,
      hasBorder,
      uploadingTitle,
      getImage,
    };
  },
};
</script>

<style scoped lang="scss">
.alert-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $font-base-reg;
  margin: 0 auto;
  &.bordered-alert {
    border: dashed 2px $gray-500;
    border-radius: $border-radius;
    padding: 30px 0;
    width: 100%;
  }
  h1 {
    font-size: $font-size-base;
    font-weight: 500;
    font-family: $font-base-reg;
  }
}
</style>
