<template>
  <div class="flex-container">
    <template v-for="(item, i) in items" :key="i">
      <div :class="`item ${item?.infoClass}`">
        <v-alert
          :color="item.infoColor"
          :variant="item.infoVariant"
          :title="item.infoTitle"
          :density="item.infoDensity"
          :icon="item.infoIcon"
          :border="item.infoBorder"
          :rounded="item.infoRounded"
          :class="item.infoCustomClasses"
        >
          <div v-if="item.customImage" class="d-flex align-center">
            <ImageWidget
              :id="'caruselinfo' + item.customImage"
              :params="{
                isImage: true,
                imageName: item.customImage,
                imageHeight: 25,
                imageWidth: 25,
                maxWidth: 30,
              }"
            ></ImageWidget>
            <span class="ml-3">{{ item.infoContent }}</span>
          </div>
          <template v-else>
            {{ item.infoContent }}
          </template>
        </v-alert>
      </div>
    </template>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { getImage } from "@/utils/utils";
import ImageWidget from "./ImageWidget.vue";

export default {
  name: "CarouselsWidget",
  props: {
    params: Object,
  },
  setup(props) {
    const { items, carouselsHeight } = reactive(props.params);
    return {
      items,
      carouselsHeight,
      getImage,
    };
  },
  components: { ImageWidget },
};
</script>

<style scoped lang="scss">
.flex-container {
  display: flex;
  flex-wrap: inherit;
  white-space: pre-wrap;
  overflow-x: auto;
  gap: 40px;
  max-width: 100vw;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 450px) {
    flex-wrap: nowrap;
    white-space: nowrap;
    gap: 10px;
  }
}

.flex-container .item:first-child {
  margin-left: auto;
}

.flex-container .item:last-child {
  margin-right: 30px;
}

:deep .v-alert {
  .v-alert__prepend {
    margin-inline-end: 5px;
    background: $white;
    border-radius: 50%;
    font-size: $font-size-xs;

    @media (min-width: $device-width-md) {
      font-size: $font-size-xs;
    }
  }

  @media (max-width: 768px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: $font-size-sm;

    .v-alert__prepend {
      margin-inline-end: 10px;
    }
  }

  &.text-custom {
    background-color: $alert-default-bg;

    .v-alert__underlay {
      background-color: $alert-default-bg;
    }

    .v-alert__content {
      color: $gray-800;
      text-align: left;
      font-size: $font-size-xs;
      font-weight: 400;
      line-height: 15px;
      overflow: visible;
      -ms-overflow-style: none;
      scrollbar-width: none;

      @media (min-width: $device-width-md) {
        font-size: $font-size-sm;
      }
    }

    .mdi-shield-account {
      color: $primary;
      font-size: $font-size-base;
      padding: 12px;
    }
  }

  &.custom-orange {
    background-color: $alert-orange-bg;

    .v-alert__underlay {
      background-color: $alert-orange-bg;
    }
  }

  &.custom-purple {
    background-color: $alert-purple-bg;

    .v-alert__underlay {
      background-color: $alert-purple-bg;
    }
  }
}

.margin-left-auto {
  @media (min-width: 960px) {
    margin-left: auto;
  }
}
</style>
