export const initateChatBot = () => {
  (async () => {
    const p = (l, s, a, i) => {
      const n = () => {
        if (!s.getElementById(a)) {
          const e = s.getElementsByTagName(i)[0],
            t = s.createElement(i);
          (t.type = "text/javascript"),
            (t.async = !1),
            (t.src = "https://app.alltius.ai/alltiusSDK.js"),
            (t.id = a),
            e && e.parentNode
              ? e.parentNode.insertBefore(t, e)
              : s.body.appendChild(t);
        }
      };
      if (!l.AlltiusSDK) {
        const e = (...t) => {
          e.q.push(t);
        };
        (e.q = []),
          (l.AlltiusSDK = e),
          s.readyState === "complete" ? n() : l.addEventListener("load", n);
      }
    };
    await p(window, document, "alltius-sdk", "script");
  })();

  window.AlltiusSDK("configure", {
    metatadata: {
      widgetId: "655c6f8b904987797b01b01c",
    },
  });
};
