/* eslint-disable no-case-declarations */
import {
  characterSpace,
  phoneNumber,
  validAccountNumber,
  validIfsc,
  validPAN,
  validEmail,
  alphaNum,
  pincode,
  numberonly,
  validAadhaar,
  addressValidators,
  dateValidators,
  is18yearsOld,
  is99yearsOld,
  isMonthValid,
  isDayValid,
  isYearRequired,
  isYearValid,
  nameValidators,
  futureDateValidators,
} from "@/validation/validation";

import store from "@/store/store";
import useRuleParser from "./useRuleParser";

export default function useCreateValidationRule(validations) {
  const rules = [];
  const formButtonId =
    validations &&
    validations.find((validation) => validation.type === "formButtonId");
  if (validations) {
    validations.forEach((element) => {
      rules.push((v) => {
        switch (element.type) {
          case "required":
            if (!v) {
              formButtonId && store.dispatch("setButtonToDisable", !v);
              return element.message;
            }
            break;
          case "requiredif":
            // eslint-disable-next-line no-case-declarations
            const requiredIf = useRuleParser(element?.requiredif);
            if (requiredIf && !v) {
              formButtonId && store.dispatch("setButtonToDisable", !v);
              return element.message;
            }
            break;
          case "maxLength":
            if (v && v.length > element.value) {
              formButtonId &&
                store.dispatch(
                  "setButtonToDisable",
                  v && v.length > element.value
                );
              return element.message;
            }
            break;
          case "minLength":
            if (v && v.length < element.value) {
              formButtonId &&
                store.dispatch(
                  "setButtonToDisable",
                  v && v.length < element.value
                );
              return element.message;
            }
            break;
          case "pattern":
            if (v && !checkPattern(v, element)) {
              formButtonId &&
                store.dispatch(
                  "setButtonToDisable",
                  v && !checkPattern(v, element)
                );
              return element.message;
            }
            break;
          case "isMinor":
            // eslint-disable-next-line no-case-declarations
            const relations = element?.relations || [];
            const relation = store.state.formData[element.checkRelationKey];
            const inCaseCanNotBeMinor = [...relations].includes(relation);
            if (v && !checkPattern(v, element) && inCaseCanNotBeMinor) {
              formButtonId &&
                store.dispatch(
                  "setButtonToDisable",
                  v && !checkPattern(v, element)
                );
              return element.message;
            }
            break;
          case "match":
            if (v && !checkMatch(v, element)) {
              formButtonId &&
                store.dispatch(
                  "setButtonToDisable",
                  v && !checkMatch(v, element)
                );
              return element.message;
            }
            break;
          case "mismatch":
            const isReq = element?.requiredif
              ? useRuleParser(element?.requiredif)
              : true;
            if (v && isReq && checkMatch(v, element)) {
              formButtonId &&
                store.dispatch(
                  "setButtonToDisable",
                  v && checkMatch(v, element)
                );
              return element.message;
            }
            break;
          case "lessthanTotal":
            if (v && !lessthanTotal(v, element)) {
              formButtonId &&
                store.dispatch(
                  "setButtonToDisable",
                  v && !lessthanTotal(v, element)
                );
              return element.message;
            }
            break;
          case "authenticationError":
            const error_code =
              store.state.loginAuthenticationAttemptMessage[element.error_code];
            const error_codes = [...element.error_codes].includes(error_code);
            const errorMessage =
              store.state.loginAuthenticationAttemptMessage[element.message];
            if (v && error_codes) {
              formButtonId &&
                store.dispatch("setButtonToDisable", v && error_codes);
              return errorMessage;
            }
            break;
          case "checkLessthan":
            if (v && !checkLessthan(v, element)) {
              formButtonId &&
                store.dispatch(
                  "setButtonToDisable",
                  v && !checkLessthan(v, element)
                );
              return element.message;
            }
            break;
          case "checkUniqueInArrayObj":
            if (v && !checkUniqueInArrayObj(v, element)) {
              formButtonId &&
                store.dispatch(
                  "setButtonToDisable",
                  v && !checkUniqueInArrayObj(v, element)
                );
              return element.message;
            }
            break;
          default:
            return true;
        }
      });
    });
  }

  function lessthanTotal(v, element) {
    let arrayData = store?.state?.formData[element?.arrayKey]
      ? store?.state?.formData[element?.arrayKey]
      : [];
    let total = arrayData.reduce(function (sum, data, index) {
      if (index?.toString() == store?.state[element?.editIndex]?.toString()) {
        return sum;
      } else {
        return sum + parseInt(data[element.sharePercentKey]);
      }
    }, 0);
    total = total + parseInt(v);
    return total <= element.totalVal;
  }

  function checkUniqueInArrayObj(v, element) {
    let arrayData = store?.state?.formData[element?.arrayKey]
      ? store?.state?.formData[element?.arrayKey]
      : [];
    let searchedData = arrayData.filter((data) => {
      return data[element.searchKey].toString() == v.toString();
    });

    let editNominee = {};
    if (store?.state[element?.editIndex]?.toString()) {
      editNominee =
        store?.state?.formData[element?.arrayKey][
          store?.state[element?.editIndex]
        ];
    }

    if (
      store?.state[element?.editIndex]?.toString() &&
      v == editNominee[element.searchKey]
    ) {
      return searchedData.length == 1 ? true : false;
    } else {
      return searchedData.length == 0 ? true : false;
    }
  }

  function checkMatch(v, element) {
    return v === store?.state?.formData[`${element?.value}`];
  }

  function checkLessthan(v, element) {
    return parseInt(v) <= parseInt(element?.value);
  }

  function checkPattern(v, element) {
    switch (element.value) {
      case "character_only":
        return characterSpace(v);
      case "ifsc":
        return validIfsc(v);
      case "pan":
        return validPAN(v);
      case "nomineePan":
        return validPAN(v);
      case "phone_number":
        return phoneNumber(v);
      case "bank_ac_number":
        return validAccountNumber(v);
      case "email":
        return validEmail(v);
      case "alphaNum":
        return alphaNum(v);
      case "pincode":
        return pincode(v);
      case "numberonly":
        return numberonly(v);
      case "aadhaar":
        return validAadhaar(v);
      case "address":
        return addressValidators(v);
      case "date":
        return dateValidators(v);
      case "futureDate":
        return futureDateValidators(v);
      case "is99year":
        return is99yearsOld(v);
      case "is18year":
        return is18yearsOld(v);
      case "isMonthValid":
        return isMonthValid(v);
      case "isDayValid":
        return isDayValid(v);
      case "isYearRequired":
        return isYearRequired(v);
      case "isYearValid":
        return isYearValid(v);
      case "name":
        return nameValidators(v);
      default:
        return true;
    }
  }

  return { rules };
}
