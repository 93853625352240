import store from "@/store/store";

export class DatePicker {
  constructor(input, widgetId) {
    select(".m-datepicker-overlay").style.display = "flex";
    const d = new Date();
    this.input = input;
    this.widgetId = widgetId;
    this.currentYear = d.getFullYear();
    this.currentMonth = d.getMonth();
    this.currentWeekDay = d.getDay();
    this.currentDate = d.getDate();
    this.monthX = this.currentMonth;
    this.yearX = this.currentYear;
    this.dayX = {
      date: null,
      month: null,
      year: null,
    };
    this.__renderDatePicker();
  }
  __renderDatePicker() {
    select(".m-datepicker-body").innerHTML = "";
    select(".m-datepicker-body").innerHTML += `
            <div class="m-datepicker-options">
                <div class="m-datepicker-select-year">
                    <input type="checkbox" id="select-date">
                    <label for="select-date">
                        <span class="m-datepicker-month-status"></span>
                        <span class="m-datepicker-year-status"></span>
                    </label>
                </div>
                <div class="m-datepicker-month-arrows">
                    <p class="prev">&lsaquo;</p>
                    <p class="next">&rsaquo;</p>
                </div>
            </div>
            <div class="m-datepicker-mainContent">
                <div class="m-datepicker-weeks"></div>
                <div class="m-datepicker-days"></div>
            </div>
        `;
    this.__renderWeeks();
    this.__renderDays(this.currentMonth);
    this.__handleMonthArrows();
    this.__handleDateOptionStatus();
    this.__handleMainDateStatus(this.dayX.date, this.monthX);
    this.__handleDatePicker();
    this.__handleDateOptionInput();
  }
  __handleDatePicker() {
    const datepicker_overlay = select(".m-datepicker-overlay");
    datepicker_overlay.onclick = (e) => {
      if (
        e.target.classList.contains("m-datepicker-overlay") ||
        e.target.classList.contains("m-datepicker-cancel")
      ) {
        datepicker_overlay.style.display = "none";
      } else if (e.target.classList.contains("m-datepicker-ok")) {
        const day =
          this.dayX.date.length > 0 && this.dayX.date.length < 2
            ? "0" + this.dayX.date
            : this.dayX.date;
        let m = parseInt(this.monthX) + 1;
        const month = m < 10 ? "0" + m : m;
        const newDate = `${day}/${month}/${this.yearX}`;
        store.dispatch("setFormData", {
          key: this.widgetId,
          value: newDate,
        });
        this.input.value = newDate;

        datepicker_overlay.style.display = `none`;
      }
    };
    select(".m-datepicker-ok").onclick = () => {};
  }
  __renderWeeks() {
    weekdays.forEach((w, i) => {
      select(".m-datepicker-weeks").innerHTML += `
                    <p data-dayNum=${i} data-day=${w}>${w.substring(0, 1)}</p>
                `;
    });
  }
  __renderDays() {
    const days = select(".m-datepicker-days");
    days.innerHTML = "";
    for (
      let i = 0;
      i < weekdays.findIndex((wd) => wd === getDay(this.yearX, this.monthX, 1));
      i++
    ) {
      days.innerHTML += `<p></p>`;
    }
    for (let i = 0; i < daysInMonth(this.monthX, this.yearX); i++) {
      days.innerHTML += `<p data-month=${this.monthX}>${i + 1}</p>`;
    }
    this.__handleDays();
  }
  __handleDays() {
    const days = select(`.m-datepicker-days p[data-month]`, true);
    days.forEach((day) => {
      if (
        parseInt(day.textContent) === this.currentDate &&
        parseInt(day.dataset.month) === this.currentMonth
      )
        day.classList.add("currentDay");
      else if (
        day.textContent === this.dayX.date &&
        day.dataset.month === this.dayX.month
      )
        day.classList.add("selectedDay");
    });
    days.forEach((day) => {
      day.onclick = () => {
        days.forEach((day2) => day2.classList.remove("selectedDay"));
        day.classList.add("selectedDay");
        this.dayX.date = day.textContent;
        this.dayX.month = day.dataset.month;
        this.__handleMainDateStatus(this.dayX.date, this.monthX);
      };
    });
  }
  __handleMonthArrows() {
    select(".m-datepicker-month-arrows p", true).forEach((arrow) => {
      arrow.onclick = () => {
        if (arrow.classList.contains("prev")) {
          this.monthX === 0 ? (this.monthX = 11) : --this.monthX;
          this.__renderDays(this.monthX);
          this.__handleDateOptionStatus();
        } else {
          this.monthX === 11 ? (this.monthX = 0) : ++this.monthX;
          this.__renderDays(this.monthX);
          this.__handleDateOptionStatus();
        }
      };
    });
  }
  __handleDateOptionStatus() {
    select(".m-datepicker-month-status").innerHTML = months[this.monthX];
    select(".m-datepicker-year-status").innerHTML = this.yearX;
  }
  __renderYears() {
    const mainContent = select(".m-datepicker-mainContent");
    mainContent.innerHTML = "";
    mainContent.innerHTML += `<div class="m-datepicker-years"></div>`;
    for (let i = this.currentYear; i >= this.currentYear - 99; i--) {
      select(".m-datepicker-years").innerHTML += `<p>${i}</p>`;
    }
  }
  __handleYears() {
    select(".m-datepicker-years p", true).forEach((y) => {
      parseInt(y.textContent) === parseInt(this.yearX)
        ? y.classList.add("selectedYear")
        : y.classList.remove("selectedYear");
      y.onclick = () => {
        this.yearX = y.textContent;
        this.__renderDatePicker();
      };
    });
    select(".m-datepicker-month-arrows").style.display = "none";
  }
  __handleDateOptionInput() {
    select("#select-date").onchange = (e) => {
      if (e.target.checked) {
        this.__renderYears();
        this.__handleYears();
      } else {
        this.__renderDatePicker();
      }
    };
  }
  __handleMainDateStatus(d, m) {
    select(".m-datepicker-status").innerHTML = "";
    select(".m-datepicker-status").innerHTML += `
            <span>${getDay(
              this.yearX,
              this.monthX,
              this.dayX.date ? this.dayX.date : 1
            ).substring(0, 3)}</span>,
            <span>${months[m]} ${d ? d : this.currentDate}</span>
        `;
  }
}

export const select = (selector, Boolean = false) =>
  Boolean
    ? document.querySelectorAll(selector)
    : document.querySelector(selector);
const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Sunday",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
function daysInMonth(month, year) {
  let m = parseInt(month) + 1;
  let y = parseInt(year);

  return new Date(y, m, 0).getDate();
}
function getDay(year, month, day) {
  const d = new Date(year, month, day);
  return weekdays[d.getDay()];
}

// function onKeyUp(e) {
//     let input = document.querySelectorAll("." + e.target.className)[0];
//     input.addEventListener("keypress", function (e) {
//       if (e.keyCode < 47 || e.keyCode > 57) {
//         e.preventDefault();
//       }
//       var len = input.value.length;
//       if (len !== 1 || len !== 3) {
//         if (e.keyCode == 47) {
//           e.preventDefault();
//         }
//       }
//       value.value = input.value;

//       if (len === 2) {
//         input.value += "/";
//       }
//       if (len === 5) {
//         input.value += "/";
//       }
//     });
// }
