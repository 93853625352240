<template>
  <v-dialog
    :model-value="visible"
    :min-width="minWidth || 320"
    :min-height="minHeight || 200"
    :max-width="maxWidth"
    :max-height="maxHeight || 600"
    class="popup"
    :persistent="persistent"
    @click:outside="!persistent && onClose()"
  >
    <v-card>
      <v-card-title v-if="title">
        <v-row class="mb-1" justify="space-between" align="center" no-gutters>
          <v-col cols="11">
            <h2 class="header">
              {{ title }}
            </h2>
          </v-col>
          <v-col cols="1" v-if="hasHeader" align="center">
            <v-btn
              size="x-small"
              class="close-btn"
              variant="text"
              icon="mdi-close"
              color="gray"
              @click="onClose"
            ></v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider v-if="hasHeader" />
      <v-card-text class="pa-4 mb-6">
        <slot></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { onMounted, onUnmounted } from "vue";

export default {
  name: "PopupWidget",
  props: {
    id: String,
    params: Object,
    screen: Object,
    visible: Boolean,
  },
  setup(props, { emit }) {
    function onClose() {
      emit("onClose", props?.id);
    }

    onMounted(() => {
      document.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          emit("onClose", props?.id);
        }
      });
    });
    onUnmounted(() => {
      document.removeEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          emit("onClose", props?.id);
        }
      });
    });
    const {
      title,
      persistent = false,
      minWidth,
      maxHeight,
      hasHeader,
      header,
      content,
      actions,
      showClose,
      showDivider,
      maxWidth,
      minHeight,
      hasMaxWidth = true,
    } = reactive(props.params);
    return {
      title,
      persistent,
      hasHeader,
      minWidth,
      maxHeight,
      header,
      content,
      actions,
      showClose,
      showDivider,
      maxWidth,
      minHeight,
      onClose,
      hasMaxWidth,
    };
  },
};
</script>

<style lang="scss" scoped>
.popup {
  .header {
    font-family: $font-base-med;
    font-size: $font-size-lg;
    padding: 16px;
    font-weight: 500;
    line-height: $line-height-24;
    white-space: normal;
    hyphens: initial;
  }

  .v-card--density-default {
    align-self: center;
  }

  .v-btn--icon.v-btn--density-default {
    width: calc(var(--v-btn-height) + 5px);
    height: calc(var(--v-btn-height) + 5px);
    border-radius: 50%;
    background-color: $gray-100;
    font-size: $font-size-sm;
    padding: 3px;
    color: #919294;
  }

  &.v-dialog {
    .v-overlay__content {
      width: 100% !important;

      > .v-card {
        min-width: 460px;
        width: 100%;

        @media (max-width: 600px) {
          min-width: 320px;
          max-width: calc(100% - 20px);
          width: calc(100% - 0px);
        }

        > .v-card-text {
          padding: 0px 0px 0px;
        }
      }
    }
  }

  &.w-max-460 .v-card {
    max-width: 460px;
  }

  &.mobile-width .v-card {
    @media (max-width: 600px) {
      max-width: calc(100% - 20px);
      width: calc(100% - 0px);
    }
  }

  .close-btn {
    width: 26px !important;
    height: 26px !important;

    .v-icon {
      font-size: $font-base-reg !important;
    }
  }
}

:deep .v-overlay__scrim {
  opacity: 70%;
}
</style>
