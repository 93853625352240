import { createStore } from "vuex";
export default createStore({
  state() {
    return {
      layout: {},
      formData: {
        documents: [],
        totalSharePercent: 0,
        INITIATE_CHATBOT: false,
      },
      formState: {},
      popups: {},
      activeRoute: null,
      previousRoute: null,
      hyperVergeToken: "",
      features: {},
      action: {},
      navStack: [],
      persistedNavStack: [],
      snack_bar: {
        text: "",
        snackbar: false,
        timeout: 0,
        color: "",
        multiLine: false,
      },
      loadingIndicators: {},
      progressIndicators: {},
      apiResponses: {},
      pageLoader: false,
      buttonDisable: false,
      isDigilockerLoaded: true,
      isCamsLoaded: true,
      isEsignLoaded: true,
      isPageSubmitButton: "",
      isModalSubmitButton: "",
      ignoreBrowserPromt: false,
      croppedImage: null,
      textFieldDisables: {},
      loginAuthenticationAttemptMessage: {},
      staticConfigs: {},
    };
  },
  mutations: {
    SET_LAYOUT_DATA(state, layout) {
      if (layout && Object.getPrototypeOf(layout) === Object.prototype) {
        state.layout = layout;
      } else {
        throw new Error(
          "layout contract voilated, extected an object with key & value properties"
        );
      }
    },
    SET_FORM_DATA(state, upsertData) {
      if (Object.getPrototypeOf(upsertData) === Object.prototype) {
        const { key, value } = upsertData;
        state.formData[key] = value;
      } else {
        throw new Error(
          "FormData upsert contract voilated, extected an object with key & value properties"
        );
      }
    },
    SET_FORM_DATA_MULTIPLE(state, upsertData) {
      if (Array.isArray(upsertData)) {
        upsertData.forEach(({ key, value }) => {
          state.formData[key] = value;
        });
      } else {
        throw new Error(
          "FormData upsert contract voilated, extected an object with key & value properties"
        );
      }
    },
    CLEAR_FORM_DATA(state, key) {
      if (state.formData[key]) {
        delete state.formData[key];
      }
    },
    CLEAR_API_RESPONSES(state, key) {
      if (state.apiResponses[key]) {
        delete state.apiResponses[key];
      }
    },
    SET_API_DATA(state, upsertData) {
      if (upsertData) {
        const { key, value } = upsertData;
        state.apiResponses[key] = value;
      }
    },
    INITIATE_CHATBOT(state) {
      state.formData["INITIATE_CHATBOT"] = true;
    },
    UNLOAD_CHATBOT(state) {
      state.formData["INITIATE_CHATBOT"] = false;
    },
    SET_POPUP(state, upsertData) {
      if (Object.getPrototypeOf(upsertData) === Object.prototype) {
        const { key, value } = upsertData;
        if (key && typeof key == "string") {
          state.popups[key] = value;
        }
      } else {
        throw new Error(
          "FormData upsert contract voilated, extected an object with key & value properties"
        );
      }
    },
    SET_FORM_STATE(state, upsertData) {
      if (Object.getPrototypeOf(upsertData) === Object.prototype) {
        const { key, value } = upsertData;
        state.formState[key] = value;
      } else {
        throw new Error(
          "FormData upsert contract voilated, extected an object with key & value properties"
        );
      }
    },
    SET_ACTIVE_ROUTE(state, route) {
      if (typeof route == "string") {
        state.activeRoute = route;
      } else {
        throw new Error("route must be a string");
      }
    },
    SET_PREVIOUS_ROUTE(state, route) {
      if (typeof route == "string") {
        state.previousRoute = route;
      } else {
        throw new Error("route must be a string");
      }
    },
    SET_HYPERVERGE_TOKEN(state, token) {
      if (typeof token == "string") {
        state.hyperVergeToken = token;
      } else {
        throw new Error("token must be a string");
      }
    },
    SET_SNACK_BAR(state, obj) {
      if (Object.getPrototypeOf(obj) == Object.prototype) {
        const { text, snackbar, timeout, color, ...rest } = obj;
        state.snack_bar = { text, snackbar, timeout, color, ...rest };
      } else {
        throw new Error("Snackbar Object is not valid");
      }
    },
    SET_SNACK_BAR_CLOSE(state) {
      state.snack_bar = {
        text: "",
        snackbar: false,
        timeout: 0,
        color: "",
        multiLine: false,
      };
    },
    SET_FEATURES(state, features) {
      if (features && Object.getPrototypeOf(features) === Object.prototype) {
        const feat = state.features || {};
        state.features = { ...feat, ...features };
      } else {
        throw new Error(
          "features contract voilated, extected an object with key & value properties"
        );
      }
    },

    SET_STATIC_CONFIG(state, value) {
      if (value && Object.getPrototypeOf(value) === Object.prototype) {
        state.staticConfigs = value;
      } else {
        throw new Error(
          "static config contract voilated, extected an object with key & value properties"
        );
      }
    },
    SET_ACTION(state, action) {
      if (action && Object.getPrototypeOf(action) === Object.prototype) {
        state.action = action;
      } else {
        throw new Error(
          "action contract voilated, extected an object with key & value properties"
        );
      }
    },
    SET_PERSIST_NAV_STACK(state, stack) {
      if (stack && Object.getPrototypeOf(stack) === Array.prototype) {
        state.persistedNavStack = stack;
      } else {
        throw new Error(
          "stack contract voilated, extected an object with key & value properties"
        );
      }
    },
    SET_APPLICATION_STATUS(state, status) {
      if (status) {
        state.applicationStatus = status;
      } else {
        throw new Error("Invalid Status");
      }
    },
    SET_LOADING(state, loading) {
      if (typeof loading == "object") {
        state.loadingIndicators = { ...state.loadingIndicators, ...loading };
      } else {
        throw new Error("Loading must be a object");
      }
    },
    SET_CLEAR_LOADING(state) {
      state.loadingIndicators = {};
    },
    SET_CLEAR_EDITINDEX(state, value) {
      state[value] = "";
    },
    SET_LOADING_PROGRESS(state, progress) {
      if (typeof progress == "object") {
        state.progressIndicators = { ...state.progressIndicators, ...progress };
      } else {
        throw new Error("Progress must be a object");
      }
    },
    SET_CLEAR_PROGRESS(state) {
      state.progressIndicators = {};
    },
    SET_DOCUMENT_LIST(state, document) {
      if (document && Object.getPrototypeOf(document) === Array.prototype) {
        state.formData.documents = [...document];
      } else {
        throw new Error(
          "document contract voilated, extected an array properties"
        );
      }
    },
    CLEAR_DOCUMENT_FORMDATA(state) {
      state.formData["documentList"] = [];
    },
    SET_PAGELOADER(state, loading) {
      if (loading && typeof loading == "boolean") {
        state.pageLoader = loading;
      } else {
        throw new Error(
          "loading contract voilated, extected an boolean properties"
        );
      }
    },
    SET_BUTTON_DISABLE(state, disable) {
      if (disable && typeof disable == "boolean") {
        state.buttonDisable = disable;
      } else {
        throw new Error(
          "button disable contract voilated, extected an boolean properties"
        );
      }
    },
    SET_DIGILOCERLOADER(state, loader) {
      if (typeof loader == "boolean") {
        state.isDigilockerLoaded = loader;
      } else {
        throw new Error(
          "digilocer loader contract voilated, extected an boolean properties"
        );
      }
    },
    SET_CAMSLOADER(state, loader) {
      if (typeof loader == "boolean") {
        state.isCamsLoaded = loader;
      } else {
        throw new Error(
          "Cams loader contract voilated, extected an boolean properties"
        );
      }
    },
    SET_ESIGNLOADER(state, loader) {
      if (typeof loader == "boolean") {
        state.isEsignLoaded = loader;
      } else {
        throw new Error(
          "esign loader contract voilated, extected an boolean properties"
        );
      }
    },
    SET_SELFI_URL(state, url) {
      if (typeof url == "string") {
        state.formData.selfieImageUrl = url;
      } else {
        throw new Error(
          "selfie url contract voilated, extected an string properties"
        );
      }
    },
    SET_CROPPED_IMAGE(state, url) {
      if (typeof url == "string") {
        state.croppedImage = url;
      } else {
        throw new Error(
          "croppedImage url contract voilated, extected an string properties"
        );
      }
    },
    SET_INDEXKEY_VALUE(state, indexData) {
      if (Object.getPrototypeOf(indexData) === Object.prototype) {
        const { key, value } = indexData;
        state[key] = value;
      } else {
        throw new Error("Invalid Key");
      }
    },
    PUSH_NAV(state, route) {
      if (route && Object.getPrototypeOf(route) === String.prototype) {
        state.navStack.push(route);
      } else {
        throw new Error(
          "route contract voilated, extected an object with key & value properties"
        );
      }
    },
    SET_TEXT_FIELD_DISABLE(state, value) {
      if (typeof value == "object") {
        state.textFieldDisables = { ...state.textFieldDisables, ...value };
      } else {
        throw new Error("Invalid value");
      }
    },
    POP_NAV(state) {
      if (state.navStack.length > 1) {
        state.navStack.pop();
      } else {
        // console.warn("nav stack is already empty");
      }
    },
    POP_NAV_ROUTE(state, route = "") {
      if (route) {
        let index = state.navStack.findIndex((path) => path == route);
        if (state.navStack.length > 1 && index > -1) {
          let newNavStack = state.navStack.slice(0, index + 1);
          state.navStack = [...newNavStack];
        } else {
          // console.warn("nav stack is already empty");
        }
      } else {
        throw new Error(
          "route contract voilated, extected an string properties"
        );
      }
    },
    MANAGE_ACTIVE_SUBMIT_BUTTON(state, data) {
      state[data.state] = data.value;
    },
    SET_BUTTON_TO_DISABLE(state, value) {
      let result = null;
      Object.keys(state.popups).forEach((key) => {
        if (state.popups[key] == true) result = key;
      });
      if (result) {
        state[state.isModalSubmitButton] = value;
      } else {
        state[state.isPageSubmitButton] = value;
      }
    },
    IGNORE_BROWSER_PROMPT(state, value) {
      state.ignoreBrowserPromt = value;
    },
    DELETE_FORM_FIELD(state, key) {
      if (state.formData[key]) {
        if (key && Object.getPrototypeOf(key) === String.prototype) {
          delete state.formData[key];
        } else {
          throw new Error(
            "delete contract voilated, extected an object with key & value properties"
          );
        }
      }
    },
    CLEAR_CORP_IMAGE(state) {
      state.croppedImage = null;
    },
    CLEAR_DERIVATIVE_SELECTION(state) {
      state.formData.derivativeProofFile = null;
      state.formData.derivativesType = null;
    },

    SET_NOMINIEES_PERCENT(state, value) {
      state.formData.totalSharePercent = value;
    },
    SET_AUTHENTICATION_MESSAGE(state, value) {
      if (typeof value == "object") {
        state.loginAuthenticationAttemptMessage = {
          ...state.loginAuthenticationAttemptMessage,
          ...value,
        };
      } else {
        throw new Error("Invalid value");
      }
    },

    SET_POPUP_ID_TO_WATCH(state, value) {
      state["popupIdToWatch"] = value;
    },
    SET_SIGNATURE_IMG(state, value) {
      state.formData.signatureImageUrl = value;
    },
    SET_GUIDELINE_DET_KEY(state, value) {
      state.guidelineDetKey = value;
    },
  },
  actions: {
    setLayout({ commit }, layout) {
      commit("SET_LAYOUT_DATA", layout);
    },
    setFormData({ commit }, upsertData) {
      commit("SET_FORM_DATA", upsertData);
    },
    setFormDataMultiple({ commit }, upsertData) {
      commit("SET_FORM_DATA_MULTIPLE", upsertData);
    },
    setAPIData({ commit }, upsertData) {
      commit("SET_API_DATA", upsertData);
    },
    setFormState({ commit }, upsertData) {
      commit("SET_FORM_STATE", upsertData);
    },
    setPopup({ commit }, upsertData) {
      commit("SET_POPUP", upsertData);
    },
    openPopup({ commit }, key) {
      if (!key) return;
      commit("SET_POPUP", {
        key,
        value: true,
      });
    },
    closePopup({ commit }, key) {
      commit("SET_POPUP", {
        key,
        value: false,
      });
    },
    setRoute({ commit }, { to, from }) {
      commit("SET_ACTIVE_ROUTE", to.path);
      commit("SET_PREVIOUS_ROUTE", from.path);
    },
    setHyperToken({ commit }, token) {
      commit("SET_HYPERVERGE_TOKEN", token);
    },
    setSnackBar({ commit }, obj) {
      commit("SET_SNACK_BAR", obj);
    },
    closeSnackBar({ commit }) {
      commit("SET_SNACK_BAR");
    },
    setFeatures({ commit }, feature) {
      commit("SET_FEATURES", feature);
    },
    setStaticConfig({ commit }, value) {
      commit("SET_STATIC_CONFIG", value);
    },
    setAction({ commit }, action) {
      commit("SET_ACTION", action);
    },
    setPersistNavStack({ commit }, stack) {
      commit("SET_PERSIST_NAV_STACK", stack);
    },
    setApplicationStatus({ commit }, status) {
      commit("SET_APPLICATION_STATUS", status);
    },
    setLoading({ commit }, loading) {
      commit("SET_LOADING", loading);
    },
    clearFormData({ commit }, key) {
      commit("CLEAR_FORM_DATA", key);
    },
    initiateChatBot({ commit }, key) {
      commit("INITIATE_CHATBOT", key);
    },
    unloadChatBot({ commit }, key) {
      commit("UNLOAD_CHATBOT", key);
    },
    clearAPIResponses({ commit }, key) {
      commit("CLEAR_API_RESPONSES", key);
    },
    setClearLoading({ commit }) {
      commit("SET_CLEAR_LOADING");
    },
    setClearEditIndex({ commit }, value) {
      commit("SET_CLEAR_EDITINDEX", value);
    },
    setProgress({ commit }, progress) {
      commit("SET_LOADING_PROGRESS", progress);
    },
    setClearProgress({ commit }) {
      commit("SET_CLEAR_PROGRESS");
    },
    setDocumentList({ commit }, document) {
      commit("SET_DOCUMENT_LIST", document);
    },
    clearDocumentListFormdata({ commit }) {
      commit("CLEAR_DOCUMENT_FORMDATA");
    },
    setPageLoading({ commit }, loading) {
      commit("SET_PAGELOADER", loading);
    },
    setDigiLocader({ commit }, loading) {
      commit("SET_DIGILOCERLOADER", loading);
    },
    setCamsLocader({ commit }, loading) {
      commit("SET_CAMSLOADER", loading);
    },
    setEsignLoader({ commit }, loading) {
      commit("SET_ESIGNLOADER", loading);
    },
    setButtonDisable({ commit }, disable) {
      commit("SET_BUTTON_DISABLE", disable);
    },
    setSelfieURL({ commit }, url) {
      commit("SET_SELFI_URL", url);
    },
    pushNav({ commit }, route) {
      commit("PUSH_NAV", route);
    },
    popNav({ commit }) {
      commit("POP_NAV");
    },
    popRouteToNav({ commit }, route) {
      commit("POP_NAV_ROUTE", route);
    },
    manageActiveSubmitButton({ commit }, data) {
      commit("MANAGE_ACTIVE_SUBMIT_BUTTON", data);
    },
    setButtonToDisable({ commit }, data) {
      commit("SET_BUTTON_TO_DISABLE", data);
    },
    setTextFieldDisable({ commit }, value) {
      commit("SET_TEXT_FIELD_DISABLE", value);
    },
    ignoreBrowserPrompt({ commit }, data) {
      commit("IGNORE_BROWSER_PROMPT", data);
    },
    deleteFormField({ commit }, key) {
      commit("DELETE_FORM_FIELD", key);
    },
    setCroppedImage({ commit }, base64) {
      commit("SET_CROPPED_IMAGE", base64);
    },
    setIndexValue({ commit }, indexData) {
      commit("SET_INDEXKEY_VALUE", indexData);
    },
    clearCroppedImage({ commit }) {
      commit("CLEAR_CORP_IMAGE");
    },
    clearDerivativeFile({ commit }) {
      commit("CLEAR_DERIVATIVE_SELECTION");
    },
    setTotalPercentOfNominees({ commit }, value) {
      commit("SET_NOMINIEES_PERCENT", value);
    },
    setAuthenticationMessage({ commit }, value) {
      commit("SET_AUTHENTICATION_MESSAGE", value);
    },
    setPopupIdToWatch({ commit }, value) {
      commit("SET_POPUP_ID_TO_WATCH", value);
    },
    setSignature({ commit }, value) {
      commit("SET_SIGNATURE_IMG", value);
    },
    setGuidelineDetKey({ commit }, value) {
      commit("SET_GUIDELINE_DET_KEY", value);
    },
  },
});
