<template>
  <div class="grid-container">
    <label
      :class="[
        {
          'selected-div ': value == 1,
        },
      ]"
      class="radio-group"
      for="font1"
    >
      <input
        type="radio"
        name="sign"
        id="font1"
        v-model="value"
        value="1"
        hidden
      />
      <div class="signature-div">
        <div class="font1">{{ name }}</div>
      </div>
    </label>
    <label
      :class="[
        {
          'selected-div ': value == 2,
        },
      ]"
      class="radio-group"
      for="font2"
    >
      <input
        type="radio"
        name="sign"
        id="font2"
        v-model="value"
        value="2"
        hidden
      />
      <div class="signature-div">
        <div class="font2">{{ name }}</div>
      </div>
    </label>
    <label
      :class="[
        {
          'selected-div ': value == 3,
        },
      ]"
      class="radio-group"
      for="font3"
    >
      <input
        type="radio"
        name="sign"
        id="font3"
        v-model="value"
        value="3"
        hidden
      />
      <div class="signature-div">
        <div class="font3">{{ name }}</div>
      </div>
    </label>
    <label
      :class="[
        {
          'selected-div ': value == 4,
        },
      ]"
      class="radio-group"
      for="font4"
    >
      <input
        type="radio"
        name="sign"
        id="font4"
        v-model="value"
        value="4"
        hidden
      />
      <div class="signature-div">
        <div class="font4">{{ name }}</div>
      </div>
    </label>
  </div>
</template>

<script>
import useFormValue from "@/composables/useFormValue";
import { formatName } from "@/utils/utils";
import { computed, reactive } from "vue";
import { useStore } from "vuex";

export default {
  name: "SignatureTypeWidget",
  props: {
    params: Object,
    id: String,
  },
  setup(props) {
    const store = useStore();
    const widgetId = reactive(props.id);
    const name = computed({
      get() {
        return formatName(store.state.formData["aadhaarFullName"]);
      },
    });
    const { value } = useFormValue(widgetId);

    return { store, widgetId, name, value };
  },
};
</script>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: auto;
  gap: 10px;
  width: 100%;
  padding: 10px;
}

.grid-container > div,
label {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
  border: 1px solid black;

  &.selected-div {
    border: 1px solid blue;
  }
}
</style>
