import ActionHandler from "@/handlers/action";
import CleaverTap from "./Clevertap";

export default function useLifecycleHooks() {
  function _onMounted() {
    if (!this.events) return;
    const { screenId, onLoad } = this.events;
    if (onLoad) {
      ActionHandler.handleAction(onLoad, screenId);
    }
  }

  function _onUnmounted() {
    if (!this.events) return;
    const { screenId, onUnLoad } = this.events;

    if (onUnLoad) {
      ActionHandler.handleAction(onUnLoad, screenId);
      CleaverTap.logout();
    }
  }

  function _onErrorCaptured() {
    if (!this.events) return;
    const { screenId, onError } = this.events;
    if (onError) {
      ActionHandler.handleAction(onError, screenId);
    }
  }

  return {
    _onMounted,
    _onUnmounted,
    _onErrorCaptured,
  };
}
