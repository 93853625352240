<template>
  <section class="ckyc-container">
    <template v-for="(item, index) in details">
      <div class="box" :key="index" v-if="store.state.formData[item?.value]">
        <div class="title">{{ item?.title }}</div>
        <div class="value">{{ store.state.formData[item?.value] }}</div>
      </div>
    </template>
  </section>
</template>
<script>
import { onMounted } from "vue";
import { reactive } from "vue";
import { useStore } from "vuex";

export default {
  name: "CkycConfirmationWidget",
  props: {
    params: Object,
    id: String,
  },
  setup(props) {
    const store = useStore();
    onMounted(() => {
      console.log(JSON.stringify(store.state.formData.pan));
    });
    const { fullName, panNumber, dob, mobileNo, address, email, details } =
      reactive(props.params);
    return {
      store,
      fullName,
      panNumber,
      dob,
      mobileNo,
      address,
      email,
      details,
    };
  },
};
</script>

<style scoped lang="scss">
.ckyc-container {
  width: 100%;
  min-height: 120px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 20px 10px;
  border-radius: 4px;
  background-color: #f4f6fb;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  // box-shadow: 5px 10px rgba(0, 0, 0, 0.5);
  .box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: start;

    padding: 10px;

    .title {
      color: var(--Light-Mode-Neutrals-body_grey, #425061);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .value {
      color: var(--Light-Mode-Neutrals-title_black, #181f29);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
</style>
