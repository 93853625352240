<template>
  <v-btn
    flat
    :color="btnColor"
    :elevation="btnElevation"
    :size="btnSize"
    :variant="btnVariant"
    :rounded="btnRoundedType"
    :style="customStyles"
    v-model="value"
    :class="[
      'btn-min-width',
      { 'w-sm-100': hasFullMobWidth },
      { 'btn-arrow-left': btnArrowClass },
      { 'flex-grow-0': imageLeftAligned },
      { 'flex-grow-0 ml-auto': imageRightAligned },
    ]"
    :ripple="false"
    @click="login"
  >
    <span class="d-flex align-center" data-testid="btn-text-container">
      <v-icon v-if="hasIcon">{{ iconName }}</v-icon>
      <v-img
        :class="negativeMargin ? 'mr-n3' : 'mr-3'"
        :src="getImage(imageName)"
        :width="imageWidth"
        :height="imageHeight"
        v-if="hasImage"
      >
      </v-img>
      {{ btnText }}
    </span>
  </v-btn>
  <!-- <button @click="login">Login Using Google</button> -->
</template>
<script>
import { reactive } from "@vue/reactivity";
import { useStore } from "vuex";
import { getImage } from "@/utils/utils";
import { onMounted } from "vue";
import ActionHandler from "@/handlers/action";
import useFormValue from "@/composables/useFormValue";
import useRuleParser from "@/composables/useRuleParser";

import {
  googleOneTap,
  decodeCredential,
  googleTokenLogin,
} from "vue3-google-login";
export default {
  name: "OAuthWidget",
  props: {
    id: String,
    params: Object,
  },
  setup(props) {
    const widgetId = reactive(props.id);
    const store = useStore();
    const { value } = useFormValue(widgetId);

    const {
      btnVariant,
      btnText,
      btnColor,
      btnSize,
      btnElevation = 0,
      btnRoundedType,
      customStyles,
      iconName,
      hasIcon,
      btnIcon,
      hasFullMobWidth,
      hasLoadingIndicator,
      hasImage,
      imageName,
      btnArrowClass,
      imageWidth = 24,
      imageHeight = 24,
      imageLeftAligned,
      imageRightAligned,
      negativeMargin = false,
      disabledRule,
      checkRuleAndStateValue,
      requestActionButton,
      requestActionOnTap,
      onTapLoadWhen,
    } = reactive(props.params);

    const login = () => {
      googleTokenLogin().then((response) => {
        store.dispatch("setFormData", {
          key: "accessToken",
          value: response.access_token,
        });
        store.dispatch("setFormData", {
          key: "emailSource",
          value: "GOOGLE",
        });
        ActionHandler.clearFormData({ email: "email" });
        ActionHandler.handleAction(requestActionButton, widgetId, {});
      });
    };
    onMounted(() => {
      if (useRuleParser(onTapLoadWhen)) {
        // googleOneTap({ autoLogin: false, cancelOnTapOutside: true })
        //   .then((response) => {
        //     // This promise is resolved when user selects an account from the the One Tap prompt
        //     store.dispatch("setFormData", {
        //       key: "crdentials",
        //       value: response.credential,
        //     });
        //     store.dispatch("setFormData", {
        //       key: "emailSource",
        //       value: "GOOGLE",
        //     });
        //     ActionHandler.clearFormData({ email: "email" });
        //     ActionHandler.handleAction(requestActionOnTap, widgetId, {});
        //   })
        //   .catch((error) => {
        //     console.log("Handle the error", error);
        //   });
      }
    });
    return {
      btnVariant,
      btnText,
      btnColor,
      btnSize,
      btnElevation,
      btnRoundedType,
      customStyles,
      iconName,
      hasIcon,
      btnIcon,
      hasFullMobWidth,
      hasLoadingIndicator,
      hasImage,
      imageName,
      btnArrowClass,
      imageWidth,
      imageHeight,
      imageLeftAligned,
      imageRightAligned,
      negativeMargin,
      value,
      getImage,
      disabledRule,
      checkRuleAndStateValue,
      requestActionButton,
      requestActionOnTap,
      onTapLoadWhen,
      login,
    };
  },
  components: {
    // GoogleLogin,
  },
};
</script>
