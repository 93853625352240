<template>
  <v-img
    :id="'img_' + widgetId"
    v-if="isImage"
    :width="imageWidth"
    :height="imageHeight"
    :max-width="maxWidth"
    :max-height="maxHeight"
    aspect-ratio="1"
    :class="[
      { 'd-none d-sm-block': hideInMobile },
      { 'flex-grow-0': imageLeftAligned },
      { 'flex-grow-0 ml-auto': imageRightAligned },
    ]"
    :style="customStyles"
    :src="imageUrl || getImage(imageName)"
  ></v-img>
  <a
    :href="href"
    v-else-if="isAnchor"
    :class="[
      { 'd-none d-sm-block': hideInMobile },
      { 'flex-grow-0': imageLeftAligned },
      { 'flex-grow-0 ml-auto': imageRightAligned },
    ]"
  >
    <v-img
      :id="'img_' + widgetId"
      :width="imageWidth"
      :height="imageHeight"
      :max-width="maxWidth"
      :max-height="maxHeight"
      aspect-ratio="1"
      :src="imageUrl || getImage(imageName)"
    ></v-img>
  </a>

  <v-icon
    v-if="isIcon"
    :class="[{ 'd-none d-sm-block': hideInMobile }]"
    :style="customStyles"
    :color="iconColor"
  >
    {{ iconName }}
  </v-icon>
</template>
<script>
import { getImage } from "@/utils/utils";
import { computed, reactive } from "@vue/reactivity";
import { useStore } from "vuex";

export default {
  name: "ImageWidget",
  props: {
    params: Object,
    id: String,
  },
  setup(props) {
    const {
      imageWidth,
      imageHeight,
      imageName,
      isImage,
      isIcon,
      iconName,
      maxHeight,
      maxWidth,
      hideInMobile = false,
      imageLeftAligned = true,
      imageRightAligned,
      href,
      isAnchor,
      customStyles,
      iconColor,
    } = reactive(props.params);
    const widgetId = reactive(props.id);
    const store = useStore();
    const imageUrl = computed({
      get() {
        return store?.state?.formData[widgetId];
      },
    });

    return {
      imageWidth,
      imageHeight,
      imageName,
      isImage,
      isIcon,
      iconName,
      hideInMobile,
      maxHeight,
      maxWidth,
      imageLeftAligned,
      widgetId,
      imageUrl,
      imageRightAligned,
      href,
      isAnchor,
      getImage,
      customStyles,
      iconColor,
    };
  },
};
</script>
