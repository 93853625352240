<template>
  <v-carousel
    :height="carouselsHeight"
    cycle
    :show-arrows="false"
    hide-delimiter-background
  >
    <template v-for="(item, i) in items" :key="i">
      <v-carousel-item>
        <v-img
          :height="imageHeight"
          :width="imageWidth"
          :src="getImage(item.src)"
          style="margin-bottom: 80px"
        ></v-img>
        <h2 class="mb-2">{{ item.heading }}</h2>
        <p class="light-gray">{{ item.text }}</p>
      </v-carousel-item>
    </template>
  </v-carousel>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { getImage } from "@/utils/utils";

export default {
  name: "CarouselsWidget",
  props: {
    params: Object,
  },
  setup(props) {
    const { items, imageHeight, imageWidth, carouselsHeight } = reactive(
      props.params
    );
    return {
      items,
      imageHeight,
      imageWidth,
      carouselsHeight,
      getImage,
    };
  },
};
</script>

<style scoped lang="scss">
:deep .v-carousel__controls {
  bottom: 220px;
  .v-btn {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    border-radius: $border-radius * 5;
    .v-icon {
      height: 8px;
      color: $gray-500;
      font-size: 8px;
      &::before {
        width: 8px;
        height: 8px;
      }
    }
  }
  .v-btn--selected {
    width: 28px;
    .v-icon {
      color: $primary;
      width: 28px;
      &::before {
        background: $primary;
        width: 28px;
        border-radius: $border-radius * 5;
        transition: width ease-in 333ms;
      }
    }
  }
}
.light-gray {
  color: #6a7582;
}
</style>
