<template>
  <div class="aadharLinkAlert" v-if="!isCloseBtnIsClicked">
    <v-card
      class="mx-auto"
      elevation="24"
      theme="dark"
      :maxWidth="maxwitdh"
      :class="[
        { 'error-gradient': !isDeadLineNotExceed },
        { 'success-gradient': isDeadLineNotExceed },
      ]"
    >
      <v-row align="start">
        <v-col class="shrink" style="max-width: 35px">
          <v-img :src="getImage(isDeadLineExceedicon)" max-width="35"></v-img>
        </v-col>
        <v-col>
          <v-card-title>{{ titleValue }}</v-card-title>
          <v-card-text>{{ contentValue }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="closeClick">Close</v-btn>
            <v-btn color="primary" @click="openLink" target="_blank"
              >Link Now</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { reactive, computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { getImage } from "@/utils/utils";
import { clickStream } from "@/composables/clickStreamAnalytics";

export default {
  name: "AadharLinkAlert",
  props: {
    params: Object,
  },
  setup(props) {
    // let clickStream = new ClickStreamAnalytics();
    const store = useStore();
    const isCloseBtnIsClicked = ref(false);
    const { title, content, link, isAadharPanLinked, maxwitdh, cardCSS } =
      reactive(props.params);
    const isDeadLineNotExceed = computed({
      get() {
        let GivenDate = "2023-03-31";
        let CurrentDate = new Date();
        GivenDate = new Date(GivenDate);

        return GivenDate > CurrentDate;
      },
    });
    const isDeadLineExceedicon = computed({
      get() {
        if (isDeadLineNotExceed.value) {
          return "aadharLinkThunderboltYellow.svg";
        } else {
          return "aadharLinkThunderboltRed.svg";
        }
      },
    });
    const titleValue = computed({
      get() {
        return store.state.formData[title] || isDeadLineNotExceed.value
          ? "Linking of PAN with Aadhaar is Mandatory"
          : "Account Blocked - Link PAN Aadhaar";
      },
    });
    const contentValue = computed({
      get() {
        return store.state.formData[content] || isDeadLineNotExceed.value
          ? "As per SEBI guidelines, you must link your PAN to AADHAAR by 31 Mar 2023, to avoid blocking of your trading/investment activities. Charges may apply as per government regulations"
          : "As per SEBI regulations, your trading/investment activities have been temporarily blocked because your PAN is not linked to your Aadhaar";
      },
    });

    // function isTimeLineExceed() {
    //   let GivenDate = "2023-03-31";
    //   let CurrentDate = new Date();
    //   GivenDate = new Date(GivenDate);

    //   if (GivenDate > CurrentDate) {
    //     showerror.value = false;

    //     titleValue.value = "Linking of PAN with Aadhaar is Mandatory";
    //     contentValue.value =
    //       "As per SEBI guidelines, you must link your PAN to AADHAAR by 31 Mar 2023, to avoid blocking of your trading/investment activities. Charges may apply as per government regulations";
    //   } else {
    //     showerror.value = true;
    //     titleValue.value = "Account Blocked - Link PAN Aadhaar";
    //     contentValue.value =
    //       "As per SEBI regulations, your trading/investment activities have been temporarily blocked because your PAN is not linked to your Aadhaar";
    //   }
    // }

    const linkValue = computed({
      get() {
        return (
          store.state.formData[link] ||
          "https://eportal.incometax.gov.in/iec/foservices/#/pre-login/bl-link-aadhaar"
        );
      },
    });
    const isAadharPanLinkedValue = computed({
      get() {
        return store.state.formData[isAadharPanLinked];
      },
    });
    onMounted(() => {
      let csEvents = {
        event_screen: "s-wehavereciveddocuments",
        event_type: "impression",
        event_sub_type: "nudge",
        event_name: "N-PAN aadhar seeding",
        event_id: "73.0.0.35.0",
      };
      clickStream.addEventList(csEvents);
    });

    function closeClick() {
      let csEvents = {
        event_screen: "s-wehavereciveddocuments",
        event_type: "click",
        event_sub_type: "button",
        event_name: "close",
        event_id: "73.0.0.35.2",
      };
      isCloseBtnIsClicked.value = true;
      clickStream.addEventList(csEvents);
    }

    function openLink() {
      let csEvents = {
        event_screen: "s-wehavereciveddocuments",
        event_type: "click",
        event_sub_type: "button",
        event_name: "link now",
        event_id: "73.0.0.35.1",
      };
      window.open(linkValue.value, "_blank");
      clickStream.addEventList(csEvents);
    }
    return {
      titleValue,
      contentValue,
      linkValue,
      isAadharPanLinkedValue,
      maxwitdh,
      cardCSS,
      isDeadLineNotExceed,
      closeClick,
      isCloseBtnIsClicked,
      getImage,
      isDeadLineExceedicon,
      openLink,
    };
  },
};
</script>

<style scoped lang="scss">
.a {
  float: left;
}

.success-gradient {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  border-radius: 12px;
  padding: 8px;
  box-sizing: content-box;

  color: #425061;
  background: linear-gradient(
    257.16deg,
    rgba(249, 186, 77, 0.12) 18.99%,
    rgba(255, 255, 255, 0) 104.83%
  );
  border-left: 4px solid rgba($color: #f9ba4d, $alpha: 0.8);
}

.error-gradient {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  border-radius: 12px;
  padding: 8px;
  box-sizing: content-box;

  color: #425061;
  background: linear-gradient(
    257.16deg,
    rgba(246, 94, 90, 0.12) 18.99%,
    rgba(255, 255, 255, 0) 104.83%
  );
  border-left: 4px solid rgba($color: #f65e5a1f, $alpha: 0.8);
}
</style>
