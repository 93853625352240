<template>
  <h1
    :class="[
      { 'text-center w-100': textCenteredAligned },
      { 'text-left': !textCenteredAligned },
      { 'gray-custom': isGuidelineHeader },
      { 'gray-900': !isGuidelineHeader },
      { mobNegativeMarginTop: isCustomClass },
    ]"
    v-if="isH1"
    :style="customStyles"
  >
    {{ title }}
  </h1>
  <h2
    :class="[
      { 'text-center w-100': textCenteredAligned },
      { 'text-left': !textCenteredAligned },
      { 'gray-custom': isGuidelineHeader },
      { 'gray-900': !isGuidelineHeader },
    ]"
    v-if="isH2"
    :style="customStyles"
  >
    {{ title }}
  </h2>
  <h3
    :class="[
      { 'text-center w-100': textCenteredAligned },
      { 'text-left': !textCenteredAligned },
      { 'gray-custom': isGuidelineHeader },
      { 'gray-900': !isGuidelineHeader },
    ]"
    v-if="isH3"
    :style="customStyles"
  >
    {{ title }}
  </h3>
  <h4
    :class="[
      { 'text-center w-100': textCenteredAligned },
      { 'text-left': !textCenteredAligned },
      { 'gray-custom': isGuidelineHeader },
      { 'gray-900': !isGuidelineHeader },
    ]"
    v-if="isH4"
    :style="customStyles"
  >
    {{ title }}
  </h4>
  <h5
    :class="[
      { 'text-center w-100': textCenteredAligned },
      { 'text-left': !textCenteredAligned },
      { 'gray-custom': isGuidelineHeader },
      { 'gray-900': !isGuidelineHeader },
    ]"
    v-if="isH5"
    :style="customStyles"
  >
    {{ title }}
  </h5>
  <h6
    :class="[
      { 'text-center w-100': textCenteredAligned },
      { 'text-left': !textCenteredAligned },
      { 'gray-custom': isGuidelineHeader },
      { 'gray-900': !isGuidelineHeader },
    ]"
    v-if="isH6"
    :style="customStyles"
  >
    {{ title }}
  </h6>
</template>

<script>
import { reactive, computed } from "@vue/reactivity";
import { useStore } from "vuex";
export default {
  name: "HeadingWidget",
  props: {
    params: Object,
  },
  setup(props) {
    const {
      heading,
      isH1,
      isH2,
      isH3,
      isH4,
      isH5,
      isH6,
      textCenteredAligned,
      isGuidelineHeader,
      customStyles,
      isCustomClass,
      dynamicText,
    } = reactive(props.params);
    const store = useStore();
    const title = computed({
      get() {
        return dynamicText ? store.state.formData[heading] : heading;
      },
    });
    return {
      heading,
      isH1,
      isH2,
      isH3,
      isH4,
      isH5,
      isH6,
      textCenteredAligned,
      isGuidelineHeader,
      customStyles,
      isCustomClass,
      title,
    };
  },
};
</script>

<style scoped lang="scss">
* {
  font-family: $font-base-reg;
  font-weight: 600;
}
.gray-custom {
  color: $custom-gray;
}
.gray-900 {
  color: $gray-900;
}
h1 {
  font-size: $font-size-base * 1.25;
  font-family: $font-base-reg;
  line-height: 24px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: $font-size-lg;
    font-weight: 500;
  }
}
h2 {
  font-size: $font-size-base;
  font-family: $font-base-reg;
  font-weight: 600 !important;
  @media (max-width: 768px) {
    font-size: $font-size-base;
    font-weight: 500 !important;
  }
}
.mobNegativeMarginTop {
  @media (max-width: $device-width-md) {
    margin-top: -3rem;
  }
}
</style>
