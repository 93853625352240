<template>
  <div class="container">
    <slot />
  </div>
</template>

<script>
import { reactive, ref } from "vue";

export default {
  name: "StyleContainer",
  props: {
    style: Object,
    width: Number,
    id: String,
  },
  setup(props) {
    const margin = ref(props.style.margin);
    const padding = ref(props.style.padding);
    const flex = reactive(props.width);
    const size = ref(props.style.size);
    const backgroundColor = ref(props.style.backgroundColor);
    const textColor = ref(props.style.textColor);
    return { margin, padding, size, backgroundColor, textColor, flex };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex: v-bind(flex ?? 1);
  color: v-bind(textColor);
  background-color: v-bind(backgroundColor);
  /* prettier-ignore */
  margin-top: v-bind(`${(margin && margin.top) ?? 0}px`);
  /* prettier-ignore */
  margin-bottom: v-bind(`${(margin && margin.bottom) ?? 0}px`);
  /* prettier-ignore */
  margin-right: v-bind(`${(margin && margin.right) ?? 0}px`);
  /* prettier-ignore */
  margin-left: v-bind(`${(margin && margin.left) ?? 0}px`);
  /* prettier-ignore */
  padding-top: v-bind(`${(padding && padding.top) ?? 0}px`);
  /* prettier-ignore */
  padding-bottom: v-bind(`${(padding && padding.bottom) ?? 0}px`);
  /* prettier-ignore */
  padding-right: v-bind(`${(padding && padding.right) ?? 0}px`);
  /* prettier-ignore */
  padding-left: v-bind(`${(padding && padding.left) ?? 0}px`);
}
</style>
