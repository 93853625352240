<template>
  <v-radio-group v-model="value">
    <v-row>
      <v-col
        v-for="item in radioList"
        :key="item.id"
        :cols="minColWidth"
        :md="minColWidth"
        :class="[{ 'flex-grow-0 mr-2 mb-2 pa-0': isRadioSmall }]"
        :style="containerStyles"
      >
        <v-radio :label="item.label" :value="item.value" :key="item.value">
          <template v-slot:label>
            <div
              :class="[
                { borderd: item.outlined },
                { 'borderd-selected': item.value == value },
                { check: item.value == value },
                { 'is-icon-checked': isIconChecked },
                { 'is-radio-sm': isRadioSmall },
              ]"
              :style="customStyles"
              class="radio-button"
            >
              <img
                v-if="item?.imageName && item.value == value"
                :height="imgMaxHeight || 24"
                :width="imgMaxwidth || 24"
                class="mr-3"
                :src="getImage(item?.selectedImageName)"
              />
              <img
                v-if="item?.imageName && item.value != value"
                :height="imgMaxHeight || 24"
                :width="imgMaxwidth || 24"
                class="mr-3"
                :src="getImage(item?.imageName)"
              />
              <span class="w-100 text-left">{{ item?.label }}</span>
            </div>
          </template>
        </v-radio>
      </v-col>
    </v-row>
  </v-radio-group>
</template>
<script>
import useButtonDisabledHooks from "@/composables/useButtonDisabledHooks";
import useFormValue from "@/composables/useFormValue";
import store from "@/store/store";
import { getImage } from "@/utils/utils";
import { reactive } from "@vue/reactivity";
import { computed, onUpdated } from "vue";

export default {
  name: "RadioButton",
  props: {
    params: Object,
    id: String,
    layoutData: Object,
  },
  setup(props) {
    const widgetId = reactive(props.id);
    const {
      radioList,
      colWidth = 6,
      isIconChecked = true,
      isRadioSmall = false,
      clearFiled = "",
      imgMaxHeight,
      imgMaxwidth,
      customStyles,
      containerStyles,
      clearFields,
    } = reactive(props.params);
    const minColWidth = computed(() => {
      return !isRadioSmall ? "6" : 0;
    });
    const minWebColWidth = computed(() => {
      return !isRadioSmall ? "6" : 0;
    });
    const { value } = useFormValue(widgetId);
    const { handleButtonDisabledState } = useButtonDisabledHooks();
    const isCleared = () => {
      const ar = clearFiled.split("|");
      if (
        ar[0] &&
        Object.getPrototypeOf(ar[0]) === String.prototype &&
        ar[1] &&
        Object.getPrototypeOf(ar[1]) === String.prototype
      ) {
        if (value.value !== ar[0]) {
          store.dispatch("deleteFormField", ar[1]);
        }
      }
    };
    onUpdated(() => {
      handleButtonDisabledState(props.layoutData);
      if (value.value) {
        isCleared();
      }
    });
    return {
      radioList,
      widgetId,
      value,
      colWidth,
      isIconChecked,
      isRadioSmall,
      minColWidth,
      minWebColWidth,
      imgMaxHeight,
      imgMaxwidth,
      customStyles,
      getImage,
      containerStyles,
    };
  },
};
</script>
<style scoped lang="scss">
:deep {
  .v-selection-control__wrapper {
    display: none;
  }

  .v-input__details {
    display: none;
  }

  .v-label {
    opacity: 1 !important;
  }
}

.radio-button {
  @media (max-width: 600px) {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    gap: 2px;
  }

  padding: 20px;
  height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
  transition: all 333ms ease-in;

  &.is-icon-checked {
    &::after {
      background: url("https://cdn.angelone.in/kyc/images/icon-checked.svg")
        no-repeat;
      width: 25px;
      height: 25px;
      min-width: 25px;
      position: relative;
      right: 0;
      content: "";
      display: block;
      clear: both;
      display: none;
    }
  }

  &.is-radio-sm {
    padding: 8px 16px;
    height: auto;

    span {
      max-width: 100%;
    }

    &.check {
      color: $primary;
      background: $gray-400;
    }
  }

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    max-width: calc(100% - 20px);
    font-size: $font-size-sm;
    color: $gray-900;
    font-weight: 500;

    @media (min-width: $device-width-md) {
      font-size: $font-size-base;
    }
  }
}

.borderd {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  position: relative;
  font-family: $font-base-med;
  font-weight: 500;

  &-selected {
    border: 1px solid $primary !important;
    color: $primary;
  }

  &:hover {
    transition: all 333ms ease-in;
    border-color: $primary !important;
  }

  &.check {
    &::after {
      display: block;
    }
  }
}
</style>
