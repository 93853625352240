import store from "@/store/store";
import usePopupManager from "./usePopupManager";
import useRuleParser from "./useRuleParser";

export default function useButtonDisabledHooks() {
  const { getActivePopups } = usePopupManager();
  function handleButtonDisabledState(layoutData) {
    let validationField = [];
    for (let i = 0; i <= layoutData?.length; i++) {
      for (let j = 0; j < layoutData[i]?.length; j++) {
        if (layoutData[i][j].validations) {
          layoutData[i][j].validations.findIndex((vl) => {
            if (vl.type === "required") {
              return validationField.push(layoutData[i][j].id);
            } else if (vl.type === "requiredif") {
              const isPushNeeded = useRuleParser(vl.requiredif);
              return isPushNeeded && validationField.push(layoutData[i][j].id);
            }
          });
        }
        if (layoutData[i][j].isTypeSubmit === true) {
          if (getActivePopups()) {
            store.dispatch("manageActiveSubmitButton", {
              state: "isModalSubmitButton",
              value: layoutData[i][j].id,
            });
          } else {
            store.dispatch("manageActiveSubmitButton", {
              state: "isPageSubmitButton",
              value: layoutData[i][j].id,
            });
          }
        }
      }
    }
    const formDataValueChecker = validationField.every(
      (v) => store.state.formData[v]
    );
    store.dispatch("setButtonToDisable", !formDataValueChecker);
  }

  return {
    handleButtonDisabledState,
  };
}
