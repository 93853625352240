import store from "@/store/store";
import { computed } from "vue";

export default function useFormValue(widgetId) {
  const value = computed({
    get() {
      return store.state.formData[widgetId];
    },
    set(value) {
      store.dispatch("setFormData", {
        key: widgetId,
        value,
      });
    },
  });
  return { value };
}
