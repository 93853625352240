import store from "@/store/store";

export default function usePopupManager() {
  function initPopups() {
    const {
      state: {
        layout: { popups },
      },
    } = store;
    if (popups && Object.getPrototypeOf(popups) === Object.prototype) {
      Object.keys(popups).forEach((key) => {
        store.dispatch("setPopup", {
          key,
          value: false,
        });
      });
    }
  }

  const iterateOverPopups = () => {
    const result = Object.keys(store.state.popups).find((key) => {
      return store.state.popups[key] == true;
    });
    return result;
  };

  function getActivePopups() {
    let result = iterateOverPopups();
    return result;
  }

  function isPopupShown() {
    let result = iterateOverPopups();
    if (!result) return false;
    return true;
  }

  function openPopup(id, idToWatch = null) {
    const newId = parseId(id);
    idToWatch && store.dispatch("setPopupIdToWatch", idToWatch);
    if (newId) {
      return store.dispatch("openPopup", newId);
    } else {
      console.error("invalid popup id");
    }
  }

  function closePopup(id) {
    const newId = parseId(id);
    if (store.state["popupIdToWatch"]) {
      store.dispatch("setPopupIdToWatch", null);
    }
    if (newId) {
      store.dispatch("closePopup", newId);
      store.dispatch("clearDocumentListFormdata");
    } else {
      console.error("invalid popup id");
    }
  }

  function parseId(id) {
    if (id && id.includes("$")) {
      const str = id.replace("$", "");
      const newId = store.state.formData[str];
      return newId;
    } else {
      return id;
    }
  }
  function getPopupScreen() {
    const result = iterateOverPopups();
    if (!result) return;

    const screen = store.state.layout.popups[result];
    return screen;
  }

  function getPopupParams() {
    return {
      hasHeader: true,
      hasMaxWidth: true,
    };
  }

  function popupOnClose(id) {
    closePopup(id);
  }

  function showPopup() {
    return isPopupShown();
  }

  return {
    initPopups,
    openPopup,
    closePopup,
    getPopupScreen,
    getPopupParams,
    popupOnClose,
    showPopup,
    getActivePopups,
  };
}
