import ScreenView from "@/components/ScreenView.vue";
import store from "@/store/store";
import * as _ from "underscore";

export default function useCreateRoutes() {
  const {
    state: {
      layout: { screens },
      persistedNavStack,
    },
  } = store;
  const routes = [];
  let steps = [];
  let allSteps = [];
  let paths = {};
  const navStack = [];
  function sortSteps() {
    return _.uniq(steps, (x) => x && x.title);
  }
  function getObjectArray(key) {
    return paths[key] || [];
  }
  function splitAndTrimSteps(stepNumber, indexToReturn) {
    if (stepNumber?.includes("|")) {
      return Number(stepNumber?.split("|")[indexToReturn]);
    }
    return Number(stepNumber);
  }

  const isPersonalFirst = store.state.formData["prePersonal"];
  for (const screenKey in screens) {
    const currentScreen = screens[screenKey];
    const path = `/${screenKey}`;
    let stepNum = currentScreen.step;
    let mobileStepNum = currentScreen.mobileStep;
    let screenFlow = currentScreen.flow;
    if (stepNum && screenFlow) {
      if (isPersonalFirst) {
        if (screenFlow.includes("|")) {
          screenFlow = screenFlow.split("|")[1].trim();
        }
        stepNum = splitAndTrimSteps(stepNum, 1);
        mobileStepNum = splitAndTrimSteps(mobileStepNum, 1);
      } else {
        if (screenFlow.includes("|")) {
          screenFlow = screenFlow.split("|")[0].trim();
        }
        stepNum = splitAndTrimSteps(stepNum, 0);
        mobileStepNum = splitAndTrimSteps(mobileStepNum, 0);
      }
      steps.push({
        title: screenFlow ?? "",
        path,
        step: stepNum,
        mobileStep: mobileStepNum,
      });
      paths[screenFlow] = [...getObjectArray(screenFlow), path];
      steps.map((item) => {
        if (item?.title == screenFlow) {
          item.path = [...getObjectArray(item?.title)];
        }
      });
      allSteps = steps;
      steps = sortSteps();
    }

    routes.push({
      path,
      component: ScreenView,
      props: {
        screen: currentScreen,
        id: screenKey,
      },
    });
  }

  persistedNavStack.forEach((stack) => {
    navStack.push({
      path: `/${stack}`,
    });
  });

  return { routes, steps, navStack, allSteps };
}
