<template>
  <div :class="infoClass">
    <v-alert
      :color="infoColor"
      :variant="infoVariant"
      :title="infoTitle"
      :density="infoDensity"
      :icon="infoIcon"
      :border="infoBorder"
      :rounded="infoRounded"
      :class="infoCustomClasses"
    >
      <div v-if="customImage" class="d-flex align-center">
        <ImageWidget
          :params="{
            isImage: true,
            imageName: imageName,
            imageHeight: imageHeight,
            imageWidth: imageWidth,
            maxWidth: imageWidth,
          }"
        ></ImageWidget>
        <span class="ml-3">{{ infoContent }}</span>
      </div>
      <template v-else>
        {{ infoContent }}
      </template>
    </v-alert>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import ImageWidget from "./ImageWidget.vue";
export default {
  name: "InfoWidget",
  props: {
    params: Object,
  },
  components: { ImageWidget },
  setup(props) {
    const {
      infoColor,
      infoTheme,
      infoIcon,
      infoDensity,
      infoContent,
      infoVariant,
      infoTitle,
      infoBorder,
      infoRounded,
      infoClass,
      infoCustomClasses,
      customImage,
      imageName,
      imageHeight,
      imageWidth,
      imageCustomStyles,
    } = reactive(props.params);
    return {
      infoColor,
      infoTheme,
      infoIcon,
      infoDensity,
      infoContent,
      infoVariant,
      infoTitle,
      infoBorder,
      infoRounded,
      infoClass,
      infoCustomClasses,
      customImage,
      imageName,
      imageHeight,
      imageWidth,
      imageCustomStyles,
    };
  },
};
</script>

<style scoped lang="scss">
:deep .v-alert {
  .v-alert__prepend {
    margin-inline-end: 5px;
    background: $white;
    border-radius: 50%;
    font-size: $font-size-xs;

    @media (min-width: $device-width-md) {
      font-size: $font-size-xs;
    }
  }
  @media (max-width: 768px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: $font-size-sm;
    .v-alert__prepend {
      margin-inline-end: 10px;
    }
  }
  &.text-custom {
    background-color: $alert-default-bg;
    .v-alert__underlay {
      background-color: $alert-default-bg;
    }
    .v-alert__content {
      color: $gray-800;
      text-align: left;
      font-size: $font-size-xs;
      font-weight: 400;
      line-height: 15px;
      @media (min-width: $device-width-md) {
        font-size: $font-size-sm;
      }
    }
    .mdi-shield-account {
      color: $primary;
      font-size: $font-size-base;
      padding: 12px;
    }
  }
  &.custom-orange {
    background-color: $alert-orange-bg;
    .v-alert__underlay {
      background-color: $alert-orange-bg;
    }
  }

  &.custom-purple {
    background-color: $alert-purple-bg;
    .v-alert__underlay {
      background-color: $alert-purple-bg;
    }
  }
}
.margin-left-auto {
  @media (min-width: 960px) {
    margin-left: auto;
  }
}
</style>
