// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

// Vuetify
import { createVuetify } from "vuetify";

const cstmLtTheme = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    primary: "#3f5bd9",
    secondary: "#5d5e63",
    error: "#F65E5A",
    info: "#2196F3",
    success: "#1EC7B6",
    warning: "#f9ba4d",
  },
};
export default createVuetify(
  {
    theme: {
      defaultTheme: "cstmLtTheme",
      themes: {
        cstmLtTheme,
      },
    },
    components,
    directives,
  }
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
);
