<template>
  <v-row>
    <v-col class="d-flex flex-column" cols="12">
      <v-autocomplete
        v-model="value"
        :items="optionlist"
        :label="SelectLabel"
        :variant="fieldVariant"
        :suffix="textSuffix"
        :class="[{ 'v-field--error': v$?.selectOption?.$error }]"
        class="selectdropdown"
        @change="checkValidation()"
        @blur="checkValidation()"
      ></v-autocomplete>
      <span class="text-left text-red" v-if="v$?.selectOption?.$error">
        {{ v$?.selectOption?.$errors[0]?.$message }}
      </span>
    </v-col>
  </v-row>
</template>

<script>
import { reactive, computed } from "@vue/reactivity";
import useValidate from "@vuelidate/core";
import useFormValue from "@/composables/useFormValue";
import { requiredIf } from "@vuelidate/validators";
export default {
  name: "SelectDropdownWidget",
  props: {
    params: Object,
    id: String,
  },
  setup(props) {
    const widgetId = reactive(props.id);
    function checkValidation() {
      this.v$.$validate();
    }
    const {
      SelectLabel,
      optionlist,
      appendIcon,
      prependIcon,
      textSuffix,
      errorstext,
      fieldVariant = "outlined",
      isFieldRequired,
    } = reactive(props.params);
    const { value } = useFormValue(widgetId);
    const rules = computed(() => {
      return {
        selectOption: {
          requiredIfRef: requiredIf(isFieldRequired),
        },
      };
    });
    const state = reactive({
      selectOption: null,
    });
    const v$ = useValidate(rules, state);
    return {
      SelectLabel,
      optionlist,
      appendIcon,
      prependIcon,
      textSuffix,
      errorstext,
      fieldVariant,
      state,
      v$,
      checkValidation,
      isFieldRequired,
      value,
    };
  },

  computed: {},
};
</script>

<style scoped lang="scss">
.selectdropdown {
  :deep {
    .v-input__control {
      border-radius: $border-radius;
      .v-field__overlay {
        background: $white !important;
      }
    }
    .v-field {
      padding: 7px 3px;
      .v-field__outline {
        .v-field__outline__start,
        .v-field__outline__end,
        .v-field__outline__notch::before,
        .v-field__outline__notch::after {
          border-color: $border-color;
          opacity: 1;
        }
      }
      &--active {
        .v-field__outline {
          .v-field__outline__notch::before {
            border-color: $white;
          }
        }
      }

      .v-field__field {
        .v-text-field__suffix {
          opacity: 1;
          background: $white;
          margin-right: -1.25rem;
          padding: 0;
          cursor: pointer;
          color: $primary;
          z-index: 1;
          font-family: $font-base-med;
        }
      }
      .v-field__append-inner {
        i {
          display: none;
        }
        &::after {
          background: url("https://cdn.angelone.in/kyc/images/down-arrow.svg")
            5px 10px no-repeat;
          content: "";
          background-size: 16px;
          width: 35px;
          height: 24px;
        }
      }
    }
    .v-input__details {
      display: none;
      .v-messages {
        text-align: left;
      }
    }
  }
  &.v-field--error {
    :deep .v-input__control {
      border-color: $danger;
    }
  }
}
:deep .v-overlay-container {
  .v-autocomplete__content {
    right: 20px !important;
  }
}
</style>
