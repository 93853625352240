<template>
  <form
    name="PostForm"
    id="frmFormButton"
    :method="method"
    :action="api"
    :class="formClass"
    ref="formRef"
    x-novalidate="true"
  >
    <input
      v-for="(value, key) in bodyParams"
      :key="key"
      :name="key"
      :value="value"
      hidden
    />
    <v-btn
      flat
      :color="btnColor"
      :elevation="btnElevation"
      :size="btnSize"
      :variant="btnVariant"
      :rounded="btnRoundedType"
      :style="customStyles"
      :class="[
        'btn-min-width',
        { 'w-sm-100': hasFullMobWidth },
        {
          'justify-content-center':
            hasLoadingIndicator && loading == 'progress',
        },
        {
          'justify-start': contnetLeftAligned,
        },
      ]"
      :ripple="false"
      type="button"
      :disabled="
        (hasLoadingIndicator && loading == 'progress') || isButtonDisabled
      "
      v-on:click="submitForm"
    >
      <span
        v-if="hasLoadingIndicator && loading == 'progress'"
        class="progress-circle justify-content-center"
      >
        <v-progress-circular
          indeterminate
          width="2"
          size="24"
        ></v-progress-circular>
      </span>
      <span
        v-else-if="hasIcon"
        class="d-flex align-center justify-content-center"
      >
        <v-icon class="btnTextClass" v-if="hasIcon">{{ iconName }}</v-icon>
        <v-img
          class="mr-2"
          :src="getImage(imageName)"
          width="24"
          height="24"
          v-if="hasImage"
        >
        </v-img>
        <span class="btnTextClass">{{ btnText }}</span>
      </span>
      <span v-else class="d-flex align-center justify-content-center">
        <v-icon v-if="hasIcon">{{ iconName }}</v-icon>
        <v-img
          class="mr-2"
          :src="getImage(imageName)"
          width="24"
          height="24"
          v-if="hasImage"
        >
        </v-img>
        {{ btnText }}
      </span>
    </v-btn>
  </form>
</template>
<script>
import useBodyParser from "@/composables/useBodyValue";
import useRuleParser from "@/composables/useRuleParser";
import ActionHandler from "@/handlers/action";
import { getCookieValue } from "@/services/HttpClient";
import { getImage } from "@/utils/utils";
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "FormButton",
  props: {
    params: Object,
    id: {
      type: String,
      requred: true,
    },
  },
  setup(props) {
    const {
      btnText,
      onSubmit,
      btnVariant,
      btnColor,
      btnSize,
      btnElevation = 0,
      btnRoundedType,
      customStyles,
      iconName,
      hasIcon,
      hasImage,
      imageName,
      hasLoadingIndicator,
      hasFullMobWidth,
      contnetLeftAligned,
      disabledRule,
      hasFullWidth = true,
    } = reactive(props.params);
    const widgetId = ref(props.id);
    const store = useStore();
    const parsed = ref(null);
    const api = ref(null);
    const method = ref(null);
    const formRef = ref();
    let parsedBodyData;
    const isButtonDisabled = computed({
      get() {
        if (disabledRule) {
          return useRuleParser(disabledRule);
        } else {
          return store.state[widgetId.value];
        }
      },
    });
    const formClass = ref();
    formClass.value = hasFullWidth ? "w-100" : "";
    const loading = computed({
      get() {
        return store.state.loadingIndicators[props.id];
      },
    });

    async function submitForm() {
      switch (onSubmit.constructor) {
        case Array:
          for (let i = 0; i < onSubmit.length; i++) {
            if (onSubmit[i].method == "FORM_POST") {
              if (useRuleParser(onSubmit[i].triggerWhen)) {
                store.state.loadingIndicators[props.id] = "progress";
                api.value = parseValue(onSubmit[i].api);
                method.value = "POST";
                parsedBodyData = useBodyParser(onSubmit[i].bodyParameters);

                parsed.value = parsedBodyData;
                setTimeout(() => {
                  formRef.value.submit();
                }, 1000);
              }
            } else if (onSubmit[i].method == "FORM_GET") {
              const appVersion = "6.1.0";
              const token = getCookieValue(
                import.meta.env.VITE_APP_GUEST_COOKIE
              );
              let headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Accept-Language": "en-US",
                "X-appVersion": `${appVersion}|${appVersion}`,
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,
                "X-source": "spark-web",
              };
              if (await useRuleParser(onSubmit[i].triggerWhen)) {
                store.state.loadingIndicators[props.id] = "progress";
                api.value = onSubmit[i].api;
                method.value = await "GET";
                setTimeout(() => {
                  formRef.value.submit({
                    target: "_blank",
                    params: {},
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      "Accept-Language": "en-US",
                      "X-appVersion": `${appVersion}|${appVersion}`,
                      "Access-Control-Allow-Origin": "*",
                      Authorization: `Bearer ${token}`,
                      "X-source": "spark-web",
                    },
                  });
                }, 1000);
              }
            } else if (onSubmit[i]?.triggerWhen) {
              if (
                onSubmit[i]?.triggerWhen &&
                !useRuleParser(onSubmit[i]?.triggerWhen)
              ) {
                if (onSubmit[i]?.continueNextAction) {
                  continue;
                }
                return;
              } else {
                await ActionHandler.handleSingleAction(
                  onSubmit[i],
                  widgetId.value,
                  formRef.value
                );
              }
            } else {
              await ActionHandler.handleSingleAction(
                onSubmit[i],
                widgetId.value,
                formRef.value
              );
            }
          }
          break;
        case Object:
          switch (onSubmit.method) {
            case "FORM_POST":
              store.state.loadingIndicators[props.id] = "progress";
              api.value = parseValue(onSubmit.api);
              method.value = "POST";
              parsed.value = await useBodyParser(onSubmit.bodyParameters);
              setTimeout(() => {
                formRef.value.submit();
              }, 1000);

              break;
            case "FORM_GET":
              store.state.loadingIndicators[props.id] = "progress";
              api.value = parseValue(onSubmit.api);
              method.value = "GET";
              setTimeout(() => {
                formRef.value.submit();
              }, 1000);
              break;
            default:
              ActionHandler.handleAction(
                onSubmit,
                widgetId.value,
                formRef.value
              );
              break;
          }
          break;
      }
    }

    onMounted(() => {
      store.state.loadingIndicators[props.id] = "";
    });

    function parseValue(data) {
      if (data && data.includes("env:")) {
        const [type, keyParam] = data.split(":");
        if (type === "env") {
          return import.meta.env[keyParam];
        }
      }
      return data;
    }

    return {
      api,
      method,
      btnText,
      bodyParams: parsed,
      btnVariant,
      btnColor,
      btnSize,
      btnElevation,
      btnRoundedType,
      customStyles,
      iconName,
      hasIcon,
      hasImage,
      submitForm,
      formRef,
      loading,
      hasLoadingIndicator,
      isButtonDisabled,
      hasFullMobWidth,
      contnetLeftAligned,
      imageName,
      getImage,
      disabledRule,
      formClass,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn-min-width {
  min-width: 180px;
}

.progress-circle {
  text-align: center;
  display: inline-flex;
}

.w-sm-100 {
  @media (max-width: 600px) {
    width: 100%;
  }
}

:deep .v-btn {
  &.v-btn--disabled.v-btn--variant-outlined {
    opacity: 1;
    border-color: $gray-400;
  }
}

.btnTextClass {
  color: blue;
}
</style>
