import { calculateAge } from "@/validation/validation";
import dayjs from "dayjs";
/* prettier-ignore */
import store from "@/store/store";
import { getCookieValue } from "@/services/HttpClient";
const DEFAULT_STATE = true;

export default function useRuleParser(condition) {
  let result = false;
  /*
        validate if conditions is a valid condition
        if condition is invalid we want widget to be visible
    */
  if (!condition) return DEFAULT_STATE;
  if (condition.constructor !== String) {
    //TODO show a warning here
    return DEFAULT_STATE;
  }
  if (condition.includes("|<>|")) {
    const [firstRule, validationRule, secondRule] = condition.split("|<>|");
    const firstRuleResult = checkConditons(firstRule);
    const secondRuleResult = checkConditons(secondRule);

    if (validationRule === "&&") {
      result = firstRuleResult && secondRuleResult;
    } else if (validationRule === "||") {
      result = firstRuleResult || secondRuleResult;
    }
  } else {
    result = checkConditons(condition);
  }

  return result;
}

const checkConditons = (condition) => {
  if (condition.includes("&&")) {
    const conditions = condition.split("&&");
    for (let i = 0; i < conditions.length; i++) {
      let conditionResult = getDataFromCond(conditions[i]);
      if (!conditionResult) {
        return false;
      }
    }
    return true;
  } else if (condition.includes("OR")) {
    const conditions = condition.split("OR");
    for (let i = 0; i < conditions.length; i++) {
      let conditionResult = getDataFromCond(conditions[i]);
      if (conditionResult) {
        return true;
      }
    }
    return false;
  } else {
    return getDataFromCond(condition);
  }
};

export function getDataFromCond(condition) {
  let result = false;
  const [field, typeConditionValue] = condition.split(":");
  const [type, cond, expectedValue, defaultValue] =
    typeConditionValue.split("|");

  if (!field || !type || expectedValue == undefined) {
    //TODO show a warning here
    return DEFAULT_STATE;
  }
  const formData = store?.state;
  if (!formData) return DEFAULT_STATE;
  const isVariableValue = expectedValue.split("")[0] === "$";

  let derivedRHSValue = isVariableValue
    ? findAllByKey(formData, expectedValue)
    : expectedValue;

  const fieldArray = field.split(".");
  const derivedLHSValue = ["getCookieVal", "sessionStorage"].includes(type)
    ? field
    : findAllByKey(formData, fieldArray);

  if (derivedLHSValue == null && defaultValue) {
    result = toBoolean(defaultValue);
    return result;
  }

  result = evaluateCondition(type, cond, derivedRHSValue, derivedLHSValue);
  return result;
}

export function evaluateCondition(
  type,
  cond,
  derivedRHSValue,
  derivedLHSValue
) {
  let result = false;
  /*
        We have valid fields here, lets evaluate condition
    */
  const screenWidth =
    window?.innerWidth ||
    document?.documentElement?.clientWidth ||
    document?.body?.clientWidth;

  switch (type) {
    case "string":
      switch (cond) {
        case "=":
          derivedRHSValue =
            derivedRHSValue === "undefined" ? undefined : derivedRHSValue;
          derivedRHSValue = derivedRHSValue === "null" ? null : derivedRHSValue;
          result = derivedLHSValue == derivedRHSValue;
          break;
        case "!=":
          derivedRHSValue =
            derivedRHSValue === "undefined" ? undefined : derivedRHSValue;
          derivedRHSValue = derivedRHSValue === "null" ? null : derivedRHSValue;
          result = derivedLHSValue !== derivedRHSValue;
          break;
      }
      break;
    case "boolean":
      switch (cond) {
        case "=":
          result = derivedLHSValue == toBoolean(derivedRHSValue);
          break;
      }
      break;
    case "number":
      // eslint-disable-next-line no-case-declarations
      let lhs = parseInt(derivedLHSValue) || 0;
      // eslint-disable-next-line no-case-declarations
      let rhs = parseInt(derivedRHSValue) || 0;
      switch (cond) {
        case "=":
          result = lhs == rhs;
          break;
        case "<":
          result = lhs < rhs;
          break;
        case ">":
          result = lhs > rhs;
          break;
        case "<=":
          result = lhs <= rhs;
          break;
        case ">=":
          result = lhs >= rhs;
          break;
      }
      break;
    case "age":
      switch (cond) {
        case "=":
          result =
            calculateAge(
              dayjs(derivedLHSValue, "DD/MM/YYYY").format("YYYY/MM/DD")
            ) == parseInt(derivedRHSValue);
          break;
        case "<":
          result =
            calculateAge(
              dayjs(derivedLHSValue, "DD/MM/YYYY").format("YYYY/MM/DD")
            ) < parseInt(derivedRHSValue);
          break;
        case ">":
          result =
            calculateAge(
              dayjs(derivedLHSValue, "DD/MM/YYYY").format("YYYY/MM/DD")
            ) > parseInt(derivedRHSValue);
          break;
        case "<=":
          result =
            calculateAge(
              dayjs(derivedLHSValue, "DD/MM/YYYY").format("YYYY/MM/DD")
            ) <= parseInt(derivedRHSValue);
          break;
        case ">=":
          result =
            calculateAge(
              dayjs(derivedLHSValue, "DD/MM/YYYY").format("YYYY/MM/DD")
            ) >= parseInt(derivedRHSValue);
          break;
      }
      break;
    case "array":
      result = derivedRHSValue.includes(derivedLHSValue);
      break;
    case "object":
      result = DEFAULT_STATE;
      break;
    case "notnull":
      result = !!derivedLHSValue;
      break;
    case "null":
      result = derivedLHSValue == "" || derivedLHSValue == null;
      break;
    case "isMobile":
      result = screenWidth < 500;
      break;
    case "checkInStoreArr":
      result = derivedLHSValue?.includes(derivedRHSValue);
      break;
    case "arrayLength":
      switch (cond) {
        case "=":
          result = derivedLHSValue?.length === parseInt(derivedRHSValue);
          break;
        case "!=":
          result = derivedLHSValue?.length !== parseInt(derivedRHSValue);
          break;
        case ">":
          result = derivedLHSValue?.length > parseInt(derivedRHSValue);
          break;
        case "<":
          result = derivedLHSValue?.length < parseInt(derivedRHSValue);
          break;
      }
      break;
    case "getCookieVal":
      switch (cond) {
        case "=":
          result = getCookieValue(derivedLHSValue) === derivedRHSValue;
          break;
        case "!=":
          result = getCookieValue(derivedLHSValue) !== derivedRHSValue;
          break;
      }
      break;

    case "sessionStorage":
      console.log(
        "Session storage",
        sessionStorage.getItem(derivedLHSValue) === derivedRHSValue
      );
      switch (cond) {
        case "=":
          derivedRHSValue =
            derivedRHSValue === "undefined" ? undefined : derivedRHSValue;
          derivedRHSValue = derivedRHSValue === "null" ? null : derivedRHSValue;
          result = sessionStorage.getItem(derivedLHSValue) === derivedRHSValue;
          break;
        case "!=":
          derivedRHSValue =
            derivedRHSValue === "undefined" ? undefined : derivedRHSValue;
          derivedRHSValue = derivedRHSValue === "null" ? null : derivedRHSValue;
          result = sessionStorage.getItem(derivedLHSValue) !== derivedRHSValue;
          break;
      }
      break;
    case "rollout":
      switch (cond) {
        case "=":
          result =
            calculateRollOutPercentage(
              derivedLHSValue,
              store.state.staticConfigs[derivedRHSValue]
            ) === true;
          break;
        case "!=":
          result =
            calculateRollOutPercentage(
              derivedLHSValue,
              store.state.staticConfigs[derivedRHSValue]
            ) === false;
          break;
      }

      break;

    default:
      // unmatchd type return DEFAULT State
      result = DEFAULT_STATE;
  }
  return result;
}

export function toBoolean(value) {
  var strValue = String(value).toLowerCase();
  strValue =
    !isNaN(strValue) &&
    strValue !== "0" &&
    strValue !== "" &&
    strValue !== "null" &&
    strValue !== "undefined"
      ? "1"
      : strValue;
  return strValue === "true" || strValue === "1" ? true : false;
}

export function findAllByKey(obj, keyArray) {
  if (keyArray.constructor == Array) {
    for (let i = 0; i < keyArray.length; i++) {
      let value = obj?.[keyArray?.[i]];

      if (Array.isArray(value)) {
        return value;
      } else if (typeof value == "object") {
        keyArray.splice(i, 1);
        return findAllByKey(value, keyArray);
      } else if (
        typeof value == "string" ||
        typeof value == "boolean" ||
        typeof value == "number"
      ) {
        return value;
      }
    }
  } else {
    return obj[keyArray];
  }
}
