<template>
  <div class="container" :style="customStyles">
    <v-radio-group v-model="value">
      <template v-slot:label>
        <p class="paymentHeading">Choose a method</p>
      </template>
      <v-radio
        :value="VERIFY_WITH_UPI"
        true-icon="mdi-check-circle"
        @click="chooseMehtodAnalytics(VERIFY_WITH_UPI)"
      >
        <template v-slot:label>
          <div class="labelContainer">
            <div style="text-align: left">
              <span class="label">Verify with UPI</span>
              <img :src="safeAndFastImg" style="margin-left: 16px" />
            </div>
            <p class="subLabel">
              Complete a quick ₹1 payment using UPI. Rest assured, your ₹1 will
              be promptly refunded to your account within 48 hours.
            </p>
          </div>
        </template>
      </v-radio>
      <div v-if="showUPIApps" class="UPIAppsContaier">
        <p style="color: #6a7582">Pick a UPI app to make payment</p>
        <div style="display: flex; gap: 5px; width: 100%">
          <section class="appImg">
            <img :src="phonepeImg" @click="generateDeepLink('phonepe')" />
          </section>
          <section class="appImg">
            <img :src="gpayImg" @click="generateDeepLink('gpay')" />
          </section>
          <section class="appImg">
            <img
              :src="paytmImg"
              @click="generateDeepLink('paytm')"
              v-if="mobilePlatform !== 'IOS'"
            />
          </section>
        </div>
      </div>
      <v-radio
        :value="VERIFY_WITH_BANK"
        true-icon="mdi-check-circle"
        @click="chooseMehtodAnalytics(VERIFY_WITH_BANK)"
      >
        <template v-slot:label>
          <div class="labelContainer">
            <p class="label">Verify with bank account information</p>
            <p class="subLabel">
              Provide your bank account number and IFSC code
            </p>
          </div>
        </template>
      </v-radio>
    </v-radio-group>
    <v-btn
      v-if="!isMobile"
      color="primary"
      size="large"
      style="margin: auto"
      :disabled="continueButtonDisable"
      @click="onContinueClick"
      :ripple="false"
      :loading="continueBtnLoader"
    >
      Continue
    </v-btn>
  </div>

  <v-bottom-sheet
    class="d-block d-md-none bottom-sheet"
    v-model="bottomSheetOpen"
    :class="[{ 'display-sheet': bottomSheetOpen }]"
    v-if="isMobile"
  >
    <v-overlay v-model="bottomSheetOpen" @click="resetStates(true)"></v-overlay>
    <div class="bottomSheetContainer">
      <div>
        <img :src="BOTTOM_SHEET_CONFIG[transactionStatus]?.imgSrc" />
        <p>{{ BOTTOM_SHEET_CONFIG[transactionStatus]?.label }}</p>
        <p style="color: #6a7582">
          {{ BOTTOM_SHEET_CONFIG[transactionStatus]?.subLabel }}
        </p>
      </div>
      <p v-if="transactionStatus === 'pending'">
        Not working?
        <span
          class="textBoldBlue"
          @click="routeToBankManually({ callAnalytics: true })"
        >
          Enter bank details manually</span
        >
      </p>
      <p
        v-if="transactionStatus === 'error'"
        class="textBoldBlue"
        @click="resetStates"
      >
        Try Again
      </p>
    </div>
  </v-bottom-sheet>
  <SlottedPopup
    :id="widgetId + 'qrCode'"
    :params="{
      hasHeader: true,
      hasMaxWidth: true,
      persistent: transactionStatus === 'success',
      title: '',
    }"
    :visible="showPopup"
    @onClose="resetStates(true)"
  >
    <div class="popupContainer" v-if="!showStatusWeb && showPopup">
      <img :src="angelOneLogo" style="margin-left: 40px" />
      <v-btn
        size="small"
        class="close-btn close"
        variant="text"
        icon="mdi-close"
        color="gray"
        @click="resetStates(true)"
        ripple="false"
      ></v-btn>
      <h2>SCAN & PAY</h2>
      <p style="color: #6a7582">
        Scan the QR code below with a UPI App linked to your bank account that
        you want to register with Angel One
      </p>
      <h2>₹ 1</h2>
      <img :src="qrImage" height="200" width="200" />
      <img :src="bhimLogo" style="margin-top: 4px" />
      <img :src="upiAppsImg" style="margin-top: 4px" />
      <p class="qrTimer" v-if="minutes != null && minutes >= 0 && seconds >= 0">
        <span>This QR code is valid for</span>
        <span>
          {{ minutes < 10 ? `0${minutes}` : minutes }} :
          {{ seconds < 10 ? `0${seconds}` : seconds }}
        </span>
      </p>
    </div>
    <div class="popupStatus" v-if="showStatusWeb">
      <img :src="BOTTOM_SHEET_CONFIG[transactionStatus]?.imgSrc" />
      <p>{{ BOTTOM_SHEET_CONFIG[transactionStatus]?.message }}</p>
      <h2>₹1</h2>
      <p>{{ BOTTOM_SHEET_CONFIG[transactionStatus]?.label }}</p>
      <p style="color: #6a7582">
        {{ BOTTOM_SHEET_CONFIG[transactionStatus]?.subLabel }}
      </p>
      <v-btn
        v-if="!isMobile && transactionStatus !== 'success'"
        color="primary"
        size="large"
        style="margin: auto"
        @click="resetStates(true)"
        ripple="false"
      >
        close
      </v-btn>
    </div>
  </SlottedPopup>
</template>
<script setup>
import useFormValue from "@/composables/useFormValue";
import ActionHandler from "@/handlers/action";
import { ref, watch, computed, reactive, onUnmounted, onMounted } from "vue";
import { checkPlatform, getImage, base64ToBlob } from "@/utils/utils";
import { showInfoMessage, http } from "@/services/HttpClient";
import {
  BOTTOM_SHEET_CONFIG,
  VERIFY_WITH_UPI,
  VERIFY_WITH_BANK,
  STATUS_API_CALL_INTERVAL_DURATION,
  TRANSACTION_STATUS_ENDPOINT,
  SKIP_TO_BANK_MANUALLY_ENDPOINT,
  TRANSACTION_START_ACTION,
  TRANSACTION_START_API,
  M_WEB,
  D_WEB,
} from "@/constants/bandRPDConstants";
import useMobileTabletDetector from "@/composables/useMobileDetector";
import LocalNavigator from "@/composables/useNavigation";
import { useStore } from "vuex";
import SlottedPopup from "./SlottedPopup.vue";
import CleaverTap from "@/composables/Clevertap";
import { watchEffect } from "vue";
import {
  impressionRPDAnalytics,
  chooseUPIApp,
  chooseMethodRPD,
  verifyingUpiPayment,
  upiErrorAnalytics,
  bankAccountVerifiedAnalytics,
  enterDetailsAnalytics,
  proceedBtnAnalytics,
} from "@/utils/bankRPDUtil";

const props = defineProps({
  params: Object,
  id: String,
  layoutData: Object,
});
const { customStyles } = reactive(props.params);
const widgetId = reactive(props.id);
const gpayImg = getImage("gpay.svg");
const paytmImg = getImage("paytm.svg");
const phonepeImg = getImage("phonepe.svg");
const angelOneLogo = getImage("logo.svg");
const bhimLogo = getImage("BHIMUpi.svg");
const safeAndFastImg = getImage("saferAndFaster.svg");
const upiAppsImg = getImage("upiApps.svg");

const store = useStore();
const RPD_TIMER = store.state.staticConfigs["rpdTimer"] || 1500;

let statusAPIIntervalID = ref(null);
let statusAPICallTimeoutID = ref(null);
let minutes = ref(null);
let seconds = ref(0);
let transactionStatus = ref();
let bottomSheetOpen = ref(false);
let continueButtonDisable = ref(true);
let showPopup = ref(false);
let isStatusCallNeeded = ref(false);

let transactionID = ref(store?.state?.formData?.transactionID || null);
let transactionValidity = ref(null);
let qrBase64 = ref(null);
let qrImage = ref(null);
let continueBtnLoader = ref(false);
let selectedUpiApp = ref();
let mobilePlatform = checkPlatform();

const { value } = useFormValue(widgetId);

let showUPIApps = ref(false);

let isMobile = useMobileTabletDetector();

onMounted(() => {
  value.value = VERIFY_WITH_UPI;
  if (isMobile.value) {
    showUPIApps.value = true;
  }
  continueButtonDisable.value = value.value && !isMobile.value ? false : true;
  const metaData = {
    method: value.value,
    appName: selectedUpiApp.value,
    platform: isMobile.value ? M_WEB : D_WEB,
  };
  impressionRPDAnalytics(metaData);
});

let showStatusWeb = computed(() => {
  return ["success", "error"].includes(transactionStatus.value);
});

const chooseMehtodAnalytics = (method) => {
  const metaData = {
    method,
    platform: isMobile.value ? M_WEB : D_WEB,
  };
  chooseMethodRPD(metaData);
};

const resetStates = (apiCallNeeded = false) => {
  return new Promise((res) => {
    isStatusCallNeeded.value = apiCallNeeded;
    if (statusAPIIntervalID.value) {
      clearInterval(statusAPIIntervalID.value);
      statusAPIIntervalID.value = null;
    }
    if (statusAPICallTimeoutID.value) {
      clearTimeout(statusAPICallTimeoutID.value);
      statusAPICallTimeoutID.value = null;
    }
    if (isMobile.value) {
      bottomSheetOpen.value = false;
    } else {
      showPopup.value = false;
    }
    transactionStatus.value = "";
    transactionValidity.value = 0;
    store.dispatch("setAPIData", {
      [widgetId]: null,
    });
    seconds.value = 0;
    minutes.value = null;
    res();
  });
};

const routeToBankManually = async ({ callAnalytics = false } = {}) => {
  if (callAnalytics) {
    enterDetailsAnalytics({
      message: 'Couldn"t complete verification',
      appName: selectedUpiApp.value,
      platform: isMobile.value ? M_WEB : D_WEB,
    });
  }
  await resetStates();
  const navigateAction = {
    api: SKIP_TO_BANK_MANUALLY_ENDPOINT,
    method: "POST",
  };
  await ActionHandler.handleAPI(navigateAction, {}, {}, widgetId);
  continueBtnLoader.value = false;
};

const onContinueClick = async () => {
  proceedBtnAnalytics({
    method: store.state.formData[widgetId],
    appName: selectedUpiApp.value,
    platform: isMobile.value ? M_WEB : D_WEB,
  });
  const selectedOption = store.state.formData[widgetId];
  continueBtnLoader.value = true;
  if (selectedOption === VERIFY_WITH_BANK) {
    routeToBankManually();
    return;
  }
  await getQR();
};

watch(
  () => store.state.formData[widgetId],
  (updatedVal) => {
    continueButtonDisable.value = updatedVal && !isMobile.value ? false : true;
    if (isMobile.value) {
      showUPIApps = updatedVal === VERIFY_WITH_UPI;
      if (updatedVal === VERIFY_WITH_BANK) {
        routeToBankManually();
      }
    }
  }
);

const getAndroidIntent = (deeplinkURL, selectedApp) => {
  const [_, splittedURL] = deeplinkURL.split("upi://");
  const upperCaseAppName = selectedApp?.toUpperCase();
  const packageName = import.meta.env[`VITE_${upperCaseAppName}_PACKAGE_NAME`];
  let intent = `intent://${splittedURL}#Intent;scheme=upi;package=${packageName};end;`;
  return intent;
};

const checkSuccessStatus = (res) => {
  const apiData = res?.data;
  if (apiData?.data) {
    transactionStatus.value = apiData.data.status;
    if (apiData.data.status === "error") {
      upiErrorAnalytics({
        message: 'Couldn"t complete transaction',
        appName: selectedUpiApp.value,
        platform: isMobile.value ? M_WEB : D_WEB,
      });
      bankAccountVerifiedAnalytics({
        status: false,
        appName: selectedUpiApp.value,
        platform: isMobile.value ? M_WEB : D_WEB,
      });
    }

    if (apiData.data.status === "success") {
      bankAccountVerifiedAnalytics({
        status: true,
        appName: selectedUpiApp.value,
        platform: isMobile.value ? M_WEB : D_WEB,
      });
      statusAPIIntervalID.value && clearInterval(statusAPIIntervalID.value);
      const storeValues = [
        { key: "bankName", value: apiData.data.bank_name },
        { key: "ifsc", value: apiData.data.bank_ifsc_code },
        { key: "bankAccountNumber", value: apiData.data.bank_account_number },
        { key: "bankAccountName", value: apiData.data.bank_account_name },
      ];
      store.dispatch("setFormDataMultiple", [...storeValues]);

      setTimeout(async () => {
        await resetStates();
        transactionID.value = null;
        CleaverTap.addEvents("KYC_CTA_Clicked", {
          event_type: "bank_RPD btn click",
          event_category: "KYC2.0",
          event_action: "bank_RPD",
          AUC: store.state.formData.appNumber,
        });
        LocalNavigator.push("/confirmBankDetails");
      }, RPD_TIMER);
      return true;
    }
  }
};

const getTransactionStatus = async (callOnce = false) => {
  const endpoint = `${TRANSACTION_STATUS_ENDPOINT}${transactionID.value}`;
  if (callOnce) {
    try {
      const res = await http.get(endpoint);
      return checkSuccessStatus(res);
    } catch (err) {
      await resetStates();
      return;
    }
  }
  if (statusAPIIntervalID.value == null && transactionValidity.value) {
    if (isMobile.value) {
      bottomSheetOpen.value = true;
      verifyingUpiPayment({
        appName: selectedUpiApp.value,
        platform: isMobile.value ? M_WEB : D_WEB,
      });
    }
    let apiCallCount = 0;
    statusAPIIntervalID.value = setInterval(async () => {
      try {
        if (apiCallCount === transactionValidity.value * 60) {
          await resetStates();
          showInfoMessage(
            "Transaction timed out, Please try again",
            widgetId,
            3000
          );
          upiErrorAnalytics({
            message: "UPI Timed Out",
            appName: selectedUpiApp.value,
            platform: isMobile.value ? M_WEB : D_WEB,
          });
          return;
        }

        if (!isMobile.value) {
          if (seconds.value === 0 && minutes.value != null) {
            seconds.value = 59;
            minutes.value -= minutes.value === 0 ? 0 : 1;
            return;
          }
          seconds.value -= 1;
        }

        apiCallCount++;
        const res = await http.get(endpoint);
        checkSuccessStatus(res);
      } catch (err) {
        await resetStates();
      }
    }, STATUS_API_CALL_INTERVAL_DURATION);
  }
};

const generateDeepLink = async (selectedApp) => {
  try {
    if (isStatusCallNeeded.value || transactionID.value) {
      const isTransactionSuccess = await getTransactionStatus(true);
      isStatusCallNeeded.value = false;
      if (isTransactionSuccess) {
        return;
      }
    }
    chooseUPIApp({
      appName: selectedApp,
      platform: isMobile.value ? M_WEB : D_WEB,
    });
    store.dispatch("setFormData", {
      key: "upiApp",
      value: selectedApp,
    });
    let body = {
      upi_app_name: selectedApp,
    };
    selectedUpiApp.value = selectedApp;

    if (body.upi_app_name) {
      await ActionHandler.fetchAPI(
        TRANSACTION_START_ACTION,
        body,
        {},
        widgetId
      );
      const apiResp = store.state.apiResponses[widgetId];
      if (apiResp && apiResp.data && apiResp.status === "success") {
        const deeplinkURLIOS = apiResp.data.ios_deeplink_url;
        const commonDeepLinkURL = apiResp.data.deeplink_url;
        const deepLinkURLAndroid = getAndroidIntent(
          commonDeepLinkURL,
          selectedApp
        );

        const platform = checkPlatform();

        if (["android", "IOS"].includes(platform)) {
          window.location.href =
            platform === "android" && deepLinkURLAndroid
              ? deepLinkURLAndroid
              : platform === "IOS" && deeplinkURLIOS
              ? deeplinkURLIOS
              : "";
        }
        transactionID.value = apiResp.data.transaction_id;
        transactionValidity.value = apiResp.data.transaction_validity;
        minutes.value = apiResp.data.transaction_validity;

        getTransactionStatus();
      }
    }
  } catch (err) {
    await resetStates();
  }
};

const getQR = async () => {
  try {
    if (isStatusCallNeeded.value || transactionID.value) {
      const isTransactionSuccess = await getTransactionStatus(true);
      showPopup.value = true;
      isStatusCallNeeded.value = false;
      if (isTransactionSuccess) {
        continueBtnLoader.value = false;
        return;
      }
    }
    let body = {
      request_type: "qr",
    };
    const res = await http.post(TRANSACTION_START_API, { data: body });
    const apiData = res?.data;
    continueBtnLoader.value = false;

    if (apiData?.data) {
      showPopup.value = true;
      transactionID.value = apiData.data.transaction_id;
      qrBase64.value = apiData.data.qr;
      transactionValidity.value = apiData.data.transaction_validity;
      minutes.value = apiData.data.transaction_validity;

      const blob = base64ToBlob(qrBase64.value);
      qrImage.value = URL.createObjectURL(blob);
      getTransactionStatus();
    }
  } catch (err) {
    continueBtnLoader.value = false;
    await resetStates();
  }
};

watchEffect(async () => {
  if (isMobile.value) {
    return;
  }
  if (
    seconds.value === 0 &&
    minutes.value === 0 &&
    showPopup.value &&
    transactionStatus.value !== "success"
  ) {
    await resetStates();
    showInfoMessage("Transaction timed out, Please try again", widgetId, 3000);
    upiErrorAnalytics({
      message: "UPI Timed Out",
      appName: selectedUpiApp.value,
      platform: isMobile.value ? M_WEB : D_WEB,
    });
    return;
  }
});

watch(
  () => transactionID.value,
  (updatedVal) => {
    store.dispatch("setFormData", {
      key: "transactionID",
      value: updatedVal,
    });
  }
);

onUnmounted(() => {
  transactionID.value = null;
  resetStates();
});
</script>

<style scoped lang="scss">
.paymentHeading {
  font-size: 14px;
  margin-left: 16px;
  color: #6a7582;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
}

.labelContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label {
  color: black;
  font-weight: 800;
  text-align: left;
  margin-top: 8px;
}

.subLabel {
  text-align: left;
}

:deep {
  .v-selection-control {
    align-items: flex-start;
    border: 1px solid #d1d4d6;
    margin: 0 10px 0 10px;
    border-radius: 8px;
  }

  .v-selection-control-group {
    gap: 10px;
  }

  .mdi-check-circle {
    color: #52de8c;
  }

  .v-radio {
    padding: 14px;
  }
}

.UPIAppsContaier {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 16px;
}

.appImg {
  text-align: left;
  width: 100px;
  text-decoration: none;
}

.display-sheet {
  height: 300px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  transition: height ease-in 1000ms;
}

.bottomSheetContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  gap: 20px;
}

.popupStatus {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 20px;
}

.textBoldBlue {
  color: #3f5bd9;
  font-weight: 600;
}

.popupContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.qrTimer {
  padding: 10px;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.close {
  position: absolute;
  top: 0px;
  right: 0px;
  left: auto;
}
</style>
