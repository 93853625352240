<template>
  <v-btn
    flat
    :color="btnColor"
    :elevation="btnElevation"
    :size="btnSize"
    :variant="btnVariant"
    :rounded="btnRoundedType"
    :style="customStyles"
    :class="[
      'btn-min-width',
      { 'w-sm-100': hasFullMobWidth },
      { 'btn-arrow-left': btnArrowClass },
      { 'flex-grow-0': imageLeftAligned },
      { 'flex-grow-0 ml-auto': imageRightAligned },
    ]"
    :ripple="false"
    :disabled="
      (hasLoadingIndicator && loading == 'progress') || isButtonDisabled
    "
    :append-icon="appendIcon"
  >
    <span v-if="hasLoadingIndicator && loading == 'progress'">
      <v-progress-circular
        indeterminate
        width="2"
        size="24"
      ></v-progress-circular>
    </span>
    <span v-else class="d-flex align-center" data-testid="btn-text-container">
      <v-icon v-if="hasIcon">{{ iconName }}</v-icon>
      <v-img
        :class="negativeMargin ? 'mr-n3' : 'mr-3'"
        :src="getImage(imageName)"
        :width="imageWidth"
        :height="imageHeight"
        v-if="hasImage"
      >
      </v-img>
      {{ dynamicButtonLabel }}
    </span>
    <p v-if="buttonSubText" class="btnSubLabelText">
      {{ buttonSubText }}
    </p>
  </v-btn>
</template>

<script>
import useRuleParser from "@/composables/useRuleParser";
import { getImage } from "@/utils/utils";
import { reactive } from "@vue/reactivity";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "ButtonWidget",
  props: {
    params: Object,
    id: {
      type: String,
      requred: true,
    },
    layoutData: Object,
  },
  setup(props) {
    const store = useStore();

    const widgetId = reactive(props.id);
    const loading = computed({
      get() {
        return store.state.loadingIndicators[props.id];
      },
    });

    const {
      btnVariant,
      btnText,
      btnColor,
      btnSize,
      btnElevation = 0,
      btnRoundedType,
      customStyles,
      iconName,
      hasIcon,
      btnIcon,
      hasFullMobWidth,
      hasLoadingIndicator,
      hasImage,
      imageName,
      btnArrowClass,
      imageWidth = 24,
      imageHeight = 24,
      imageLeftAligned,
      imageRightAligned,
      negativeMargin = false,
      disabledRule,
      checkRuleAndStateValue,
      storeKey,
      labelDependentOnStoreKey,
      labelKeyChange,
      buttonContentAlignment = "row",
      appendIcon,
      labelFromConstantObj = false,
      constantObj = null,
    } = reactive(props.params);

    const prevValue = ref();
    onMounted(() => {
      if (labelDependentOnStoreKey) {
        const storeVal = store.state.formData?.[storeKey];
        prevValue.value = storeVal;
        if (labelFromConstantObj && constantObj && storeVal) {
          dynamicButtonLabel.value = constantObj[storeVal];
        }
      }
    });
    const isButtonDisabled = computed({
      get() {
        switch (true) {
          case disabledRule && checkRuleAndStateValue:
            return useRuleParser(disabledRule) || store.state[widgetId];
          case !!disabledRule:
            return useRuleParser(disabledRule);
          default:
            return store.state[widgetId];
        }
      },
    });
    const dynamicButtonLabel = ref(btnText);

    const buttonSubText = computed(() => {
      if (storeKey) {
        let storeValue = store.state.formData[storeKey];
        if (Array.isArray(storeValue)) {
          storeValue = storeValue.toString();
          return ` (${storeValue})`;
        }
      }
      return null;
    });
    watch(
      () => store.state.formData[storeKey],
      (currVal) => {
        if (labelDependentOnStoreKey) {
          if (labelFromConstantObj && constantObj) {
            dynamicButtonLabel.value = constantObj[currVal];
          } else if (currVal?.length !== prevValue.value?.length) {
            dynamicButtonLabel.value = labelKeyChange;
          } else {
            dynamicButtonLabel.value = btnText;
          }
        }
      }
    );

    return {
      btnVariant,
      btnColor,
      btnSize,
      btnText,
      btnElevation,
      btnRoundedType,
      customStyles,
      iconName,
      hasIcon,
      btnIcon,
      hasFullMobWidth,
      loading,
      hasLoadingIndicator,
      hasImage,
      imageName,
      btnArrowClass,
      isButtonDisabled,
      imageWidth,
      imageHeight,
      imageLeftAligned,
      imageRightAligned,
      negativeMargin,
      getImage,
      disabledRule,
      checkRuleAndStateValue,
      buttonSubText,
      dynamicButtonLabel,
      buttonContentAlignment,
      appendIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-btn__content {
    flex-direction: v-bind(buttonContentAlignment);
  }
}

.v-btn {
  letter-spacing: initial !important;

  &--variant-plain:not(.btn-arrow-left) {
    color: $btn-variant-plain-color;
    opacity: 1;
  }
}

.btn-min-width {
  min-width: 180px;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.btn-arrow-left {
  font-size: $font-size-lg;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  min-width: 50px;
}

.btnSubLabelText {
  font-weight: 400;
  font-size: 14px;
}

@media (max-width: 600px) {
  .w-sm-100 {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .btnSubLabelText {
    font-weight: 400;
    font-size: 11px;
    max-width: 290px;
  }
}
</style>
