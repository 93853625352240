<template>
  <div style="" class="pdfPreview">
    <div
      v-show="!showLoader"
      id="pdf-viewer"
      ref="pdfViewerRef"
      style="
        height: 100%;
        width: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
      "
    />
    <div v-if="showLoader">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { onMounted } from "vue";
import { ref } from "vue";
import useMobileTabletDetector from "@/composables/useMobileDetector";

const store = useStore();
let pdfURLToTest = ref();
let scale = ref(1);
let isMobile = useMobileTabletDetector();
let showLoader = ref(false);
let pdfViewerRef = ref();
let mitcPageNum = ref(0);

onMounted(() => {
  pdfURLToTest.value = store.state.formData.url;
  mitcPageNum.value = store?.state?.formData?.mitcPageNo || 0;
  showLoader.value = true;
  if (isMobile.value) {
    scale.value = 0.6;
  }
  loadPdf();
});

const loadPdf = () => {
  pdfjsLib.getDocument(pdfURLToTest.value).promise.then(function (pdf) {
    for (let page = 1; page <= pdf.numPages; page++) {
      let canvas = document.createElement("canvas");
      canvas.id = `canvas-${page}`;
      const div = pdfViewerRef.value;
      if (div) {
        div.appendChild(canvas);
        renderPage(page, canvas, pdf);
      }
    }
    showLoader.value = false;
  });
};

function renderPage(pageNumber, canvas, thePdf) {
  thePdf.getPage(pageNumber).then(function (page) {
    let viewport = page.getViewport({ scale: scale.value });
    canvas.height = viewport.height;
    canvas.width = isMobile.value ? viewport.width : 700;
    page.render({
      canvasContext: canvas.getContext("2d"),
      viewport: viewport,
    });

    if (pageNumber === thePdf.numPages) {
      scrollToPage(mitcPageNum.value);
    }
  });
  canvas.style.border = "1px solid black";
}

function scrollToPage(pageNumber) {
  const scrollToEle = document.getElementById(`canvas-${pageNumber}`);
  if (scrollToEle) {
    scrollToEle.scrollIntoView({
      block: "start",
    });
  }
}
</script>
<style scoped>
.pageContainer {
  border: 1px solid black;
}
.pdfPreview {
  height: 65vh;
  width: 100%;
}
@media (max-width: 500px) {
  .pdfPreview {
    height: 55vh;
  }
}
</style>
