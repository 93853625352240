<template>
  <div class="setting-box">
    <v-img
      :src="getImage('cameraPermission2.gif')"
      width="200"
      height="200"
    ></v-img>
    <div class="pt-6">
      <p class="camera-header">AngelOne is blocked from using camera</p>
      <v-list :class="[{ 'max-Width': hasMaxWidth }]">
        <v-list-item v-for="(item, i) in items" :key="i" class="v-list-item-2">
          <div class="numberFont">{{ i + 1 }}</div>
          <v-list-item-title class="text-wrap">
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { getImage, getHost } from "@/utils/utils";
import useMobileTabletDetector from "@/composables/useMobileDetector";

export default {
  name: "BrowserSettings",
  props: {
    params: Object,
  },
  setup(props) {
    const { hasBorder, hasMaxWidth, items } = reactive(props.params);
    const useMobileTabletDetectors = useMobileTabletDetector();
    return {
      hasBorder,
      getImage,
      hasMaxWidth,
      items,
      getHost,
    };
  },
};
</script>

<style scoped lang="scss">
.text-wrap {
  text-wrap: wrap;
}

.numberFont {
  background: $gray-300;
  border-radius: 50%;
  min-width: 24px;
  height: 24px;
  margin: 0.2rem 1rem 0 0;
  font-size: $font-size-xs;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $gray-800;

  @media (min-width: $device-width-md) {
    font-size: $font-size-sm;
  }
}

.setting-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $font-base-reg;
  margin: 0 auto;

  @media only screen and (min-width: 600px) {
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
  }

  .camera-header {
    font-weight: 600;
    font-size: 20px;

    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
}
</style>
