import { ref, onMounted, onBeforeUnmount } from "vue";

const MOBILE_WIDTH_THRESHOLD = 500;

const useMobileTabletDetector = () => {
  const isMobile = ref();

  const checkMobile = () => {
    const screenWidth =
      window?.innerWidth ||
      document?.documentElement?.clientWidth ||
      document?.body?.clientWidth;

    isMobile.value = screenWidth < MOBILE_WIDTH_THRESHOLD;
  };

  onMounted(() => {
    checkMobile();
    window.addEventListener("resize", checkMobile);
  });
  onBeforeUnmount(() => {
    window.removeEventListener("resize", checkMobile);
  });

  return isMobile;
};

export default useMobileTabletDetector;
