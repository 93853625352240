<template>
  <div class="v-stepper">
    <header class="v-stepper__header">
      <template v-for="(step, n) in computed_step" :key="n">
        <div
          class="v-stepper__step"
          :class="[
            { 'v-stepper__step--active': n + 1 === activeStep },
            { 'v-stepper__step--completed': activeStep - 1 > n },
          ]"
          :active="activeStep"
        >
          <span class="v-stepper__icon d-none d-md-block">
            {{ n + 1 }}
          </span>
          <v-divider></v-divider>
          <span class="v-stepper__label d-none d-md-block">
            {{ step.title }}
          </span>
        </div>
      </template>
    </header>
    <p></p>
    <template v-for="(step, n) in computed_step" :key="n">
      <div
        v-if="activeStep == n + 1"
        class="d-flex d-md-none mb-2 justify-space-between flex-basis-100"
      >
        <span class="v-stepper__label--mobile">{{ step?.title }}</span>
        <span class="v-stepper__label--mobile">
          {{ percentCompleted + "% completed" }}
        </span>
      </div>
    </template>
  </div>
</template>
<script>
import { computed, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  name: "StepperWidget",
  props: {
    params: Object,
    activeTab: String,
    percentCompletedObj: String,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    let activeStep = ref(Number);
    activeStep.value = 1;
    let percentCompleted = ref(Number);
    percentCompleted.value = 1;
    const computed_step = computed(() => props?.params?.steps?.value || []);

    const stepperOperation = (nextRoute) => {
      const stepArray = computed_step?.value;
      if (stepArray) {
        stepArray.sort(function (a, b) {
          return a.step - b.step;
        });
        stepArray.find((item) => {
          if (item?.path && item.path.includes(nextRoute?.path)) {
            activeStep.value = (item?.step / 1) * 10;
            percentCompleted.value = ((item?.mobileStep || 0.01) / 1) * 100;
            return true;
          }
        });
      }
    };

    router.beforeEach(async (to, from) => {
      store.dispatch("setRoute", { to, from });
      stepperOperation(to);
    });

    onMounted(() => {
      const route = useRoute();
      stepperOperation(route);
    });

    return {
      activeStep,
      computed_step,
      percentCompleted,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-stepper {
  width: 100%;

  &__header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .v-stepper__step {
    flex: 1 1 auto;
    text-align: left;
    background: transparent !important;
    color: $text-primary !important;
    font-size: $font-size-sm;
    padding: 0;
    margin: 0;
    justify-content: flex-start;

    &--active,
    &--completed {
      .v-stepper__label {
        color: $gray-900;
        font-family: $font-base-med;
        font-size: $font-size-xs;
        font-weight: 500;
        @media (min-width: $device-width-md) {
          font-size: $font-size-sm;
        }
      }

      .v-divider {
        background-color: $primary;
        border-radius: 0 $border-radius $border-radius 0;
        transition: background-color ease-in-out 333ms;
        opacity: 1;
      }
    }

    &--completed {
      .v-divider {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &--active {
      .v-stepper__label {
        font-weight: 600;
      }
    }

    &:first-of-type {
      .v-divider {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    &:last-of-type {
      .v-divider {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  &__label {
    font-size: $font-size-xs;
    color: $gray-800;
    width: 100%;
    line-height: $line-height-16;
    font-weight: 400;
    font-family: $font-base-reg;
    @media (min-width: $device-width-md) {
      font-size: $font-size-sm;
    }
  }

  &__icon {
    margin: 0 0 10px;
    line-height: 1;
  }

  .v-divider {
    border: 0;
    height: 4px;
    max-height: 4px;
    background-color: $gray-500;
    margin: 0 0 10px 0;
    transition: background-color ease-in 333ms;
  }

  .flex-basis-100 {
    flex-basis: 100%;
  }
}
</style>
