import { clickStream } from "@/composables/clickStreamAnalytics";
// const clickStream = new ClickStreamAnalytics();

export const impressionRPDAnalytics = (metaData) => {
  let rpdImpression = {
    event_screen: "s-bankaccountverification",
    event_name: "s-bankaccountverification",
    event_id: "73.0.0.66.0",
    event_type: "impression",
    event_sub_type: "screen",
    event_metadata: {
      method: metaData.method,
      upi: metaData.appName || "",
      platform: metaData.platform,
    },
  };

  clickStream.addEventList(rpdImpression);
};

export const chooseMethodRPD = (metaData) => {
  let rpdChooseMethod = {
    event_screen: "s-bankaccountverification",
    event_name: "choosemethod",
    event_id: "73.0.0.66.1",
    event_type: "click",
    event_sub_type: "button",
    event_metadata: { method: metaData.method, platform: metaData.platform },
  };
  clickStream.addEventList(rpdChooseMethod);
};

export const chooseUPIApp = (metaData) => {
  let rpdUpiApp = {
    event_screen: "s-bankaccountverification",
    event_name: "pickupiapp",
    event_id: "73.0.0.66.2",
    event_type: "click",
    event_sub_type: "button",
    event_metadata: { upi: metaData.appName, platform: metaData.platform },
  };
  clickStream.addEventList(rpdUpiApp);
};

export const proceedBtnAnalytics = (metaData) => {
  let rpdProceedBtnData = {
    event_screen: "s-bankaccountverification",
    event_name: "proceed",
    event_id: "73.0.0.66.3",
    event_type: "click",
    event_sub_type: "button",
    event_metadata: {
      method: metaData.method,
      upi: metaData.appName || "",
      platform: metaData.platform,
    },
  };
  clickStream.addEventList(rpdProceedBtnData);
};

export const verifyingUpiPayment = (metaData) => {
  let rpdVerifying = {
    event_screen: "bs-upiconfirmation",
    event_name: "verifyingupipayment",
    event_id: "73.0.0.66.4",
    event_type: "impression",
    event_sub_type: "screen",
    event_metadata: { upi: metaData.appName, platform: metaData.platform },
  };
  clickStream.addEventList(rpdVerifying);
};

export const upiErrorAnalytics = (metaData) => {
  let rpdError = {
    event_screen: "bs-upierror",
    event_name: "upierror",
    event_id: "73.0.0.66.5",
    event_type: "impression",
    event_sub_type: "screen",
    event_metadata: {
      message: metaData.message,
      upi: metaData.appName || "",
      platform: metaData.platform,
    },
  };
  clickStream.addEventList(rpdError);
};

export const enterDetailsAnalytics = (metaData) => {
  let rpdUpiApp = {
    event_screen: "bs-upierror",
    event_name: "enterdetailsmanually",
    event_id: "73.0.0.66.6",
    event_type: "click",
    event_sub_type: "button",
    event_metadata: {
      message: metaData.message,
      upi: metaData.appName || "",
      platform: metaData.platform,
    },
  };
  clickStream.addEventList(rpdUpiApp);
};

export const bankAccountVerifiedAnalytics = (metaData) => {
  let bankAccountVerified = {
    event_name: "bankaccountverified",
    event_id: "73.0.0.66.7",
    event_type: "api",
    event_sub_type: "apirequest-response",
    event_metadata: {
      status: metaData.status,
      upi: metaData.appName || "",
      platform: metaData.platform,
    },
  };
  clickStream.addEventList(bankAccountVerified);
};

export const bankConfirmationAnalytics = (metaData) => {
  let bankConfirm = {
    event_screen: "s-bankconfirmation",
    event_name: "bankconfirmation",
    event_id: "73.0.0.66.8",
    event_type: "impression",
    event_sub_type: "screen",
    event_metadata: {
      bank_name: metaData.bankName,
      ifsc: metaData.ifsc,
      upi: metaData.appName || "",
      platform: metaData.platform,
    },
  };
  clickStream.addEventList(bankConfirm);
};
export const continueAPIAnalytics = (metaData) => {
  let continuApiData = {
    event_name: "bankconfirmation_continue",
    event_id: "73.0.0.66.9",
    event_type: "api",
    event_sub_type: "apirequest-response",
    event_metadata: {
      status: metaData.status,
      upi: metaData.appName || "",
      platform: metaData.platform,
    },
  };
  clickStream.addEventList(continuApiData);
};

export const continueClickAnalytics = (metaData) => {
  let continuData = {
    event_screen: "s-bankconfirmation",
    event_name: "continue",
    event_id: "73.0.0.66.10",
    event_type: "click",
    event_sub_type: "button",
    event_metadata: {
      upi: metaData.appName || "",
      platform: metaData.platform,
    },
  };
  clickStream.addEventList(continuData);
};

export const editBankDetailsAnaltyics = (metaData) => {
  let editBank = {
    event_screen: "s-bankconfirmation",
    event_name: "editbankdetails",
    event_id: "73.0.0.66.11",
    event_type: "click",
    event_sub_type: "button",
    event_metadata: {
      upi: metaData.appName || "",
      platform: metaData.platform,
    },
  };
  clickStream.addEventList(editBank);
};
