import store from "@/store/store";

export default function useUrlCreator(rawUrl) {
  let url = rawUrl;
  try {
    const {
      state: { formData },
    } = store;
    var variableRegex = new RegExp(/\$([a-zA-Z_]+)/, "g");
    var res = url.matchAll(variableRegex);
    for (const variable of res) {
      url = url.replace(variable[0], formData[variable[1]]);
    }
  } catch (err) {
    console.error("url creation error, skipping default url");
  }
  return url;
}
