import { getImage } from "@/utils/utils";

export const BOTTOM_SHEET_CONFIG = {
  pending: {
    imgSrc: getImage("verify.svg"),
    label: "Verifying",
    message: "Transaction Successful",
    subLabel: "Waiting for UPI Payment Transaction",
  },
  error: {
    imgSrc: getImage("failed.svg"),
    label: "Error",
    message: "Transaction Failed",
    subLabel:
      "If your money has been debited from your bank account, it will be deposited into your account 48hrs.",
  },
  success: {
    imgSrc: getImage("success.svg"),
    label: "Bank account verified and linked",
    subLabel:
      "Rs.1 was debited from your account for completing this verification. It will be refunded within 48 hours.",
  },
};

export const PACKAGE_NAMES = {
  phonepe: "com.phonepe.app",
  paytm: "net.one97.paytm",
  gpay: "com.google.android.apps.nbu.paisa.user",
};

export const VERIFY_WITH_UPI = "VERIFY_WITH_UPI";
export const VERIFY_WITH_BANK = "VERIFY_WITH_BANK";
export const RESET_STATE_TIMEOUT = 1500;
export const STATUS_API_CALL_INTERVAL_DURATION = 1000;

export const TRANSACTION_START_API = "/v1/kyc/rpd";
export const TRANSACTION_STATUS_ENDPOINT = "v1/kyc/rpd?transaction_id=";
export const SKIP_TO_BANK_MANUALLY_ENDPOINT = "v1/kyc/skip/bank/rpd";
export const CONFIRM_RPD_ENDPOINT = "v1/kyc/bank/rpd";
export const M_WEB = "mweb";
export const D_WEB = "dweb";

export const MOBILE_WIDTH_THRESHOLD = 500;

export const TRANSACTION_START_ACTION = {
  api: TRANSACTION_START_API,
  method: "POST",
};
