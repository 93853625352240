<template>
  <div :class="[{ 'border-c': border }]">
    <v-list :class="[{ 'max-Width': hasMaxWidth }]">
      <header class="header" v-if="header" v-text="header"></header>
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        :class="[{ 'v-list-item-2': header }]"
      >
        <v-icon
          class="mr-3"
          v-if="isIcon"
          :icon="item.icon"
          :color="item.colour"
        ></v-icon>
        <div v-if="isNumbered" class="numberFont">{{ i + 1 }}</div>
        <img
          v-if="isImage"
          class="mr-3"
          :src="getImage(item.imageName)"
          width="24"
          height="24"
        />
        <v-list-item-title>
          {{ item.text }}
          <b v-if="isBoldRightText">{{ item.textBold }}</b>
          <span v-if="item.textRight">{{ item.textRight }}</span>
          <span
            style="color: #3f5bd9; cursor: pointer"
            @click="onClickAction"
            >{{ item.blueElementText }}</span
          >
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import ActionHandler from "@/handlers/action";
import { getImage } from "@/utils/utils";
import { reactive } from "@vue/reactivity";

export default {
  name: "ListWidget",
  props: {
    params: Object,
  },
  setup(props) {
    const {
      items,
      isIcon,
      isNumbered,
      isImage,
      hasMaxWidth,
      header,
      border,
      isBoldRightText,
      requestAction,
    } = reactive(props.params);

    function onClickAction() {
      ActionHandler.handleAction(requestAction, "", {});
    }

    return {
      border,
      header,
      items,
      isIcon,
      isImage,
      isNumbered,
      hasMaxWidth,
      getImage,
      isBoldRightText,
      onClickAction,
      requestAction,
    };
  },
};
</script>

<style scoped lang="scss">
* {
  font-family: $font-base-reg;
  color: $gray-800;
}

.numberFont {
  background: $gray-300;
  border-radius: 50%;
  min-width: 24px;
  height: 24px;
  margin: 0.2rem 1rem 0 0;
  font-size: $font-size-xs;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $gray-800;
  @media (min-width: $device-width-md) {
    font-size: $font-size-sm;
  }
}

.v-list {
  margin: 0;
  &.max-Width {
    max-width: 350px;
  }
  .v-list-item {
    &.v-list-item-2 {
      margin-bottom: 0px;
    }
    margin: 0;
    padding: 0;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: auto !important;
    margin-bottom: 12px;
    &-title {
      overflow: initial;
      white-space: initial;
      text-align: left;
      font-size: $font-size-sm;
    }
  }
}
.v-list-item__content {
  display: flex !important;
}
.header {
  font-weight: 700;
  font-size: 13px;
  color: #1d1e20;
  justify-content: flex-start;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.border-c {
  border: 2px solid rgba($color: #e0e0ec, $alpha: 0.5);
  padding: 10px;
  border-radius: 10px;
}
</style>
