/* eslint-disable */
export function isValidURL(s = "") {
  if (typeof s != "string") return false;
  var res = s.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
  );
  return res !== null;
}

export function isBase64(s = "") {
  if (typeof s != "string") return false;
  var res = s.match(
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/,
  );
  return res !== null;
}

export function isDataURL(s) {
  if (typeof s != "string") return false;
  return !!s.match(isDataURL.regex);
}
isDataURL.regex =
  /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
