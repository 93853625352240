import { clickStream } from "@/composables/clickStreamAnalytics";
// let clickStream = new ClickStreamAnalytics();

export default function useDigio() {
  function createEvent(url, status, message) {
    return {
      event_name: "digiosuccess",
      event_id: "73.0.0.9.12",
      event_type: "api",
      event_sub_type: "apirequest-response",
      // eslint-disable-next-line
      event_metadata: `{ url: ${url}, status: ${status}, message: ${message} }`,
    };
  }
  async function onFinishDigio(to) {
    const currentUrl = window.location;
    const urlQuery = currentUrl.toString().split("?");
    const urlParams = new URLSearchParams(urlQuery[urlQuery.length - 1]);
    let status =
      urlParams.get("a1SuccessCallBack") || urlParams.get("a1FailureCallBack");
    if (currentUrl.toString().includes("bank") && status) {
      let message = urlParams.get("message")
        ? urlParams.get("message").split("#")[0]
        : "";
      let url = to.fullPath;
      let apiValidationAnalytics = createEvent(url, status, message);
      await clickStream.addEventList(apiValidationAnalytics);
    }
  }

  return {
    onFinishDigio,
  };
}
