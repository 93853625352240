import {
  calculateRollOutPercentage,
  handleCloseChatBot,
  handleOpenChatBot,
  isObjectEmpty,
} from "@/utils/utils";
import {
  deleteCookie,
  getCookieValue,
  http,
  setCookie,
  showErrorMessage,
  showInfoMessage,
} from "@/services/HttpClient";
import { strToU8, zlibSync } from "fflate";

import Analytics from "@/composables/analytics";
import CONFIGDATA from "../../src/config/configData.json";
import CleaverTap from "@/composables/Clevertap";
import CryptoJS from "crypto-js";
import LocalNavigator from "@/composables/useNavigation";
import { clickStream } from "@/composables/clickStreamAnalytics";
import { getHttpErrorForAnalytics } from "@/utils/httpUtils";
import { loadingEnum } from "@/constants/enums";
import store from "@/store/store";
import { useMessageonSnackBar } from "@/composables/useMessages";
import usePopupManager from "@/composables/usePopupManager";
import useRuleParser from "@/composables/useRuleParser";
import useUrlCreator from "@/composables/useUrlCreator";
import { useToast } from "vue-toastification";

export const ACTION_TYPES = {
  navigateWithAPI: "navigateWithAPI",
  navigateToRoute: "navigateToRoute",
  openWebView: "openWebView",
  openDeepLink: "openDeepLink",
  openBotomSheet: "openBotomSheet",
  openCallback: "openCallback",
  callAPI: "callAPI",
  callAPIOnLoad: "callAPIOnLoad",
  captureImage: "captureImage",
  openPopup: "openPopup",
  closePopup: "closePopup",
  // uploadSignature: "uploadSignature",
  goBack: "goBack",
  forceGoBack: "forceGoBack",
  popToRoute: "popToRoute",
  closeWindow: "closeWindow",
  showMessage: "showMessage",
  callAnalyitcs: "callAnalyitcs",
  ignorePrompt: "ignorePrompt",
  redirectToUrl: "redirectToUrl",
  redirectToExternaPageNwTab: "redirectToExternaPageNwTab",
  redirect: "redirect",
  addDataToForm: "addDataToForm",
  addArrayElement: "addArrayElement",
  clearFormData: "clearFormData",
  initiateChatBot: "initiateChatBot",
  unloadChatBot: "unloadChatBot",
  clearAPIResponses: "clearAPIResponses",
  setFormData: "setFormData",
  clearEditIndex: "clearEditIndex",
  CROP: "CROP",
  setSession: "setSession",
  clearSession: "clearSession",
  callAPILogin: "callAPILogin",
  callAPIOTPVerification: "callAPIOTPVerification",
  clevertapUserProfile: "clevertapUserProfile",
  cleverTapEvents: "cleverTapEvents",
  sendDataInWhatsApp: "sendDataInWhatsApp",
  openPopupWithTimer: "openPopupWithTimer",
  openChatBot: "openChatBot",
  closeChatBot: "closeChatBot",
  copyStateValue: "copyStateValue",
  setCookieValue: "setCookieValue",
  setStateFromCookieValue: "setStateFromCookieValue",
  setBankCode: "setBankCode",
  showToast: "showToast",
};

export const API_ACTION_TYPES = {
  navigate: "navigate",
  openBottomSheet: "openBottomSheet",
  openWebView: "openWebView",
  openPopup: "openPopup",
};

export function clearUploadKeys() {
  store.dispatch("setClearLoading");
  store.dispatch("setClearProgress");
}

class ActionHandler {
  static router;
  static isAndroid = /(android)/i.test(navigator.userAgent);
  static isiOS = /(ipod|iphone|ipad)/i.test(navigator.userAgent);

  static useRouter(routerRef) {
    this.router = routerRef;
  }
  static showToast(toastMessage) {
    let toast = useToast();
    toast.error(toastMessage, 3000);
  }

  // static clickStream = new ClickStreamAnalytics();
  static handleAction(action, widgetId, formRef, screenId) {
    if (action == null) return;
    if (action.constructor === Array) {
      // It is multiple action
      action.forEach((singleAction) => {
        if (
          singleAction?.callAPIWhen &&
          useRuleParser(singleAction?.callAPIWhen)
        ) {
          ActionHandler.handleSingleAction(
            singleAction,
            widgetId,
            formRef,
            screenId
          );
        } else if (!singleAction?.callAPIWhen) {
          ActionHandler.handleSingleAction(
            singleAction,
            widgetId,
            formRef,
            screenId
          );
        }
      });
    } else if (action.constructor === Object) {
      // It is single action
      return ActionHandler.handleSingleAction(
        action,
        widgetId,
        formRef,
        screenId
      );
    } else {
      // console.warn("invalid action type supplied");
    }
  }

  /* handling user actions*/
  static async handleSingleAction(action, widgetId, formRef, screenId) {
    const { push, pop, forcePop, popToRoute } = LocalNavigator;
    const { openPopup, closePopup, getActivePopups } = usePopupManager();
    const parsedBody = ActionHandler.dataBuilder(action.bodyParameters);
    const parsedHeader = ActionHandler.dataBuilder(action.headerParams);
    const resetFormDataOnFailure = action.resetFormDataOnFailure || false;
    // let clv = new CleaverTap();

    switch (action.type) {
      case ACTION_TYPES.navigateToRoute:
        push(action.value);
        break;
      case ACTION_TYPES.openPopup:
        clickStream.addEventList(action?.eventsParams);
        return openPopup(action.value, action?.idToWatch);
      case ACTION_TYPES.openPopupWithTimer:
        if (action?.condition && useRuleParser(action?.condition)) {
          clickStream.addEventList(action?.eventsParams);
          return setTimeout(
            openPopup(action.value, action?.idToWatch),
            action?.timerinmilisecond
          );
        } else {
          return;
        }

      case ACTION_TYPES.showMessage:
        useMessageonSnackBar(action.value, action.messageData);
        break;
      case ACTION_TYPES.closePopup:
        if (action?.validationRequired) {
          let { valid, _ } = await ActionHandler.validateForm(formRef, action);
          if (valid) {
            closePopup(action.value);
          }
        } else {
          closePopup(action.value);
        }
        break;
      case ACTION_TYPES.redirectToUrl:
        ActionHandler.redirectToUrl(action.value);
        break;
      case ACTION_TYPES.redirectToExternaPageNwTab:
        ActionHandler.redirectToExternaPageNwTab(parsedBody);
        break;

      case ACTION_TYPES.addArrayElement:
        ActionHandler.addArrayElement(action);
        break;
      case ACTION_TYPES.clearFormData:
        ActionHandler.clearFormData(action.bodyParameters);
        break;
      case ACTION_TYPES.setFormData:
        ActionHandler.setFormData(action.bodyParameters);
        break;
      case ACTION_TYPES.clearAPIResponses:
        ActionHandler.clearAPIResponses(action.bodyParameters);
        break;
      case ACTION_TYPES.clearEditIndex:
        ActionHandler.clearEditIndex(action);
        break;
      case ACTION_TYPES.navigateWithAPI:
        if (formRef == null) {
          return;
        } else {
          let { valid, error } = await ActionHandler.validateForm(
            formRef,
            action
          );
          if (action.addToBodyParams == "bankCode") {
            let banksList = store.state.formData.availableBanks || "";
            let code = store.state.formData.bankCode
              ? store.state.formData.bankCode.toLowerCase()
              : "";
            const bankObj = banksList.find((bank) => {
              let bankCode = bank.BankCode.toLowerCase();
              if (code && code === bankCode) {
                return bank;
              }
            });
            if (bankObj) {
              parsedBody[action.addToBodyParams] = bankObj.BankCode;
            }
          }

          const activeButton = getActivePopups()
            ? "isModalSubmitButton"
            : "isPageSubmitButton";
          if (valid) {
            store.dispatch("manageActiveSubmitButton", {
              state: store.state[activeButton],
              value: false,
            });
            return ActionHandler.handleAPI(
              action,
              parsedBody,
              { headers: parsedHeader },
              widgetId,
              screenId,
              resetFormDataOnFailure
            );
          } else {
            store.dispatch("manageActiveSubmitButton", {
              state: store.state[activeButton],
              value: true,
            });
            if (!error) {
              showErrorMessage("Please submit a valid form", "invalidForm");
            } else {
              showErrorMessage(error, "invalidForm");
            }
          }
        }
        break;
      case ACTION_TYPES.callAPI:
        if (formRef == null) {
          return;
        } else {
          await ActionHandler.validateForm(formRef, action);
          //validation commented
          // if (valid) {
          //   store.dispatch("manageActiveSubmitButton", {
          //     state: store.state[activeButton],
          //     value: false,
          //   });
          // } else {
          //   store.dispatch("manageActiveSubmitButton", {
          //     state: store.state[activeButton],
          //     value: true,
          //   });
          //   if (!error) {
          //     showErrorMessage("Please submit a valid form", "invalidForm");
          //   } else {
          //     showErrorMessage(error, "invalidForm");
          //   }
          // }
          return ActionHandler.fetchAPI(
            action,
            parsedBody,
            {
              header: parsedHeader,
              ...action?.config,
            },
            widgetId
          );
        }

      // break;
      case ACTION_TYPES.callAPILogin:
        if (formRef == null) return;
        // if (await ActionHandler.validateForm(formRef, action)) {
        //   store.dispatch("manageActiveSubmitButton", {
        //     state: store.state[activeButton],
        //     value: false,
        //   });
        // } else {
        //   store.dispatch("manageActiveSubmitButton", {
        //     state: store.state[activeButton],
        //     value: true,
        //   });
        // }
        return ActionHandler.fetchAPILogin(
          action,
          action.bodyParameters,
          { header: parsedHeader },
          widgetId
        );
      case ACTION_TYPES.callAPIOTPVerification:
        if (formRef == null) {
          return;
        } else {
          let { valid, error } = await ActionHandler.validateForm(
            formRef,
            action
          );
          const activeButton = getActivePopups()
            ? "isModalSubmitButton"
            : "isPageSubmitButton";
          if (valid) {
            store.dispatch("manageActiveSubmitButton", {
              state: store.state[activeButton],
              value: false,
            });
            if (action?.isJourneyType === "JointAccount") {
              return ActionHandler.fetchAPIOTPForJointAccount(
                action,
                action.bodyParameters,
                { header: parsedHeader },
                widgetId
              );
            }
            return ActionHandler.fetchAPIOTPVerification(
              action,
              action.bodyParameters,
              { header: parsedHeader },
              widgetId
            );
          } else {
            store.dispatch("manageActiveSubmitButton", {
              state: store.state[activeButton],
              value: true,
            });
            if (!error) {
              showErrorMessage("Please submit a valid form", "invalidForm");
            } else {
              showErrorMessage(error, "invalidForm");
            }
          }
        }
        break;
      case ACTION_TYPES.callAPIOnLoad:
        return ActionHandler.fetchAPI(
          action,
          parsedBody,
          { header: parsedHeader },
          action.widgetId
        );
      case ACTION_TYPES.goBack:
        pop();
        // if (action.clearKey) {
        // clearUploadKeys(action.clearKey);
        clearUploadKeys();
        // }
        break;
      case ACTION_TYPES.addDataToForm:
        if (formRef == null) {
          return;
        } else {
          let { valid, error } = await ActionHandler.validateForm(
            formRef,
            action
          );
          const activeButton = getActivePopups()
            ? "isModalSubmitButton"
            : "isPageSubmitButton";
          if (valid) {
            store.dispatch("manageActiveSubmitButton", {
              state: store.state[activeButton],
              value: false,
            });
            ActionHandler.addDataToForm(
              parsedBody,
              action.arrayKey,
              action.uniqueKey,
              action
            );
          } else {
            store.dispatch("manageActiveSubmitButton", {
              state: store.state[activeButton],
              value: true,
            });
            if (!error) {
              showErrorMessage("Please submit a valid form", "invalidForm");
            } else {
              showErrorMessage(error, "invalidForm");
            }
          }
        }
        break;
      case ACTION_TYPES.forceGoBack:
        forcePop();
        break;
      case ACTION_TYPES.callAnalyitcs:
        if (action?.native) {
          Analytics.analyticsEventLog(action?.native?.bodyParameters);
        }
        if (action?.cs) {
          const forcePush = action?.cs?.forcePush || false;
          clickStream.addEventList(action?.cs?.bodyParameters, forcePush);
        }
        break;
      case ACTION_TYPES.popToRoute:
        popToRoute(action.value);
        break;
      case ACTION_TYPES.closeWindow:
        window.location.href = "https://uattrade.angelbroking.com/";
        break;
      case ACTION_TYPES.ignorePrompt:
        store.dispatch("ignoreBrowserPrompt", action.value);
        break;
      case ACTION_TYPES.setSession:
        ActionHandler.setSessionData(action.value);
        break;
      case ACTION_TYPES.clearSession:
        ActionHandler.clearSessionData(action.value);
        break;
      case ACTION_TYPES.clevertapUserProfile:
        if (action?.ctProfile) {
          CleaverTap.userprofile(action?.ctProfile?.ctUser);
        }
        break;

      case ACTION_TYPES.cleverTapEvents:
        if (action?.ct) {
          CleaverTap.addEvents(
            action?.ct?.event_name,
            action?.ct?.bodyParameters
          );
        }
        break;

      case ACTION_TYPES.sendDataInWhatsApp:
        ActionHandler.sendDataInWhatsApp(action);
        break;

      case ACTION_TYPES.openChatBot:
        if (
          store.state.staticConfigs.enableAIChatbot &&
          calculateRollOutPercentage(
            store.state.formData.appNumber,
            store.state.staticConfigs.chatbot_distributionLoad
          )
        ) {
          store.dispatch("initiateChatBot");
          handleOpenChatBot();
        }
        break;

      case ACTION_TYPES.closeChatBot:
        if (
          store.state.staticConfigs.enableAIChatbot &&
          calculateRollOutPercentage(
            store.state.formData.appNumber,
            store.state.staticConfigs.chatbot_distributionLoad
          ) &&
          store.state.formData.INITIATE_CHATBOT
        ) {
          store.dispatch("unloadChatBot");
          handleCloseChatBot();
        }
        break;
      case ACTION_TYPES.copyStateValue:
        ActionHandler.copyStateValue(action);
        break;
      case ACTION_TYPES.setCookieValue:
        ActionHandler.setCookieValue(action);
        break;
      case ACTION_TYPES.setStateFromCookieValue:
        ActionHandler.setStateFromCookieValue(action);
        break;
      case ACTION_TYPES.setBankCode:
        ActionHandler.setBankCode(action);
        break;

      default:
      // console.warn("THIS ROUTE IS NOT HANDLED");
    }
  }
  static async validateForm(ref, action) {
    if (action?.requestOn == "onChangeVal") {
      if (ref) {
        return {
          valid: true,
          error: null,
        };
      }
    } else {
      if (ref.id === "frmFormButton") return true;
      const validationStatus = await ref.validate();
      return {
        valid: validationStatus?.valid,
        error: validationStatus?.errors[0]?.errorMessages[0] || null,
      };
    }
  }

  static dataBuilder(params) {
    const parsedBody = {};
    for (const key in params) {
      const value = params[key];
      if (value.toString().includes(":")) {
        let [type, keyParam, formDataKey] = value.split(":");
        if (type === "baseUrl") {
          let baseUrl = import.meta.env.VITE_BASEURL;
          let configData = CONFIGDATA.CONFIGDATA;
          let configVal = configData[keyParam];
          let finalUrl = baseUrl + configVal;
          parsedBody[key] = finalUrl;
        } else if (type === "env") {
          parsedBody[key] = import.meta.env[keyParam];
        } else if (type == "JSONVALUE") {
          parsedBody[key] = ActionHandler.buildFROMJSON(value);
        } else if (type === "getFromArr") {
          parsedBody[key] =
            store.state.formData[formDataKey].includes(keyParam);
        } else if (type === "string") {
          parsedBody[key] = keyParam;
        }
      } else {
        parsedBody[key] = store.state.formData[value];
      }
    }
    return parsedBody;
  }

  static buildFROMJSON(data) {
    if (data && data?.includes("JSONVALUE")) {
      const [field, typeConditionValue] = data.split(":");
      const [type, value] = typeConditionValue.split("|");
      if (!field || !type || value == undefined) {
        return undefined;
      }
      if (type == "string") {
        return JSON.stringify(JSON.parse(value));
      }
      return JSON.parse(value);
    } else {
      return undefined;
    }
  }

  static dataBuilderFirLogin(params) {
    const parsedBody = {};
    for (const key in params) {
      const value = params[key];
      if (value.includes(":")) {
        let [type, keyParam] = value.split(":");
        if (type === "baseUrl") {
          let baseUrl = import.meta.env.VITE_BASEURL;
          let configData = CONFIGDATA.CONFIGDATA;
          let configVal = configData[keyParam];
          let finalUrl = baseUrl + configVal;
          parsedBody[key] = value[key].replace(value[key], finalUrl);
        }
      } else {
        const fieldValue = store.state.formData[value];
        if (fieldValue || typeof fieldValue == "boolean") {
          parsedBody[key] = fieldValue;
        }
      }
    }
    return parsedBody;
  }

  static async fetchAPI(
    action,
    body,
    config,
    widgetId,
    infoMessageNeeded = true
  ) {
    const apiEndpoint = useUrlCreator(action.api);
    if (widgetId) {
      store.dispatch("setLoading", { [widgetId]: loadingEnum.progress });
    }
    const startTime = Date.now();
    const networkType = navigator?.connection?.effectiveType || "";
    const apiValidationParameters = {
      event_name: action?.apiValidationEvent?.event_name,
      event_id: action?.apiValidationEvent?.event_id,
      event_type: "api",
      event_sub_type: "apirequest-response",
    };
    const forcePush = action?.forcePush || false;
    switch (action.method) {
      case "POST":
        if (action?.setFlagBeforeCall) {
          store.dispatch("setPanPrefilledLoader", true);
        }
        return http
          .post(apiEndpoint, { data: body }, { ...config })
          .then((res) => {
            if (action?.setFlagOn) {
              if (res?.data?.data?.pan) {
                store.dispatch("setFormData", {
                  key: "panPrefilled",
                  value: true,
                });
              } else {
                store.dispatch("setFormData", {
                  key: "panPrefilled",
                  value: false,
                });
              }
            }
            apiValidationParameters[
              "event_metadata"
            ] = `{ url: ${apiEndpoint}, status: ${
              res?.status == 200 ? "true" : "false"
            }, message: ${
              res?.status == 200
                ? `API Success: ${JSON.stringify(res?.data)}`
                : `API Failure: ${JSON.stringify(res?.data)}`
            }, response-time: ${
              (Date.now() - startTime) / 1000
            }s, network-type: ${networkType}`;
            clickStream.addEventList(apiValidationParameters, forcePush);
            if (widgetId) {
              store.dispatch("setLoading", { [widgetId]: loadingEnum.success });
            }
            if (
              res?.data?.status &&
              res?.data?.status == "success" &&
              infoMessageNeeded === true
            ) {
              showInfoMessage(res?.data?.message, "apiSuccessResp", 2000);
            }

            store.dispatch("setAPIData", {
              key: widgetId,
              value: res?.data,
            });
            ActionHandler.handleAPIData(res?.data?.data);
            if (action?.setFlagBeforeCall) {
              store.dispatch("setPanPrefilledLoader", false);
            }
          })
          .catch((error) => {
            if (widgetId) {
              store.dispatch("setLoading", { [widgetId]: loadingEnum.error });
            }
            store.dispatch("setAPIData", {
              key: widgetId,
              value: error,
            });
            apiValidationParameters[
              "event_metadata"
            ] = `{ url: ${apiEndpoint}, status: ${false}, message: ${getHttpErrorForAnalytics(
              error
            )}, response-time: ${
              (Date.now() - startTime) / 1000
            }s, network-type: ${networkType}}`;
            clickStream.addEventList(apiValidationParameters, forcePush);
            if (action?.setFlagBeforeCall) {
              store.dispatch("setPanPrefilledLoader", false);
            }
          });

      case "GET":
        return http
          .get(apiEndpoint, { ...config })
          .then((res) => {
            apiValidationParameters[
              "event_metadata"
            ] = `{ url: ${apiEndpoint}, status: ${
              res?.status == 200 ? "true" : "false"
            },  message: ${
              res?.status == 200
                ? `API Success: ${JSON.stringify(res?.data)}`
                : `API Failure: ${JSON.stringify(res?.data)}`
            }, response-time: ${
              (Date.now() - startTime) / 1000
            }s, network-type: ${networkType}}`;
            clickStream.addEventList(apiValidationParameters, forcePush);
            store.dispatch("setAPIData", {
              key: widgetId,
              value: res?.data,
            });
            ActionHandler.handleAPIData(res?.data?.data);
            if (widgetId) {
              store.dispatch("setLoading", { [widgetId]: loadingEnum.success });
            }
          })
          .catch((error) => {
            if (widgetId) {
              store.dispatch("setLoading", { [widgetId]: loadingEnum.error });
            }
            apiValidationParameters[
              "event_metadata"
            ] = `{ url: ${apiEndpoint}, status: ${false},  message: ${getHttpErrorForAnalytics(
              error
            )}, response-time: ${
              (Date.now() - startTime) / 1000
            }s, network-type: ${networkType}}`;
            clickStream.addEventList(apiValidationParameters, forcePush);
          });
    }
  }

  static checkOS() {
    const userAgent = navigator.userAgent.toLowerCase();
    switch (true) {
      case /windows/.test(userAgent):
        return "windows";
      case /mac/.test(userAgent):
        return "Mac OS";
      case /linux/.test(userAgent):
        return "Linux";
      case /(android)/i.test(userAgent):
        return "Android";
      case /(ipod|iphone|ipad)/i.test(userAgent):
        return "IOS";
      default:
        return "";
    }
  }

  static async fetchAPILogin(action, body, config, widgetId) {
    const apiEndpoint = useUrlCreator(action.api);
    if (widgetId) {
      store.dispatch("setLoading", { [widgetId]: loadingEnum.progress });
    }
    const reqbody = {
      country_code: "+91",
      mob_no: store.state.formData[body.mobile],
      is_otp_resend: body.isOtpResend,
      user_id: "",
    };
    let captcha = await ActionHandler.loginHeader(
      store.state.formData[body.mobile]
    );
    let headers = {
      "Content-type": "application/json",
      // "x-captcha": captcha,
      "X-DeviceID":
        getCookieValue("platform") || ActionHandler.fnGenerateUniqueID(),
      "x-clientlocalip": localStorage.getItem("myip"),
      "x-clientpublicip": localStorage.getItem("myip"),
      "x-sourceid": 3,
      "x-usertype": 1,
      "X-macaddress": getCookieValue("macAddress") || "00:00:5e:00:53:af",
      ApplicationName: "Kyc-web",
      "X-Appid": "Kyc-web",
      "X-OperatingSystem": ActionHandler.checkOS(),
      "X-Location": "",
      "X-Systeminfo": "",
      "X-Productversion": "",
    };
    headers = {
      ...headers,
      ...captcha,
    };
    const startTime = Date.now();
    const networkType = navigator?.connection?.effectiveType || "";
    const apiValidationParameters = {
      event_name: action?.apiValidationEvent?.event_name,
      event_id: action?.apiValidationEvent?.event_id,
      event_type: "api",
      event_sub_type: "apirequest-response",
    };
    return http
      .post(apiEndpoint, reqbody, { headers })
      .then((res) => {
        if (widgetId) {
          store.dispatch("setLoading", { [widgetId]: loadingEnum.success });
        }
        if (res?.data?.status && res?.data?.status == "success") {
          showInfoMessage(res?.data?.message, "apiSuccessResp", 2000);
        }
        store.dispatch("setAPIData", {
          key: widgetId,
          value: res?.data,
        });
        ActionHandler.handleAPIData(res?.data?.data);
        apiValidationParameters[
          "event_metadata"
        ] = `{ url: ${apiEndpoint}, status: ${
          res?.status == 200 ? "true" : "false"
        }, message: ${
          res?.status == 200
            ? `API Success: ${JSON.stringify(res?.data)}`
            : `API Failure: ${JSON.stringify(res?.data)}`
        }, response-time: ${
          (Date.now() - startTime) / 1000
        }s, network-type: ${networkType}`;
        clickStream.addEventList(apiValidationParameters);
      })
      .catch((error) => {
        if (widgetId) {
          store.dispatch("setLoading", { [widgetId]: loadingEnum.error });
        }
        apiValidationParameters[
          "event_metadata"
        ] = `{ url: ${apiEndpoint}, status: ${false}, message: ${getHttpErrorForAnalytics(
          error
        )}, response-time: ${
          (Date.now() - startTime) / 1000
        }s, network-type: ${networkType}}`;
        clickStream.addEventList(apiValidationParameters);
      });
  }

  static async loginHeader(mobile) {
    let dataVal = [];
    if (getCookieValue("GuestAuthToken")) {
      dataVal = JSON.parse(atob(getCookieValue("GuestAuthToken")));
    }
    let existingVal = dataVal
      .filter((f) => f.MobileNo === mobile)
      .map((m) => ({ accessTokenData: m.GuestAuthToken }))[0];
    if (existingVal != undefined) {
      return { Authorization: "Bearer " + existingVal.accessTokenData };
    } else {
      return { "x-captcha": await ActionHandler.getCapchaCode() };
    }
  }
  static redirectionFromLogin(widgetId) {
    let redirectionUrl = "";
    if (store.state.formData.rawUrl) {
      if (store.state.formData.rawUrl.includes("/#/login/")) {
        redirectionUrl = store.state.formData.rawUrl.replace("/#/login/", "/");
      } else if (store.state.formData.rawUrl.includes("/#/jointAccountLogin")) {
        redirectionUrl = store.state.formData.rawUrl.replace(
          "/#/jointAccountLogin",
          "/"
        );
      } else if (store.state.formData.rawUrl.includes("#/login/")) {
        redirectionUrl = store.state.formData.rawUrl.replace("#/login", "");
      } else if (store.state.formData.rawUrl.includes("#/jointAccountLogin")) {
        redirectionUrl = store.state.formData.rawUrl.replace(
          "#/jointAccountLogin",
          ""
        );
      } else if (store.state.formData.rawUrl.includes("#/register/")) {
        redirectionUrl = store.state.formData.rawUrl.replace("#/register", "");
      }
    } else {
      redirectionUrl = import.meta.env.VITE_BASEURL + "/";
    }

    store.dispatch("ignoreBrowserPrompt", true);
    setTimeout(async () => {
      window.location.replace(redirectionUrl, "");
    }, 1000);
    if (widgetId) {
      store.dispatch("setLoading", { [widgetId]: loadingEnum.success });
    }
  }
  static async fetchAPIOTPVerification(action, body, config, widgetId) {
    const apiEndpoint = useUrlCreator(action.api);

    deleteCookie("journey-type");
    sessionStorage.removeItem("firstHolderAppNumber");

    if (widgetId) {
      store.dispatch("setLoading", { [widgetId]: loadingEnum.progress });
    }
    const reqbody = {
      request_id:
        store?.state?.apiResponses?.[action.requestIdKey]?.data?.request_id,
      country_code: import.meta.env.VITE_COUNTRY_CODE,
      mob_no: store.state.formData[body.mobile],
      otp: store.state.formData[body.mobileOtp],
      source: import.meta.env.VITE_LOGIN_SOURCE,
      app_id: import.meta.env.VITE_APP_ID,
      require_token_flag: "all",
      user_id: "",
    };
    let headers = {
      "Content-type": "application/json",
      "x-clientlocalip": localStorage.getItem("myip"),
      "x-clientpublicip": localStorage.getItem("myip"),
      "X-DeviceID":
        getCookieValue("platform") || ActionHandler.fnGenerateUniqueID(),
      "x-sourceid": 3,
      "x-usertype": 1,
      "X-macaddress": getCookieValue("macAddress") || "00:00:5e:00:53:af",
      ApplicationName: "Spark-web",
      "X-Appid": "Kyc-web",
      "X-OperatingSystem": ActionHandler.checkOS(),
      "X-Location": "",
      "X-Systeminfo": "",
      "X-Productversion": "",
    };
    const startTime = Date.now();
    const networkType = navigator?.connection?.effectiveType || "";
    const apiValidationParameters = {
      event_name: action?.apiValidationEvent?.event_name,
      event_id: action?.apiValidationEvent?.event_id,
      event_type: "api",
      event_sub_type: "apirequest-response",
    };
    return http
      .post(apiEndpoint, reqbody, { headers })
      .then(async (res) => {
        store.dispatch("setAPIData", {
          key: widgetId,
          value: res?.data,
        });
        // push("register");
        ActionHandler.handleAPIData(res?.data?.data);
        apiValidationParameters[
          "event_metadata"
        ] = `{ url: ${apiEndpoint}, status: ${
          res?.status == 200 ? "true" : "false"
        }, message: ${
          res?.status == 200
            ? `API Success: ${JSON.stringify(res?.data)}`
            : `API Failure: ${JSON.stringify(res?.data)}`
        }, response-time: ${
          (Date.now() - startTime) / 1000
        }s, network-type: ${networkType}`;
        clickStream.addEventList(apiValidationParameters);
        if (action?.ctUser) {
          CleaverTap.userprofile(action?.ctUser);
        }
        if (action?.ct) {
          const ct_msg = {
            url: apiEndpoint,
            status: res?.status == 200 ? "true" : "false",
            // api: res?.data,
            networkType: networkType,
          };
          CleaverTap.addEvents(action?.ct?.event_name, {
            ...action?.ct?.bodyParameters,
            ...ct_msg,
          });
        }

        if (res?.data?.status && res?.data?.status == "success") {
          showInfoMessage(res?.data?.message, "apiSuccessResp", 2000);

          if (ActionHandler.isGuest(res?.data)) {
            deleteCookie(import.meta.env.VITE_APP_GUEST_COOKIE);
            setCookie(
              import.meta.env.VITE_APP_GUEST_COOKIE,
              res?.data?.data?.guest_token,
              500
            );

            ActionHandler.journeyDecider(
              res?.data?.data?.guest_token,
              widgetId
            );
          } else if (
            Object.keys(res?.data?.data?.PartyCodeDetails).length > 0
          ) {
            let clientId = Object.keys(res?.data?.data?.PartyCodeDetails)[0];
            let expire = ActionHandler.parseJwt(
              res?.data?.data?.PartyCodeDetails[clientId]
                ?.non_trading_access_token
            );
            // setTokenExp(expire?.exp);
            setCookie(
              "ABNonTradeToken",
              res?.data?.data?.PartyCodeDetails[clientId]
                ?.non_trading_access_token,
              expire.exp
            );
            setCookie(
              "ABNonTradeRefreshToken",
              res?.data?.data?.PartyCodeDetails[clientId]
                ?.non_trading_refresh_token,
              expire.exp
            );
            ActionHandler.checkUserType(
              res?.data?.data?.PartyCodeDetails,
              res?.data
            );
            if (widgetId) {
              store.dispatch("setLoading", {
                [widgetId]: loadingEnum.success,
              });
            }
          }
        }
      })
      .catch((error) => {
        if (widgetId) {
          store.dispatch("setLoading", { [widgetId]: loadingEnum.error });
        }

        if (action?.ct) {
          const ct_msg = {
            url: apiEndpoint,
            status: "false",
            networkType: networkType,
          };
          CleaverTap.addEvents(action?.ct?.event_name, {
            ...action?.ct?.bodyParameters,
            ...ct_msg,
          });
        }

        apiValidationParameters[
          "event_metadata"
        ] = `{ url: ${apiEndpoint}, status: ${false}, message: ${getHttpErrorForAnalytics(
          error
        )}, response-time: ${
          (Date.now() - startTime) / 1000
        }s, network-type: ${networkType}}`;
        clickStream.addEventList(apiValidationParameters);
      });
  }

  static async fetchAPIOTPForJointAccount(action, body, config, widgetId) {
    const apiEndpoint = useUrlCreator(action.api);
    setCookie("journey-type", "jointAccount");

    if (widgetId) {
      store.dispatch("setLoading", { [widgetId]: loadingEnum.progress });
    }
    const reqbody = {
      request_id:
        store?.state?.apiResponses?.[action.requestIdKey]?.data?.request_id,
      country_code: import.meta.env.VITE_COUNTRY_CODE,
      mob_no: store.state.formData[body.mobile],
      otp: store.state.formData[body.mobileOtp],
      source: import.meta.env.VITE_LOGIN_SOURCE,
      app_id: import.meta.env.VITE_APP_ID,
      require_token_flag: "all",
      user_id: "",
    };
    let headers = {
      "Content-type": "application/json",
      "x-clientlocalip": localStorage.getItem("myip"),
      "x-clientpublicip": localStorage.getItem("myip"),
      "X-DeviceID":
        getCookieValue("platform") || ActionHandler.fnGenerateUniqueID(),
      "x-sourceid": 3,
      "x-usertype": 1,
      "X-macaddress": getCookieValue("macAddress") || "00:00:5e:00:53:af",
      ApplicationName: "Spark-web",
      "X-Appid": "Kyc-web",
      "X-OperatingSystem": ActionHandler.checkOS(),
      "X-Location": "",
      "X-Systeminfo": "",
      "X-Productversion": "",
    };
    const startTime = Date.now();
    const networkType = navigator?.connection?.effectiveType || "";
    const apiValidationParameters = {
      event_name: action?.apiValidationEvent?.event_name,
      event_id: action?.apiValidationEvent?.event_id,
      event_type: "api",
      event_sub_type: "apirequest-response",
    };
    return http
      .post(apiEndpoint, reqbody, { headers })
      .then(async (res) => {
        store.dispatch("setAPIData", {
          key: widgetId,
          value: res?.data,
        });
        // push("register");
        ActionHandler.handleAPIData(res?.data?.data);
        apiValidationParameters[
          "event_metadata"
        ] = `{ url: ${apiEndpoint}, status: ${
          res?.status == 200 ? "true" : "false"
        }, message: ${
          res?.status == 200
            ? `API Success: ${JSON.stringify(res?.data)}`
            : `API Failure: ${JSON.stringify(res?.data)}`
        }, response-time: ${
          (Date.now() - startTime) / 1000
        }s, network-type: ${networkType}`;
        clickStream.addEventList(apiValidationParameters);
        if (action?.ctUser) {
          CleaverTap.userprofile(action?.ctUser);
        }
        if (action?.ct) {
          const ct_msg = {
            url: apiEndpoint,
            status: res?.status == 200 ? "true" : "false",
            // api: res?.data,
            networkType: networkType,
          };
          CleaverTap.addEvents(action?.ct?.event_name, {
            ...action?.ct?.bodyParameters,
            ...ct_msg,
          });
        }
        store.state.formData.firstHolderAppNumber &&
          sessionStorage.setItem(
            "firstHolderAppNumber",
            store.state.formData.firstHolderAppNumber
          );

        if (res?.data?.status && res?.data?.status == "success") {
          showInfoMessage(res?.data?.message, "apiSuccessResp", 2000);
          store.dispatch("setFormData", {
            key: "addjointholderAction",
            value: false,
          });
          if (ActionHandler.isGuest(res?.data)) {
            deleteCookie(import.meta.env.VITE_APP_GUEST_COOKIE);
            setCookie(
              import.meta.env.VITE_APP_GUEST_COOKIE,
              res?.data?.data?.guest_token,
              500
            );

            ActionHandler.redirectionFromLogin(widgetId);
          } else if (
            Object.keys(res?.data?.data?.PartyCodeDetails).length > 0
          ) {
            let clientId = Object.keys(res?.data?.data?.PartyCodeDetails)[0];
            let expire = ActionHandler.parseJwt(
              res?.data?.data?.PartyCodeDetails[clientId]
                ?.non_trading_access_token
            );
            setCookie(
              import.meta.env.VITE_APP_GUEST_COOKIE,
              res?.data?.data?.PartyCodeDetails[clientId]
                ?.non_trading_access_token,
              expire.exp
            );
            if (!action?.addedHolder) {
              const jointAccresp = await http.get("/v1/kyc/jointAccount");

              if (
                jointAccresp.data.type === "second" ||
                jointAccresp.data.type === "third"
              )
                return ActionHandler.redirectionFromLogin(widgetId);
              else {
                sessionStorage.removeItem("firstHolderAppNumber");
              }
            }
            if (action?.addedHolder) {
              let clientId = Object.keys(res?.data?.data?.PartyCodeDetails)[0];
              let expire = ActionHandler.parseJwt(
                res?.data?.data?.PartyCodeDetails[clientId]
                  ?.non_trading_access_token
              );
              // setTokenExp(expire?.exp);
              ActionHandler.redirectionFromLogin(widgetId);
            } else {
              let clientId = Object.keys(res?.data?.data?.PartyCodeDetails)[0];
              let expire = ActionHandler.parseJwt(
                res?.data?.data?.PartyCodeDetails[clientId]
                  ?.non_trading_access_token
              );
              // setTokenExp(expire?.exp);
              setCookie(
                "ABNonTradeToken",
                res?.data?.data?.PartyCodeDetails[clientId]
                  ?.non_trading_access_token,
                expire.exp
              );
              setCookie(
                "ABNonTradeRefreshToken",
                res?.data?.data?.PartyCodeDetails[clientId]
                  ?.non_trading_refresh_token,
                expire.exp
              );
              ActionHandler.checkUserType(
                res?.data?.data?.PartyCodeDetails,
                res?.data
              );
              if (widgetId) {
                store.dispatch("setLoading", {
                  [widgetId]: loadingEnum.success,
                });
              }
            }
          }
        }
      })
      .catch((error) => {
        if (widgetId) {
          store.dispatch("setLoading", { [widgetId]: loadingEnum.error });
        }

        if (action?.ct) {
          const ct_msg = {
            url: apiEndpoint,
            status: res?.status == 200 ? "true" : "false",
            // api: res?.data,
            networkType: networkType,
          };
          CleaverTap.addEvents(action?.ct?.event_name, {
            ...action?.ct?.bodyParameters,
            ...ct_msg,
          });
        }

        apiValidationParameters[
          "event_metadata"
        ] = `{ url: ${apiEndpoint}, status: ${false}, message: ${getHttpErrorForAnalytics(
          error
        )}, response-time: ${
          (Date.now() - startTime) / 1000
        }s, network-type: ${networkType}}`;
        clickStream.addEventList(apiValidationParameters);
      });
  }

  static async journeyDecider(token, widgetId) {
    let headers = ActionHandler.getJourneyDeciderHeader();
    return http
      .get("/kyc/journey-decider", { headers })
      .then(async (resp) => {
        console.log("journey deciderr response ", resp);
        const currentSearch = store.state.formData?.rawUrl?.split("?");
        const urlQuery = currentSearch;

        urlQuery &&
          !urlQuery[1] &&
          sessionStorage.setItem(
            "referralCode",
            store?.state?.formData?.referralCode
          );

        if (resp?.data?.journeyType == "OLD") {
          ActionHandler.redirectionFromLogin();
        } else if (resp?.data?.journeyType == "NEW") {
          setCookie(import.meta.env.VITE_NEW_GUEST_TOKEN_KEY, token, 500);
          store.dispatch("ignoreBrowserPrompt", true);
          if (urlQuery[1]) {
            window.location.replace(
              `${import.meta.env.VITE_KYC_NEW_JOURNEY}?${urlQuery[1]}`,
              "_self"
            );
          } else {
            window.location.replace(
              import.meta.env.VITE_KYC_NEW_JOURNEY,
              "_self"
            );
          }
        }
      })
      .catch((error) => {
        console.log("error in journey decider ", error);
      });
  }

  static getJourneyDeciderHeader() {
    return {
      "Content-type": "application/json",
      "x-clientlocalip": localStorage.getItem("myip"),
      "x-clientpublicip": localStorage.getItem("myip"),
      "X-DeviceID":
        getCookieValue("platform") || ActionHandler.fnGenerateUniqueID(),
      "x-sourceid": 3,
      "x-usertype": 1,
      "X-macaddress": getCookieValue("macAddress") || "00:00:5e:00:53:af",
      ApplicationName: "Spark-web",
      "X-Appid": "Kyc-web",
      "X-OperatingSystem": ActionHandler.checkOS(),
      "X-Location": "",
      "X-Systeminfo": "",
      "X-Productversion": "",
    };
  }

  static parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  static isGuest(data) {
    if (data?.data?.guest_token) {
      return true;
    } else {
      return false;
    }
  }

  static _arrayBufferToBase64(bytes) {
    var binary = "";
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  static fnCompressData(str) {
    if (!str) return;
    const compressed = zlibSync(strToU8(str));
    const cs = ActionHandler._arrayBufferToBase64(compressed);
    return cs;
  }

  static async checkUserType(data, respData) {
    // setCookie("ABUserCookie", JSON.stringify(respData), 500);
    const parsedData = ActionHandler.fnCompressData(JSON.stringify(respData));
    await setCookie(import.meta.env.VITE_APP_USER_COOKIE, parsedData);
    let objUsertypeResp = data;
    let arrAccounts = [];
    let accountList = [];
    for (var account in objUsertypeResp) {
      arrAccounts.push(account);
    }
    if (arrAccounts.length > 0) {
      for (let i = 0; i < arrAccounts.length; i++) {
        let clientCode = arrAccounts[i];
        let account = objUsertypeResp[clientCode];
        let passwordset = account.password;
        let pinset = account.mpin;
        let rekycNeeded = account.rekycNeeded;
        let arrUserName = account.first_name;
        let initials = arrUserName[0].charAt(0);
        if (arrUserName.length > 0) {
          initials = initials + arrUserName[arrUserName.length - 1].charAt(0);
        }
        const obj = {
          profilePic: "",
          initials: initials,
          name: account.first_name,
          clientCode: clientCode,
          passwordset: passwordset,
          pinset: pinset,
          isActive: account.active,
          tokens: account,
          rekycNeeded,
        };
        accountList.push(obj);
      }

      if (accountList.length == 1) {
        const { rekycNeeded, isActive } = accountList[0];
        if (rekycNeeded && !isActive) {
          ActionHandler.fnRedirectToReKycUrl();
        } else if (!rekycNeeded && !isActive) {
          showErrorMessage("In Active Account", "inactiveUser");
        } else {
          if (!accountList[0].passwordset) {
            store.dispatch("ignoreBrowserPrompt", true);
            window.location.replace(
              import.meta.env.VITE_LOGIN_REDIRECT_ACTIVE_USER + "?step=pass",
              ""
            );
          } else if (!accountList[0].pinset) {
            store.dispatch("ignoreBrowserPrompt", true);
            window.location.replace(
              import.meta.env.VITE_LOGIN_REDIRECT_ACTIVE_USER + "?step=pin",
              ""
            );
          } else {
            store.dispatch("ignoreBrowserPrompt", true);
            window.location.replace(
              import.meta.env.VITE_LOGIN_REDIRECT_ACTIVE_USER,
              ""
            );
          }
        }
      } else {
        store.dispatch("ignoreBrowserPrompt", true);
        window.location.replace(
          import.meta.env.VITE_LOGIN_REDIRECT_ACTIVE_USER,
          ""
        );
      }
    } else {
      store.dispatch("ignoreBrowserPrompt", true);
      window.location.replace(
        import.meta.env.VITE_LOGIN_REDIRECT_ACTIVE_USER + "?step=pass",
        ""
      );
    }
  }

  static async proceedForRekyc(clientCode) {
    let rekycParams = {
      clientCode,
      refNo: new Date()
        .toISOString()
        .replace(/[^0-9]/g, "")
        .slice(0, -3),
    };
    const hash = CryptoJS.SHA256(rekycParams).toString(); // creating hash
    rekycParams["hash"] = hash;
    const encryptedHexString = await ActionHandler.fnGetEncryptedHexString(
      rekycParams
    );
    ActionHandler.fnRedirectToReKycUrl(encryptedHexString);
  }

  static fnGetEncryptedHexString(rekycParams) {
    // encrypting obj
    const encryptedString = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(JSON.stringify(rekycParams)),
      CryptoJS.enc.Utf8.parse(import.meta.env.VITE_APP_REKYC_ENCRYPTION_KEY),
      {
        iv: CryptoJS.enc.Utf8.parse(
          import.meta.env.VITE_APP_REKYC_ENCRYPTION_IV
        ), // parse the IV
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      }
    );
    return encryptedString.toString(CryptoJS.format.Hex);
  }

  static fnRedirectToReKycUrl() {
    store.dispatch("ignoreBrowserPrompt", true);
    window.location.replace(
      `${import.meta.env.VITE_APP_REKYC_URL}?data=${
        getCookieValue("ABNonTradeToken") + "&source=spark-web"
      }`,
      ""
    );
  }

  static async handleAPI(
    action,
    body,
    config,
    widgetId,
    screenId,
    resetFormDataOnFailure
  ) {
    const { closePopup } = usePopupManager();
    const apiEndpoint = useUrlCreator(action.api);
    const startTime = Date.now();
    const networkType = navigator?.connection?.effectiveType || "";
    const apiValidationParameters = {
      event_name: action?.apiValidationEvent?.event_name,
      event_id: action?.apiValidationEvent?.event_id,
      event_type: "api",
      event_sub_type: "apirequest-response",
    };

    const forcePush = action?.forcePush || false;
    switch (action.method) {
      case "GET":
        if (widgetId) {
          store.dispatch("setLoading", { [widgetId]: loadingEnum.progress });
        }
        http
          .get(apiEndpoint, { ...config })
          .then((res) => {
            apiValidationParameters[
              "event_metadata"
            ] = `{ url: ${apiEndpoint}, status: ${
              res?.status == 200 ? "true" : "false"
            },  message: ${
              res?.status == 200
                ? `API Success: ${JSON.stringify(res?.data)}`
                : `API Failure: ${JSON.stringify(res?.data)}`
            }, response-time: ${
              (Date.now() - startTime) / 1000
            }s, network-type: ${networkType}}`;
            clickStream.addEventList(apiValidationParameters, forcePush);

            if (action?.ct) {
              const ct_msg = {
                url: apiEndpoint,
                status: res?.status == 200 ? "true" : "false",
                // api: res?.data,
                networkType: networkType,
              };
              CleaverTap.addEvents(action?.ct?.event_name, {
                ...action?.ct?.bodyParameters,
                ...ct_msg,
              });
            }

            if (action?.ctUser) {
              CleaverTap.userprofile(action?.ctUser);
            }
            if (widgetId) {
              store.dispatch("setLoading", { [widgetId]: loadingEnum.success });
            }
            if (screenId && store.state.popups[screenId]) {
              closePopup(screenId);
            }
            ActionHandler.handleAPIData(res?.data?.data);
            ActionHandler.handleAPIAction(res?.data?.action);
          })
          .catch((e) => {
            apiValidationParameters[
              "event_metadata"
            ] = `{ url: ${apiEndpoint}, status: ${false},  message: ${getHttpErrorForAnalytics(
              e
            )}, response-time: ${
              (Date.now() - startTime) / 1000
            }s, network-type: ${networkType}}`;
            clickStream.addEventList(apiValidationParameters, forcePush);
            if (action?.ct) {
              const ct_msg = {
                url: apiEndpoint,
                status: e?.response?.status,
                // api: res?.data,
                networkType: networkType,
              };
              CleaverTap.addEvents(action?.ct?.event_name, {
                ...action?.ct?.bodyParameters,
                ...ct_msg,
              });
            }
            console.error(e);
            if (screenId && store.state.popups[screenId]) {
              closePopup(screenId);
            }
            if (widgetId) {
              store.dispatch("setLoading", { [widgetId]: loadingEnum.error });
            }
          });
        break;
      case "POST":
        if (widgetId) {
          store.dispatch("setLoading", { [widgetId]: loadingEnum.progress });
        }
        return http
          .post(apiEndpoint, { data: body }, { ...config })
          .then(async (res) => {
            if (widgetId) {
              store.dispatch("setLoading", { [widgetId]: loadingEnum.success });
            }
            let storeValue;
            if (action?.storeKey) {
              storeValue = store.state.formData[action.storeKey];
            }
            apiValidationParameters["event_metadata"] =
              await `{ url: ${apiEndpoint}, status: ${
                res?.status == 200 ? "true" : "false"
              },  message: ${
                res?.status == 200
                  ? `API sucess: ${JSON.stringify(res?.data)}`
                  : `API failure: ${JSON.stringify(res?.data)}`
              }, response-time: ${
                (Date.now() - startTime) / 1000
              }s, network-type: ${networkType}, ${
                action?.metaDataKeyName && storeValue
                  ? action.metaDataKeyName + " : " + storeValue
                  : ""
              }}`;
            await clickStream.addEventList(apiValidationParameters, forcePush);
            if (action?.ct) {
              const ct_msg = {
                url: apiEndpoint,
                status: res?.status,
                // api: res?.data,
                networkType: networkType,
              };
              CleaverTap.addEvents(action?.ct?.event_name, {
                ...action?.ct?.bodyParameters,
                ...ct_msg,
              });
            }
            if (action?.ctUser) {
              CleaverTap.userprofile(action?.ctUser);
            }
            store.dispatch("setAPIData", {
              key: widgetId,
              value: res?.data,
            });
            ActionHandler.handleAPIData(res?.data?.data);
            if (res?.data?.action) {
              store.dispatch("setAction", res?.data?.action);
            }
            if (action.showMessageOnApiResp) {
              // Added timeout to avoid conlicts in navigation animation and snackBar animation
              setTimeout(() => {
                useMessageonSnackBar(action.value, action.messageData);
              }, 10);
            }
            ActionHandler.handleAPIAction(res?.data?.action);
            let apiSuccessEv = {
              event_name: action?.apiSuccessEvent?.event_name,
              event_category: action?.apiSuccessEvent?.event_category,
              event_action: action?.apiSuccessEvent?.event_action,
              event_metadata: `{ url: ${apiEndpoint}, status: ${
                res?.status == 200 ? "true" : "false"
              },  message: ${
                res?.status == 200 ? "API Success" : res?.data?.message
              }, response-time: ${
                (Date.now() - startTime) / 1000
              }s, network-type: ${networkType}}`,
            };
            if (
              (res?.status == 200 || res?.status == "success") &&
              action.apiSuccess
            ) {
              Analytics.analyticsEventLog(apiSuccessEv);
            }

            if (action.isPopup && action.popupId) {
              if (typeof action.isPopup == "boolean") {
                ActionHandler.closePopupFn(action.isPopup, action.popupId);
              } else {
                let rule = useRuleParser(action.isPopup) || false;
                ActionHandler.closePopupFn(rule, action.popupId);
              }
            } else {
              if (screenId && store.state.popups[screenId]) {
                closePopup(screenId);
              }
            }
            if (action?.resetFields && !isObjectEmpty(action?.resetFields)) {
              ActionHandler.clearFormData(action.resetFields);
            }
          })
          .catch(async (error) => {
            apiValidationParameters["event_metadata"] =
              await `{ url: ${apiEndpoint}, status: ${false},  message: ${getHttpErrorForAnalytics(
                error
              )}, response-time: ${
                (Date.now() - startTime) / 1000
              }s, network-type: ${networkType}}`;
            await clickStream.addEventList(apiValidationParameters, forcePush);
            if (
              (!action.isPopup || !action.popupId) &&
              screenId &&
              store.state.popups[screenId]
            ) {
              closePopup(screenId);
            }
            if (resetFormDataOnFailure) {
              ActionHandler.clearFormData(action.bodyParameters);
            }
            if (widgetId) {
              store.dispatch("setLoading", { [widgetId]: loadingEnum.error });
            }
            if (action?.ct) {
              const ct_msg = {
                url: apiEndpoint,
                status: error?.response?.status,
                // api: res?.data,
                networkType: networkType,
              };
              CleaverTap.addEvents(action?.ct?.event_name, {
                ...action?.ct?.bodyParameters,
                ...ct_msg,
              });
            }
          });
      case "PUT":
        if (widgetId) {
          store.dispatch("setLoading", { [widgetId]: loadingEnum.progress });
        }
        http
          .put(apiEndpoint, { ...body }, { ...config })
          .then((res) => {
            if (screenId && store.state.popups[screenId]) {
              closePopup(screenId);
            }
            if (widgetId) {
              store.dispatch("setLoading", { [widgetId]: loadingEnum.success });
            }
            ActionHandler.handleAPIData(res?.data?.data);
            ActionHandler.handleAPIAction(res?.data?.action);
            if (action?.ctUser) {
              CleaverTap.userprofile(action?.ctUser);
            }
          })
          .catch(() => {
            if (screenId && store.state.popups[screenId]) {
              closePopup(screenId);
            }
            if (widgetId) {
              store.dispatch("setLoading", { [widgetId]: loadingEnum.error });
            }
          });
        break;
      case "DELETE":
        if (widgetId) {
          store.dispatch("setLoading", { [widgetId]: loadingEnum.progress });
        }
        http
          .delete(apiEndpoint, { ...config })
          .then((res) => {
            ActionHandler.handleAPIData(res?.data?.data);
            ActionHandler.handleAPIAction(res?.data?.action);
            if (widgetId) {
              store.dispatch("setLoading", { [widgetId]: loadingEnum.success });
            }
            if (screenId && store.state.popups[screenId]) {
              closePopup(screenId);
            }
          })
          .catch(() => {
            if (widgetId) {
              store.dispatch("setLoading", { [widgetId]: loadingEnum.error });
            }
            if (screenId && store.state.popups[screenId]) {
              closePopup(screenId);
            }
          });
        break;
      case "UPLOAD":
        if (widgetId) {
          store.dispatch("setLoading", { [widgetId]: loadingEnum.progress });
        }
        http
          .upload(
            apiEndpoint,
            { ...body },
            {
              onUploadProgress(progressEvent) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                if (widgetId) {
                  store.dispatch("setProgress", {
                    [widgetId]: percentCompleted,
                  });
                }
              },
              ...config,
            }
          )
          .then(async (res) => {
            apiValidationParameters[
              "event_metadata"
            ] = `{ url: ${apiEndpoint}, status: ${
              res?.status == 200 ? "true" : "false"
            }, message: ${
              res?.status == 200
                ? `API Success: ${JSON.stringify(res?.data)}`
                : `API failure: ${JSON.stringify(res?.data)}`
            }, response-time: ${
              (Date.now() - startTime) / 1000
            }s, network-type: ${networkType}}`;
            clickStream.addEventList(apiValidationParameters, forcePush);

            if (action?.ct) {
              const ct_msg = {
                url: apiEndpoint,
                status: res?.status,
                // api: JSON.stringify(res?.data),
                networkType: networkType,
              };
              CleaverTap.addEvents(action?.ct?.event_name, {
                ...action?.ct?.bodyParameters,
                ...ct_msg,
              });
            }
            if (action?.ctUser) {
              CleaverTap.userprofile(action?.ctUser);
            }
            if (screenId && store.state.popups[screenId]) {
              await closePopup(screenId);
            }
            if (widgetId) {
              store.dispatch("setLoading", { [widgetId]: loadingEnum.success });
            }
            ActionHandler.handleAPIData(res?.data?.data);
            ActionHandler.handleAPIAction(res?.data?.action);
            if (res?.status == 200 || res?.status == "success") {
              let apiSuccessEv;
              apiSuccessEv = {
                event_name: action.apiSuccessEvent?.event_name,
                event_category: action.apiSuccessEvent?.event_category,
                event_action: action.apiSuccessEvent?.event_action,
                event_metadata: `{ url: ${apiEndpoint}, status: ${
                  res?.status == 200 ? "true" : "false"
                },  message: ${
                  res?.status == 200 ? "API Success" : res?.data?.message
                }, response-time: ${
                  (Date.now() - startTime) / 1000
                }s, network-type: ${networkType}}`,
              };
              Analytics.analyticsEventLog(apiSuccessEv);
            }
          })
          .catch((error) => {
            apiValidationParameters[
              "event_metadata"
            ] = `{ url: ${apiEndpoint}, status: ${false}, message: ${getHttpErrorForAnalytics(
              error
            )}, response-time: ${
              (Date.now() - startTime) / 1000
            }s, network-type: ${networkType}}`;
            clickStream.addEventList(apiValidationParameters, forcePush);
            if (action?.ct) {
              const ct_msg = {
                url: apiEndpoint,
                status: error?.response?.status,
                // api: JSON.stringify(res?.data),
                networkType: networkType,
              };
              CleaverTap.addEvents(action?.ct?.event_name, {
                ...action?.ct?.bodyParameters,
                ...ct_msg,
              });
            }
            if (screenId && store.state.popups[screenId]) {
              closePopup(screenId);
            }
            if (widgetId) {
              store.dispatch("setLoading", { [widgetId]: loadingEnum.error });
            }
            if (resetFormDataOnFailure) {
              ActionHandler.clearFormData(action.bodyParameters);
            }
          });

        break;
      default:
        break;
    }
  }

  /* handling api data*/
  static async handleAPIData(data) {
    if (!data) return;
    for (const key in data) {
      if (!key) return;
      store.dispatch("setFormData", {
        key: key,
        value: data[key],
      });
    }
  }

  static async redirectToExternaPageNwTab(bodyParam) {
    window.open(bodyParam.url, "_blank");
  }

  static redirectToUrl(url) {
    store.dispatch("ignoreBrowserPrompt", true);
    window.location.replace(url, "");
    // window.location.href = url;
  }

  static setStateFromCookieValue(action) {
    const c = getCookieValue(action?.params?.cookieeId);
    if (c) {
      store.dispatch("setFormData", {
        key: action?.params?.stateId,
        value: c,
      });
    }
  }
  static setBankCode(action) {
    if (
      !store.state.formData[action?.params?.code] &&
      store.state.formData["ifsc"]
    ) {
      const ifsc = store.state.formData["ifsc"];
      const code = ifsc.substring(0, 4);
      store.dispatch("setFormData", {
        key: action?.params?.code,
        value: code.toLowerCase(),
      });
    }
  }

  static setCookieValue(action) {
    let s = store.state.formData[action?.params?.stateId];
    if (s) {
      setCookie(action?.params?.cookieeId, s, 30, true);
    }
  }
  static copyStateValue(action) {
    let s = store.state.formData[action?.params?.stateId];
    if (s) {
      store.dispatch("setFormData", {
        key: action?.params?.newId,
        value: s,
      });
    }
  }

  /* handling api actions*/
  static async handleAPIAction(action) {
    const { openPopup } = usePopupManager();
    const { push } = LocalNavigator;
    if (!action) return;
    switch (action?.type) {
      case API_ACTION_TYPES.openPopup:
      case API_ACTION_TYPES.openBottomSheet:
        if (action?.value?.name == "initiateDigio") {
          ActionHandler.initiateDigio();
        } else {
          openPopup(action?.value?.name);
        }
        break;
      case API_ACTION_TYPES.navigate:
        push(action?.value?.name);
        break;
      case API_ACTION_TYPES.openWebView:
        break;
      case ACTION_TYPES.redirect:
        ActionHandler.redirectToUrl(action?.value?.name);
        break;
      case ACTION_TYPES.showToast:
        if (
          !store.state.formData.isDoBMatch ||
          !store.state.formData.isNameMatch
        ) {
          ActionHandler.showToast(action.value.name);
        }

        break;
      default:
      // console.warn("THIS ROUTE IS NOT HANDLED");
    }
  }
  static logApiEvent(csEvent) {
    this.apiValidationAnalytics = {
      event_name: csEvent?.event_name,
      event_id: csEvent?.event_id,
    };
    let result = this.apiValidationAnalytics;
    return result;
  }

  static addArrayElement(action) {
    let arrayData = store?.state?.formData[action.arrayKey]
      ? store?.state?.formData[action.arrayKey]
      : [];
    const total = arrayData.reduce(function (sum, data) {
      return sum + Number(data[action.sharePercentKey]);
    }, 0);
    store.dispatch("setFormData", {
      key: action.storeKey,
      value: total,
    });
    sessionStorage.setItem(action.storeKey, total);
  }

  static addDataToForm(parsedData, key, uniqueKey, action) {
    const { closePopup } = usePopupManager();
    const { push } = LocalNavigator;
    let arrayData;
    switch (action?.dataType) {
      case "array":
        arrayData = store?.state?.formData[key]
          ? store?.state?.formData[key]
          : [];
        if (!store?.state[action?.editIndexKey].toString()) {
          if (arrayData.length < action.maxArrayLength) {
            arrayData.push(parsedData);
          } else {
            showErrorMessage("Maximum limit reached", "maxArrayLimit");
          }
        } else {
          arrayData[store?.state[action.editIndexKey]] = parsedData;
        }
        store.dispatch("setFormData", {
          key: key,
          value: arrayData,
        });
        sessionStorage.setItem(key, JSON.stringify(arrayData));
        ActionHandler.clearFormData(action.bodyParameters);
        ActionHandler.addArrayElement(action);
        push(action.routeTo);
        closePopup(action.screenId);
        break;
      default:
        store.dispatch("setFormData", {
          key: key,
          value: parsedData,
        });
    }
  }

  static clearFormData(data) {
    for (const key in data) {
      const value = data[key];
      store.dispatch("clearFormData", value);
    }
  }
  static setFormData(data) {
    for (const key in data) {
      const value = data[key];
      store.dispatch("setFormData", { key, value });
    }
  }
  static clearAPIResponses(data) {
    for (const key in data) {
      const value = data[key];
      store.dispatch("clearAPIResponses", value);
    }
  }

  static closePopupFn(isPopup, popupId) {
    const { closePopup } = usePopupManager();
    if (isPopup && popupId) {
      closePopup(popupId);
    }
  }

  static getCapchaCode() {
    return new Promise((resolve) => {
      window.grecaptcha.enterprise.ready(function () {
        window.grecaptcha.enterprise
          .execute(import.meta.env.VITE_APP_CAPTCHA_SITE_KEY, {
            action: "submit",
          })
          .then(function (token) {
            resolve(token);
          })
          .catch(function (err) {
            console.error("Error getting captcha", err);
            resolve("");
          });
      });
    });
  }

  static clearEditIndex(data) {
    store.dispatch("setClearEditIndex", data.value);
  }

  static logApiSuccessEvent(dlEvent) {
    this.apiSuccessParams = {
      event_name: dlEvent?.event_name,
      event_category: dlEvent?.event_category,
      event_action: dlEvent?.event_action,
    };
    let result = this.apiSuccessParams;
    return result;
  }

  static setSessionData(value) {
    for (const key in value) {
      const v = value[key];
      const k = store.state.formData[v] || "";
      sessionStorage.setItem(key, JSON.stringify(k));
    }
  }

  static sendDataInWhatsApp(action) {
    // let text = "Check out this link: [insert your link here]";
    let encodedText = encodeURIComponent(action.value);
    let whatsappUrl = "https://api.whatsapp.com/send?text=" + encodedText;
    window.open(whatsappUrl);
  }

  static setSessionDataFromOBJ(value) {
    for (const key in value) {
      const v = value[key];
      sessionStorage.setItem(key, JSON.stringify(v));
    }
  }

  static clearSessionData(value) {
    for (const key in value) {
      const k = value[key];
      sessionStorage.removeItem(k);
    }
  }

  static fnGenerateUniqueID() {
    var uuidValue = "",
      k,
      randomValue;
    for (k = 0; k < 32; k++) {
      randomValue = (Math.random() * 16) | 0;

      if (k == 8 || k == 12 || k == 16 || k == 20) {
        uuidValue += "-";
      }
      uuidValue += (
        k == 12 ? 4 : k == 16 ? (randomValue & 3) | 8 : randomValue
      ).toString(16);
    }

    return uuidValue;
  }

  static async initiateDigio() {
    try {
      const initiateDigioDetails = [
        {
          type: "navigateWithAPI",
          method: "POST",
          api: "/v1/kyc/pan/digio/initiate",
          requestOn: "onChangeVal",
          bodyParameters: {
            pan: "pan",
          },
        },
        {
          type: "ignorePrompt",
          value: true,
        },
      ];

      await ActionHandler.handleAction(initiateDigioDetails, "", {});
    } catch (e) {
      console.log(e);
    }
  }
}

export default ActionHandler;
