<template>
  <div class="d-flex flex-column w-100">
    <v-row v-if="!imageUrl">
      <v-col class="d-flex flex-column" cols="12">
        <SignaturePadWidget
          ref="signatureWidget"
          @onEnd="submit($event)"
          @onBegin="onBegin($event)"
          @clearSignature="clearSignature($event)"
        ></SignaturePadWidget>
      </v-col>
    </v-row>
    <v-row v-if="imageUrl">
      <v-col class="d-flex flex-column" cols="12">
        <v-img
          :src="imageUrl"
          width="100%"
          height="180"
          class="border-dashed signatureImage"
        ></v-img>
      </v-col>
    </v-row>

    <div>
      <!-- !signtype && imageUrl -->
      <div class="sign-again" v-if="signaturesource == 'ON_SCREEN' && imageUrl">
        <v-img
          :src="getImage('reupload.svg')"
          width="20"
          height="20"
          class="flex-grow-0 mr-2"
        ></v-img>
        <p @click="reSignApplication">SIGN AGAIN</p>
      </div>
      <div class="sign-again" v-if="sinatureAutoFeature">
        <p @click="toggleSignType">
          {{ signatureText }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { clickStream } from "@/composables/clickStreamAnalytics";
import useFormValue from "@/composables/useFormValue";
import { isValidURL } from "@/utils/url";
import { getImage } from "@/utils/utils";
import { reactive, ref } from "@vue/reactivity";
import { onMounted, watch } from "vue";
import { useStore } from "vuex";
import {
  fileToBase64,
  formatName,
  getBase64URL,
  urltoFile,
} from "../utils/utils";
import { computed } from "vue";
import SignaturePadWidget from "./SignaturePadWidget.vue";
let ctx;
var timeoutHandle;
export default {
  name: "SignaturePadWidgetParent",
  components: { SignaturePadWidget },
  props: {
    params: Object,
    id: String,
  },
  setup(props) {
    const { screenPopups } = reactive(props.params);
    const store = useStore();
    const widgetId = reactive(props.id);
    const { value } = useFormValue(widgetId);
    const isEnabled = ref(false);
    let imageUrl = ref(null);
    let signtype = ref(true);
    const {
      signatureWidth,
      signatureHeight,
      screenId,
      sinatureAutoFeatureKey,
    } = reactive(props.params);
    const inScreenPopups = store.state.popups;
    const source = reactive(props?.params?.source || "");
    const dispatchSubmitState = (imageURL) => {
      const disable_btn = !!imageURL;
      setTimeout(() => {
        store.dispatch("setButtonToDisable", disable_btn ? false : true);
      }, 0);
    };

    const signaturesource = computed({
      get() {
        return store.state.formData.signaturesource || "ON_SCREEN";
      },
    });
    const sinatureAutoFeature = computed({
      get() {
        return store.state.staticConfigs[sinatureAutoFeatureKey] || false;
      },
    });
    const signatureText = computed({
      get() {
        return store.state.formData.signaturesource === "DEFAULT"
          ? "SIGN MANUALLY"
          : "SHOW SUGGESTED SIGNATURE";
      },
    });

    const setFormData = (key, value) => {
      store.dispatch("setFormData", {
        key,
        value,
      });
    };

    watch(
      () => store.state.formData[screenId],
      (currentValue) => {
        if (isValidURL(currentValue)) {
          imageUrl.value = currentValue;
          getBase64URL(screenId, (imageData) => {
            urltoFile(imageData, "signature.png").then((file) => {
              setFormData(widgetId, file);
              return file;
            });
          });
        } else if (currentValue instanceof File) {
          setFormData(widgetId, currentValue);
          fileToBase64(currentValue, (imageData) => {
            new Promise((resolve, reject) => {
              try {
                imageUrl.value = imageData;
                resolve(imageData);
              } catch (error) {
                reject(error);
              }
            }).then((image) => {
              dispatchSubmitState(image);
            });
          });
        } else {
          imageUrl.value = currentValue;
        }
      }
    );

    watch(
      () => store.state.formData[screenId],
      (currentValue) => {
        if (currentValue === null) {
          setFormData(widgetId, "");
        }
        dispatchSubmitState(currentValue);
      }
    );

    watch(
      () => [inScreenPopups[screenPopups[0]], inScreenPopups[screenPopups[1]]],
      (currentValue) => {
        let imageURL =
          store.state.formData[screenId] || store.state.formData[widgetId];
        if (currentValue.includes(true)) {
          imageURL = store.state.formData[screenId];
        }
        dispatchSubmitState(imageURL);
      }
    );

    onMounted(() => {
      if (sinatureAutoFeature.value) {
        store.dispatch("setFormData", {
          key: "signaturesource",
          value: "DEFAULT",
        });
      } else {
        store.dispatch("setFormData", {
          key: "signaturesource",
          value: "ON_SCREEN",
        });
      }
      if (store.state.formData[screenId]) {
        if (isValidURL(store.state.formData[screenId])) {
          imageUrl.value = store.state.formData[screenId];
          getBase64URL(screenId, (imageData) => {
            imageUrl.value = imageData;
            urltoFile(imageData, "signature.png").then((file) => {
              setFormData(widgetId, file);
            });
          });
        } else if (store.state.formData[screenId] instanceof File) {
          setFormData(widgetId, store.state.formData[screenId]);
          fileToBase64(store.state.formData[screenId], (imageData) => {
            imageUrl.value = imageData;
          });
        } else {
          imageUrl.value = store.state.formData[screenId];
        }
      } else {
        if (sinatureAutoFeature.value == true) {
          createDigitalSign();
        }
      }
    });

    function toggleSignType() {
      // let clickStream = new ClickStreamAnalytics();

      signtype.value = signtype.value ? false : true;
      if (signaturesource.value == "ON_SCREEN") {
        store.dispatch("setFormData", {
          key: "signaturesource",
          value: "DEFAULT",
        });
      } else {
        store.dispatch("setFormData", {
          key: "signaturesource",
          value: "ON_SCREEN",
        });
      }
      if (signaturesource.value == "DEFAULT") {
        createDigitalSign();
        let csEvents = {
          event_screen: "s-signyourapplication",
          event_type: "click",
          event_sub_type: "button",
          event_name: "ShowsuggestedSignature",
          event_id: "73.0.0.28.10",
        };
        clickStream.addEventList(csEvents);
      } else {
        let csEvents = {
          event_screen: "s-signyourapplication",
          event_type: "click",
          event_sub_type: "button",
          event_name: "SignManually",
          event_id: "73.0.0.28.9",
        };
        clickStream.addEventList(csEvents);
        reSignApplication();
      }
    }

    function reSignApplication() {
      if (imageUrl.value) {
        imageUrl.value = null;
        store.dispatch("setFormData", {
          key: screenId,
          value: "",
        });
        setFormData(screenId, "");
        setFormData(widgetId, "");
      }
      store.dispatch("setButtonToDisable", true);
      // if (source) {
      //   store.dispatch("setFormData", {
      //     key: source.key,
      //     value: source.value,
      //   });
      // }
    }

    function clearSignature(signaturePad) {
      signaturePad.clearSignature();
      store.dispatch("setButtonToDisable", true);
      setFormData(widgetId, "");
    }
    function onBegin() {
      isEnabled.value = true;
      window.clearTimeout(timeoutHandle);
    }
    function submit(signature) {
      const { data } = signature.saveSignature("image/png");
      timeoutHandle = window.setTimeout(function () {
        urltoFile(data, "signature.png")
          .then(function (file) {
            setFormData(widgetId, file);
            return file;
          })
          .then((file) => {
            dispatchSubmitState(file);
          });
      }, 500);
    }

    const loadFont = async () => {
      const font = new FontFace(
        "Thesignature",
        "url(https://cdn.angelone.in/kyc/fonts/Thesignature.ttf)"
      );
      await font.load();
      document.fonts.add(font);
    };

    async function writeText(info = {}, style = {}, canvasEle) {
      await loadFont();
      const { text, x, y } = info;
      const {
        fontFamily,
        color = "rgb(61, 79, 183)",
        textAlign = "left",
        textBaseline = "top",
        fontSize,
      } = style;
      ctx.beginPath();
      ctx.font = fontSize + "px " + fontFamily;
      ctx.textAlign = textAlign;
      ctx.textBaseline = textBaseline;
      ctx.fillStyle = color;
      ctx.fillText(text, x, y);
      ctx.stroke();
      return canvasEle.toDataURL("image/png", 1.0);
    }
    function textSize(value) {
      let v = value.length * 0.6;
      if (v >= 60) return v;
      return 72;
    }
    function capitalizeWords(str = "") {
      let v = str.toLowerCase();
      return v.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      });
    }

    async function createDigitalSign() {
      const value =
        formatName(store.state.formData["panFullName"]) ||
        formatName(store.state.formData["fullName"]);
      if (value) {
        const canvasEle = document.createElement("CANVAS");
        canvasEle.width = "600";
        ctx = canvasEle.getContext("2d");
        let v = await writeText(
          {
            text: capitalizeWords(value),
            x: 280,
            y: 50,
          },
          {
            fontSize: textSize(value),
            fontFamily: "Thesignature",
            textAlign: "center",
          },
          canvasEle
        );
        store.dispatch("setSignature", v);
        getBase64URL(screenId, (imageData) => {
          urltoFile(imageData, "signature.png").then((file) => {
            setFormData(widgetId, file);
            return file;
          });
        });
        canvasEle.width = 0;
        canvasEle.height = 0;
      }
    }

    return {
      clearSignature,
      onBegin,
      submit,
      signatureWidth,
      signatureHeight,
      screenId,
      value,
      imageUrl,
      reSignApplication,
      isEnabled,
      getImage,
      signtype,
      toggleSignType,
      signaturesource,
      sinatureAutoFeature,
      signatureText,
    };
  },
};
</script>
<style lang="scss" scoped>
.signatureImage {
  max-width: 550px;
  height: 230px;
  margin: 0px auto;
}

.sign-again {
  color: $primary;
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
  justify-content: center;

  p {
    cursor: pointer;
  }
}

.border-dashed {
  border-radius: $border-radius;
  border: 2px solid #c2c6cc;
}
</style>
