<template>
  <transition name="fab-transition">
    <DynamicLayout
      class="stepone"
      :layout="screenData?.layout"
      :id="screenId"
      :isTextFiledDisabled="screenData?.isTextFiledDisabled || null"
    />
  </transition>
  <SnackBarWidget />
</template>

<script>
// isTextFiledDisabled is important for disabling or enabling text filed
import useLifecycleHooks from "@/composables/useLifecycleHooks";
import SnackBarWidget from "@/widgets/SnackBarWidget.vue";
import { reactive } from "@vue/reactivity";
import { onErrorCaptured, onMounted, onUnmounted } from "vue";
import DynamicLayout from "./DynamicLayout.vue";

export default {
  name: "ScreenView",
  components: {
    DynamicLayout,
    SnackBarWidget,
  },
  props: {
    screen: Object,
    id: String,
  },
  setup(props) {
    const screenData = reactive(props.screen);
    const screenId = reactive(props.id);
    const { _onMounted, _onUnmounted, _onErrorCaptured } = useLifecycleHooks();
    const action = {
      ...screenData,
      screenId,
    };
    onMounted(_onMounted.bind(action));
    onUnmounted(_onUnmounted.bind(action));
    onErrorCaptured(_onErrorCaptured.bind(action));

    return { screenData, screenId };
  },
};
</script>

<style></style>
