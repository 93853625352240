<template>
  <div class="d-flex flex-column w-100">
    <v-row>
      <v-col class="d-flex flex-column" cols="12" :md="webColumns">
        <v-text-field
          :id="widgetId"
          v-if="textInput !== 'number'"
          :type="textInput"
          :counter="textCounter"
          :hint="textHint"
          :label="textLabel"
          :variant="textVariant"
          :density="textDensity"
          :disabled="disableKey"
          :readonly="readonlyText"
          :prepend-icon="prependIcon"
          :prepend-inner-icon="prependInnerIcon"
          :append-inner-icon="appendInnerIcon"
          :append-icon="appendIcon"
          :append-outer-icon="appendOuterIcon"
          :prefix="textPrefix"
          :suffix="textSuffix"
          :filled="isFilled"
          :rules="rules"
          :placeholder="textPlaceholder"
          v-model="value"
          @input="updateValue"
          :minlength="minLength"
          :maxlength="maxLength"
          :class="[
            { 'text-input-uppercase': isUppercase },
            { 'text-input-capitalize': isCapitalize },
            { 'padding-right-2rem': appendIconClass },
          ]"
          @paste="pastePrevent"
          @copy="copyPrevent"
          @change="onChangeAction"
          @click:append-inner="toggleMarker"
          :error-messages="errorMessage"
          autocomplete="off"
          @keydown="typeAllowedOnly"
          ref="textFieldRef"
          validate-on="input"
        ></v-text-field>
        <v-text-field
          v-else
          :type="textInput"
          :counter="textCounter"
          :hint="textHint"
          :label="textLabel"
          :variant="textVariant"
          :density="textDensity"
          :disabled="disableKey"
          :readonly="readonlyText"
          :prepend-icon="prependIcon"
          :prepend-inner-icon="prependInnerIcon"
          :append-inner-icon="appendInnerIcon"
          :append-icon="appendIcon"
          :append-outer-icon="appendOuterIcon"
          :prefix="textPrefix"
          :suffix="textSuffix"
          :filled="isFilled"
          :rules="rules"
          :error-messages="errorMessage"
          :placeholder="textPlaceholder"
          v-model.number="value"
          @input="updateValue"
          :minlength="minLength"
          :maxlength="maxLength"
          :class="[
            { 'text-input-uppercase': isUppercase },
            { 'text-input-capitalize': isCapitalize },
          ]"
          @paste="pastePrevent"
          @copy="copyPrevent"
          @change="onChangeAction"
          @keydown="typeAllowedOnly"
          autocomplete="off"
          :id="widgetId"
          ref="textFieldRef"
        ></v-text-field>
        <div class="info-container" v-if="appendResend">
          <ResendWidget
            :params="{
              count,
              showResend,
              resendRIghtAlign,
              ResendLabel,
              requestAction,
            }"
            :id="widgetId"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { reactive, computed, ref, onMounted, watch, watchEffect } from "vue";
import useFormValue from "@/composables/useFormValue";
import useCreateValidationRule from "@/composables/useCreateValidationRule";
import { getCookieValue, showErrorMessage } from "@/services/HttpClient";
import { useStore } from "vuex";
import ResendWidget from "./ResendWidget.vue";
import ActionHandler from "@/handlers/action";
import useRuleParser from "@/composables/useRuleParser";

export default {
  // || shouldDisable
  name: "TextFieldWidget",
  props: {
    params: Object,
    id: String,
    validations: Array,
    shouldDisableKey: String,
  },
  setup(props) {
    const {
      textTitle,
      textCounter,
      textHint,
      textLabel,
      textVariant,
      textDensity,
      readonlyText,
      prependIcon,
      prependInnerIcon,
      appendIcon,
      appendOuterIcon,
      textPrefix,
      textSuffix,
      isUppercase,
      webColumns = 6,
      textPlaceholder,
      isFilled,
      preventPaste,
      preventCopy,
      isCapitalize,
      maxLength,
      minLength,
      appendResend,
      ResendLabel,
      requestAction,
      requestActionOnChange,
      isOnChangeAction = false,
      clearFiledBeforeCreateEnable,
      isUpperCaseValue,
      toggledAppendIcon,
      appendIconClass,
      defaultValue,
      fieldType,
      shouldDisableKeyParam,
      checkForLength = false,
      jointAccountDefaultValue,
      visibleAppendIconWhen,
    } = reactive(props.params);
    const store = useStore();
    const widgetId = reactive(props.id);
    const validations = reactive(props.validations);

    let visibleAppendIcon = ref();

    watch(
      () => store.state.formData[widgetId],
      (currentValue) => {
        if (currentValue) {
          store.dispatch("setAuthenticationMessage", {
            message: "",
            error_code: "",
          });
        }
      }
    );

    const typeAllowedOnly = (evt) => {
      if (fieldType == "number") {
        evt = evt ? evt : window.event;
        let charCode = evt.which ? evt.which : evt.keyCode;
        let ctrl =
          evt.ctrlKey || evt.metaKey || charCode == 17 || charCode == 91;
        if (
          charCode == 8 ||
          charCode == 9 ||
          (charCode > 36 && charCode < 45) ||
          (charCode > 45 && charCode < 65) ||
          (charCode > 95 && charCode < 106) ||
          (charCode == 86 && ctrl) ||
          (charCode == 67 && ctrl)
        ) {
          return true;
        } else {
          evt.preventDefault();
        }
      } else {
        return true;
      }
    };

    const shouldDisable = computed({
      get() {
        if (!props?.shouldDisableKey) return false;
        return store.state.formData[props?.shouldDisableKey] || false;
      },
    });

    const isErrorCodeIncluded = computed({
      get() {
        if (!props?.error_codes) return false;
        let e_code =
          store.state.loginAuthenticationAttemptMessage[
            props?.params.error_code
          ];
        let error_codes = new Array(props?.params.error_codes) || [];
        return error_codes.includes(e_code) || false;
      },
    });
    const errorMessage = computed({
      get() {
        if (!props?.params?.error_codes) return "";
        return (
          store.state.loginAuthenticationAttemptMessage[
            props?.params?.message
          ] || ""
        );
      },
    });
    const updateValue = (event) => {
      if (event.target.type == "number") {
        value = parseInt(event.target.value);
      } else {
        if (isUpperCaseValue) {
          value = event.target.value.toUpperCase();
          store.dispatch("setFormData", {
            key: widgetId,
            value: value,
          });
        } else {
          value = event.target.value;
        }
      }
    };

    function onChangeAction() {
      if (isOnChangeAction) {
        if (checkForLength && value?.length !== maxLength) {
          return;
        }
        ActionHandler.handleAction(requestActionOnChange, widgetId, {});
      }
    }

    function toggleMarker() {
      appendInnerIcon.value =
        appendInnerIcon.value === toggledAppendIcon.toggledIcon1
          ? toggledAppendIcon.toggledIcon2
          : toggledAppendIcon.toggledIcon1;

      // if (toggledAppendIcon.action == "password") {
      //   textInput.value = textInput.value === "password" ? "text" : "password";
      //   return
      // }

      switch (toggledAppendIcon.action) {
        case "password":
          textInput.value =
            textInput.value === "password" ? "text" : "password";
          break;
        case "handleAction":
          ActionHandler.handleAction(
            toggledAppendIcon.actionRequest,
            widgetId,
            {}
          );
          break;

        default:
          break;
      }
    }

    const disableKey = computed({
      get() {
        if (store.state.textFieldDisables[widgetId]) {
          return store.state.textFieldDisables[widgetId];
        } else if (shouldDisableKeyParam) {
          return true;
        }
        return false;
      },
    });

    let appendInnerIcon = ref();
    watchEffect(() => {
      visibleAppendIcon.value = useRuleParser(visibleAppendIconWhen);
      if (visibleAppendIconWhen && visibleAppendIcon.value) {
        appendInnerIcon.value = props.params.appendInnerIcon;
      } else if (!visibleAppendIconWhen) {
        appendInnerIcon.value = props.params.appendInnerIcon;
      }
    });

    let textInput = ref(props.params.textInput);

    let { value } = useFormValue(widgetId);
    const { rules } = useCreateValidationRule(validations);
    onMounted(() => {
      if (clearFiledBeforeCreateEnable) {
        // value.value = "";
        ActionHandler.clearFormData({ k: widgetId });
      }
      if (
        jointAccountDefaultValue &&
        getCookieValue("journey-type") === "jointAccount"
      ) {
        value.value = store?.state?.formData[jointAccountDefaultValue];
      } else if (defaultValue) {
        value.value = store?.state?.formData[defaultValue];
      }
    });
    const count = ref(props.params.count);
    const showResend = ref(props.params.showResend);
    const resendRIghtAlign = ref(props.params.resendRIghtAlign);
    function pastePrevent(e) {
      if (preventPaste) {
        showErrorMessage("Paste functionality is disabled", "paste");
        e.preventDefault();
        return false;
      }
    }
    function copyPrevent(e) {
      if (preventCopy) {
        showErrorMessage("Copy functionality is disabled", "copy");
        e.preventDefault();
        return false;
      }
    }

    const textFieldRef = ref(null);

    watch(
      () => store.state.formData[props.params.reactiveOn],
      (currentValue) => {
        if (currentValue) {
          const validateSilently = !textFieldRef?.value?.value;
          textFieldRef?.value?.validate(validateSilently);
        }
      }
    );

    return {
      textTitle,
      textCounter,
      textHint,
      textLabel,
      textVariant,
      textDensity,
      // disabledText,
      readonlyText,
      prependIcon,
      prependInnerIcon,
      appendIcon,
      appendOuterIcon,
      textPrefix,
      textSuffix,
      textPlaceholder,
      textInput,
      isUppercase,
      webColumns,
      isFilled,
      rules,
      value,
      appendResend,
      pastePrevent,
      updateValue,
      copyPrevent,
      onChangeAction,
      isCapitalize,
      maxLength,
      minLength,
      shouldDisable,
      count,
      showResend,
      resendRIghtAlign,
      ResendLabel,
      requestAction,
      widgetId,
      disableKey,
      isUpperCaseValue,
      requestActionOnChange,
      isOnChangeAction,
      appendInnerIcon,
      toggleMarker,
      toggledAppendIcon,
      isErrorCodeIncluded,
      defaultValue,
      errorMessage,
      appendIconClass,
      typeAllowedOnly,
      shouldDisableKeyParam,
      textFieldRef,
      jointAccountDefaultValue,
    };
  },
  components: { ResendWidget },
};
</script>

<style scoped lang="scss">
.info-container {
  padding: 12px;
  margin-top: 0.4rem;
  position: absolute;
  padding-right: 0px;
  right: 54px;
  background-color: $white;
}

.v-text-field {
  &.text-input-uppercase {
    :deep .v-field__input * {
      text-transform: uppercase;
    }
  }

  &.padding-right-2rem {
    :deep .v-icon {
      padding-right: 2rem !important;
    }
  }

  &.text-input-capitalize {
    :deep .v-field__input * {
      text-transform: capitalize;
    }
  }

  :deep {
    .v-field--variant-outlined {
      &:not(.v-field--active) {
        .v-field-label {
          margin-left: 1.5rem;
          margin-top: -0.3rem;
        }
      }

      .v-field__input {
        min-height: 30px !important;
      }
    }

    .v-input__details {
      text-align: left;
    }

    .v-field {
      &__field {
        min-height: 46px;
      }

      .v-field__outline {
        .v-field__outline__start,
        .v-field__outline__end,
        .v-field__outline__notch::before,
        .v-field__outline__notch::after {
          border-color: $border-color;
          opacity: 1;
        }
      }

      &--active {
        .v-field__outline {
          .v-field__outline__notch::before {
            border-color: $white;
          }
        }

        &.v-field--error {
          .v-field__outline__notch::before {
            border-color: $white !important;
          }
        }
      }

      &--error {
        .v-field__outline {
          .v-field__outline__start,
          .v-field__outline__end,
          .v-field__outline__notch::before,
          .v-field__outline__notch::after {
            border-color: $danger;
          }
        }
      }
    }
  }

  &.field-has--error {
    :deep .v-field {
      .v-field-label {
        color: $danger;
        opacity: 1;
      }

      .v-field__outline__start,
      .v-field__outline__end,
      .v-field__outline__notch::before,
      .v-field__outline__notch::after {
        border-color: $danger;
      }

      &--active {
        .v-field__outline {
          .v-field__outline__notch::before {
            border-color: $white;
          }
        }
      }
    }
  }
}

.errorMsg {
  color: $danger;
  font-size: calc($font-size-xs - 1px);

  @media (min-width: $device-width-md) {
    font-size: calc($font-size-sm - 1px);
  }
}

/* Chrome, Safari, Edge, Opera */
</style>
