<template>
  <!-- We will remove margin once we set it up in layout -->
  <input
    ref="fileInput"
    type="file"
    :accept="extensions"
    ripple="false"
    :id="widgetId + '_' + 'input'"
    class="align-at-center"
    @change="pressed"
    hidden
  />
  <div class="d-flex w-100 flex-column">
    <div class="uploader" v-if="!uploadProgress">
      <div v-if="!value" class="center">
        <div class="file-wrapper" @click="reuploadClickFresh">
          <v-btn
            color="white"
            class="upload-icon pa-10 align-at-center"
            fab
            outlined
          >
            <v-icon primary>mdi-arrow-up</v-icon>
          </v-btn>
        </div>
        <h4 class="pt-6 uploader-title">{{ uploadTitle }}</h4>
        <small class="text-center pt-4 mb-0" v-if="!showHint">
          Use {{ validExtension }} files only <br />(upto {{ fileSizeDef }} MB)
        </small>
        <small class="text-center pt-4 mb-0" v-if="addOnText">
          {{ addOnText }}
        </small>
        <small class="text-center pt-4 mb-0 red--text" v-if="errorText">
          Please use {{ validExtension }} files only <br />(upto
          {{ fileSizeDef }} MB)
        </small>
      </div>
      <div v-if="value">
        <transition name="component-fade" mode="out-in">
          <div
            class="preview-wrapper"
            v-if="!isCropOn"
            :id="widgetId"
            data-angle="0"
          >
            <img
              class="v-img"
              v-if="fileExtension != 'pdf'"
              :src="imageUrl"
              width="100%"
              height="100%"
            />
            <div
              v-if="fileExtension == 'pdf'"
              class="d-flex flex-column align-center"
            >
              <v-icon color="red" size="56" class="mb-3">mdi-file-pdf</v-icon>
              <span>{{ pseudoFileName }}</span>
            </div>
          </div>
          <div class="preview-wrapper" v-else :id="widgetId">
            <ImageCropper
              :img="croppedImageSrc"
              @onCrop="cropChange"
              :fileExtension="fileExtension"
            />
          </div>
        </transition>
      </div>

      <p v-if="value && fileExtension != 'pdf'" class="preview">Preview</p>
    </div>

    <div v-else class="uploader">
      <div v-if="isLoadingState == 'error'" class="margin-auto">
        <AlertWidget
          :params="{ isFailed: true, uploadingTitle: uploadError }"
        />
      </div>
      <div
        v-else-if="isLoadingState == 'success'"
        class="d-flex flex-column align-center"
      >
        <AlertWidget
          :params="{ isSuccess: true, uploadingTitle: uploadedTitle }"
        />
      </div>
      <div v-else class="d-flex flex-column align-center">
        <v-progress-circular
          :rotate="360"
          :size="100"
          :width="15"
          :model-value="uploadProgress"
          :color="'green'"
        >
          {{ uploadProgress }}
        </v-progress-circular>
        <h4 class="pt-6 uploader-title">
          {{ uploadProgress?.progress == 100 ? uploadedTitle : uploadingTitle }}
        </h4>
      </div>
    </div>
    <div class="img-preview-quick-links">
      <div
        class="re-upload"
        @click="reuploadAgainClick"
        v-if="!hideReuploadBtn && (value || isLoadingState == 'error')"
      >
        <v-img
          :src="getImage('re-upload.svg')"
          width="12"
          height="12"
          class="flex-grow-0"
        ></v-img>
        <p>Upload Again</p>
      </div>

      <div
        v-if="value && fileExtension != 'pdf' && croppedImageSrc"
        class="rotate-images"
      >
        <span class="edit-image-text">Edit Image: </span>
        <span
          class="rotate pointer"
          @click="rotateBase64Image90deg(imageUrl, false)"
        >
          <v-img
            :src="getImage('Rotate.svg')"
            width="20"
            height="20"
            class="flex-grow-0 rotate-left"
          ></v-img>
        </span>
        <span
          class="rotate pointer"
          @click="rotateBase64Image90deg(imageUrl, true)"
        >
          <v-img
            :src="getImage('Rotate.svg')"
            width="20"
            height="20"
            class="flex-grow-0"
          ></v-img>
        </span>
        <span class="rotate pointer" @click="isCropped">
          <v-img
            :src="getImage('crop.svg')"
            width="20"
            height="20"
            class="flex-grow-0"
            v-if="!isCropOn"
          ></v-img>
          <v-img
            :src="getImage('crop_active.svg')"
            width="20"
            height="20"
            class="flex-grow-0"
            v-if="isCropOn"
          ></v-img>
        </span>
        <span
          class="crop-save pointer"
          :class="[{ primary: isCropOn }]"
          v-if="isCropOn == true"
          @click="doneCrop()"
        >
          SAVE
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  onBeforeMount,
  onMounted,
  onUpdated,
  computed,
  reactive,
  watch,
} from "vue";
import useFormValue from "@/composables/useFormValue";
import { useStore } from "vuex";
import AlertWidget from "./AlertWidget.vue";
import { isValidURL } from "@/utils/url";
import { clearUploadKeys } from "@/handlers/action";
import {
  compressImage,
  convertBlobToBase64,
  getBase64URL,
  urltoFile,
  getImage,
  truncate,
  validImageDataURL,
} from "@/utils/utils";
import { clickStream } from "@/composables/clickStreamAnalytics";
import Analytics from "@/composables/analytics";
import ActionHandler from "@/handlers/action";
import ImageCropper from "./ImageCropper.vue";

export default {
  name: "UploadWidget",
  props: {
    params: Object,
    id: String,
  },
  setup(props) {
    let isUploadStarted = ref(false);
    let isCropOn = ref(false);
    let croppedValue = ref(null);
    let croppedImageSrc = ref(null);
    let showHint = ref(false);
    let progressValue = ref(0);
    let errorText = ref("");
    let fileInput = ref();
    let isSuccess = ref(false);
    let isURL = ref(false);
    const isUrlReactive = reactive(isURL);
    const imageId = ref(props.id);
    let showPreview = ref(false);
    const widgetId = reactive(props.id);
    const uploadButtonId = reactive(props?.params?.uploadButtonId);
    const hideReuploadBtn = reactive(props?.params?.hideReupload || false);
    const setFileName = reactive(props?.params?.setFileName || "");
    const source = reactive(props?.params?.source || "");
    const { value } = useFormValue(widgetId);
    const store = useStore();
    let imageUrl = ref(props.id);
    const fileSizeDef = ref(props.params.fileSizeDef);
    const extensions = ref(props.params.extensions);
    const onChange = ref(props.params.onChange);
    const showUpload = ref(props.params.showUpload);
    const addOnText = ref(props.params.additionalText);
    let validExtension = extensions.value.sort().join(",");
    let fileExtension = ref(null);
    // let clickStream = new ClickStreamAnalytics();
    let csEvents = reactive(props?.params?.cs?.bodyParameters);
    let dlEvents = reactive(props?.params?.native?.bodyParameters);
    let fileName = ref(null);
    const pseudoFileName = computed({
      get() {
        return truncate(fileName.value, 10, "...");
      },
    });
    const uploadProgress = computed({
      get() {
        return store.state.progressIndicators[uploadButtonId];
      },
    });
    let isLoadingState = computed({
      get() {
        return store.state.loadingIndicators[uploadButtonId];
      },
    });
    function pressed(e) {
      if (source) {
        store.dispatch("setFormData", {
          key: source.key,
          value: source.value,
        });
      }
      let fileSizeByte = fileSizeDef.value * 1024 * 1024; // change byte to mb
      fileExtension.value = e.target.files[0].name
        .replace(/.*\./, "")
        .toLowerCase();
      fileName.value = e.target.files[0].name;
      if (
        e.target.files[0].size < fileSizeByte &&
        extensions.value.indexOf(fileExtension.value) > -1
      ) {
        showHint.value = false;
        isSuccess.value = false;
        previewImageFunc(e.target.files[0]);
        croppedImageSrcValue(e.target.files[0]);
        if (setFileName) {
          store.dispatch("setFormData", {
            key: setFileName,
            value: e.target.files[0].name,
          });
        }
        store.dispatch("setFormData", {
          key: widgetId,
          value: e.target.files[0],
        });
        if (onChange.value?.requestOn == "onChangeVal") {
          ActionHandler.handleSingleAction(
            onChange.value,
            widgetId,
            e.target.files[0]
          );
        }
      } else {
        if (e.target.files[0].size > fileSizeByte) {
          if (fileExtension.value != "pdf") {
            compressImage(e.target.files[0], 0.8).then(async (file) => {
              let base64CompressedImage;
              try {
                base64CompressedImage = await convertBlobToBase64(file);
              } catch (error) {
                // console.error("error", error);
              }

              if (file.size <= fileSizeByte) {
                showHint.value = false;
                isSuccess.value = false;
                previewImageFunc(file);
                croppedImageSrcValue(file);
                if (base64CompressedImage) {
                  store.dispatch("setFormData", {
                    key: widgetId,
                    value: base64CompressedImage,
                  });
                }
                if (onChange.value?.requestOn == "onChangeVal") {
                  ActionHandler.handleSingleAction(
                    onChange.value,
                    widgetId,
                    file
                  );
                }
              } else {
                showHint.value = true;
                errorText.value =
                  "Please upload file less than " + fileSizeDef.value + " MB";
              }
            });
          } else {
            showHint.value = true;
            errorText.value =
              "Please upload file less than " + fileSizeDef.value + " MB";
          }
        } else if (extensions.value.indexOf(fileExtension.value) < 0) {
          showHint.value = true;
          errorText.value = "Please use " + validExtension + " only";
        }
      }
    }

    watch(
      () => store.state.loadingIndicators,
      (currentValue) => {
        if (Object.values(currentValue).includes("error")) {
          setTimeout(() => {
            reuploadClick(true);
          }, 0);
        }
      }
    );

    function errorReuploadClick() {
      clearUploadKeys(uploadButtonId);
      reuploadClick();
    }
    function reuploadClick(skipFileManager) {
      try {
        const element = document.getElementById(`${widgetId}_input`);
        croppedValue.value = null;
        isCropOn.value = false;
        store.dispatch("setClearLoading");
        store.dispatch("setClearProgress");
        store.dispatch("setButtonToDisable", true);
        if (value.value) {
          element.value = null;
          store.dispatch("setFormData", {
            key: widgetId,
            value: null,
          });
          store.dispatch("setButtonToDisable", false);
        }
        !skipFileManager && element.click();
      } catch (error) {
        // eslint-disable-next-line no-console
        // console.error("error", error);
      }
    }
    function reuploadClickFresh() {
      reuploadClick();
      clickStream.addEventList(csEvents);
      Analytics.analyticsEventLog(dlEvents);
    }
    function reuploadAgainClick() {
      reuploadClick();
      let reuploadCsEvents = {};
      let reuploadDlEvents = {};
      switch (uploadButtonId) {
        case "panUpload":
          reuploadCsEvents = {
            event_screen: "s-uploadpancard",
            event_type: "click",
            event_sub_type: "button",
            event_name: "tryagain",
            event_id: "73.0.0.9.5",
          };
          reuploadDlEvents = {
            event_name: "itrade_PAN_Reupload_Click",
            event_category: "itrade_pan_details",
            event_action: "itrade_pan_reupload_click",
          };
          break;
        case "cancelchequeSubmit":
          reuploadCsEvents = {
            event_screen: "s-uploadcancelledcheque",
            event_type: "click",
            event_sub_type: "button",
            event_name: "tryagain",
            event_id: "73.0.0.38.4",
            event_metadata: {
              "message body": "error uploading",
            },
          };
          break;
        case "bankStatementPdfUrl":
          reuploadCsEvents = {
            event_screen: "s-uploadyour6monthbankstatement",
            event_type: "click",
            event_sub_type: "button",
            event_name: "tryagain",
            event_id: "73.0.0.40.4",
            event_metadata: {
              "message body": "error uploading.",
            },
          };
          break;
        case "signature":
          reuploadCsEvents = {
            event_screen: "p-uploadsignature",
            event_type: "click",
            event_sub_type: "button",
            event_name: "retry",
            event_id: "73.0.0.42.3",
          };
          break;
        case "aadhaar":
          reuploadCsEvents = {
            event_screen: "p-uploadaadharcard",
            event_type: "click",
            event_sub_type: "button",
            event_name: "tryagain",
            event_id: "73.0.0.35.4",
          };
      }
      clickStream.addEventList(reuploadCsEvents);
      Analytics.analyticsEventLog(reuploadDlEvents);
    }
    function previewImageFunc(file) {
      if (file) {
        let reader = new FileReader();
        reader.onload = async (e) => {
          imageUrl.value = `${e.target.result}`;
        };
        fileName.value = file.name;
        fileExtension.value = file.name.replace(/.*\./, "").toLowerCase();
        reader.readAsDataURL(file);
        store.dispatch("setButtonToDisable", false);
      }
    }

    function croppedImageSrcValue(file) {
      if (file) {
        let reader = new FileReader();
        reader.onload = async (e) => {
          croppedImageSrc.value = `${e.target.result}`;
        };
        reader.readAsDataURL(file);
      }
    }

    function cropOn(src) {
      croppedValue.value = src;
    }
    function cropChange(img) {
      croppedValue.value = img;
    }
    async function doneCrop() {
      if (croppedValue.value && validImageDataURL(croppedValue.value)) {
        let file = await urltoFile(croppedValue.value, "pan-cropped");
        if (file.size > fileSizeDef.value * 1024 * 1024) {
          try {
            file = await compressImage(file, 0.8);
          } catch (error) {
            // console.error("error while compressing cropped image", error);
          }
        }
        previewImageFunc(file);
        store.dispatch("setFormData", {
          key: imageId?.value,
          value: file,
        });
        croppedValue.value = null;
      }
      isCropOn.value = false;
    }
    function isCropped() {
      isCropOn.value = true;
    }
    function rotateLeft(e) {
      if (e) {
        let imgg = document.getElementById(imageId.value);
        const angle = (parseInt(imgg.dataset.angle) - 90) % 360;
        if (angle == -90 || angle == -270) {
          imgg.style.transform = `rotate(${angle}deg) scale(0.8)`;
        } else {
          imgg.style.transform = `rotate(${angle}deg)`;
        }
        imgg.style.transformOrigin = "center center";
        imgg.dataset.angle = angle;
      }
    }
    function rotateRight(e) {
      if (e) {
        let imgg = document.getElementById(imageId.value);
        const angle = (parseInt(imgg.dataset.angle) + 90) % 360;
        if (angle == 90 || angle == 270) {
          imgg.style.transform = `rotate(${angle}deg) scale(0.8)`;
        } else {
          imgg.style.transform = `rotate(${angle}deg)`;
        }
        imgg.style.transformOrigin = "center center";
        imgg.dataset.angle = angle;
      }
    }

    function rotateBase64Image90deg(base64Image, isClockwise) {
      let offScreenCanvas = document.createElement("canvas");
      let offScreenCanvasCtx = offScreenCanvas.getContext("2d");
      let img = new Image();
      img.src = base64Image;
      offScreenCanvas.height = img.width;
      offScreenCanvas.width = img.height;
      if (isClockwise) {
        offScreenCanvasCtx.rotate((90 * Math.PI) / 180);
        offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
      } else {
        offScreenCanvasCtx.rotate((-90 * Math.PI) / 180);
        offScreenCanvasCtx.translate(-offScreenCanvas.height, 0);
      }
      offScreenCanvasCtx.drawImage(img, 0, 0);

      // encode image to data-uri with base64
      imageUrl.value = offScreenCanvas.toDataURL(
        `image/${fileExtension.value}`,
        100
      );
      urltoFile(imageUrl.value, fileName.value).then((file) => {
        store.dispatch("setFormData", {
          key: imageId?.value,
          value: file,
        });
      }); // }
    }

    const setUploadedFileData = () => {
      if (isValidURL(value.value)) {
        getFileName(value.value);
        getBase64URL(
          imageId.value,
          (data) => {
            urltoFile(data, fileName.value).then((file) => {
              store.dispatch("setFormData", {
                key: imageId?.value,
                value: file,
              });
            });
          },
          `data:image/${fileExtension.value};base64`
        );
      } else if (!(value?.value instanceof File)) {
        if (value?.value && validImageDataURL(value.value)) {
          urltoFile(value?.value, "uploadedFile").then((file) => {
            store.dispatch("setFormData", {
              key: imageId?.value,
              value: file,
            });
          });
        }
      }
    };
    onMounted(() => {
      if (showUpload.value) {
        store.dispatch("setClearLoading");
        store.dispatch("setClearProgress");
        if (value.value) {
          store.dispatch("setFormData", {
            key: widgetId,
            value: "",
          });
        }
      } else {
        setUploadedFileData();
        if (value?.value && typeof value?.value == "object") {
          previewImageFunc(value?.value);
        } else {
          !value?.value && store.dispatch("setButtonToDisable", true);
          let img = document.getElementById(imageId?.value);
          if (img) {
            imageUrl.value = `${value?.value}`;
          }
        }
      }
    });
    onUpdated(() => {
      setUploadedFileData();
      if (typeof value.value == "string") {
        isURL.value = isValidURL(value.value);
      }
    });
    onBeforeMount(() => {
      if (value.value) {
        fileInput = value.value;
      }
    });

    function getFileName(url) {
      let urlArray = url.split("/");
      let urmParam = urlArray[5];
      let fileNameFromUrl = urmParam.split("?");
      fileName.value = fileNameFromUrl[0];
      fileExtension.value = fileNameFromUrl[0]
        .replace(/.*\./, "")
        .toLowerCase();
    }
    const { uploadTitle, uploadingTitle, uploadedTitle, uploadError } =
      reactive(props.params);
    return {
      uploadTitle,
      uploadingTitle,
      pressed,
      isUploadStarted,
      progressValue,
      fileInput,
      showHint,
      errorText,
      widgetId,
      value,
      isSuccess,
      uploadedTitle,
      uploadError,
      showPreview,
      previewImageFunc,
      uploadProgress,
      isLoadingState,
      imageUrl,
      imageId,
      reuploadClick,
      fileSizeDef,
      extensions,
      validExtension,
      isUrlReactive,
      rotateLeft,
      rotateRight,
      fileExtension,
      fileName,
      pseudoFileName,
      onChange,
      showUpload: false,
      errorReuploadClick,
      reuploadAgainClick,
      reuploadClickFresh,
      getImage,
      isCropped,
      isCropOn,
      cropOn,
      doneCrop,
      cropChange,
      croppedImageSrc,
      hideReuploadBtn,
      rotateBase64Image90deg,
      addOnText,
    };
  },
  components: { AlertWidget, ImageCropper },
};
</script>

<style scoped lang="scss">
* {
  color: $gray-900;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active for <2.1.8 */ {
  opacity: 0;
}

.uploader-title {
  font-size: $font-size-base;
  line-height: 19.2px;
  color: $gray-900;
}

.margin-auto {
  margin: auto;
}

.uploader {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  border: 2px dashed $gray-500;
  padding: 23px 40px;
  position: relative;
  min-height: 248px;

  .file-wrapper {
    position: relative;
    width: 80px;
    height: 80px;
    margin-top: 5px;

    .align-at-center {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    input {
      width: 76px;
      height: 76px;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
    }

    .upload-icon {
      border: 1px solid $gray-400 !important;
      border-radius: 50%;
      position: relative;
      box-shadow: none;
      cursor: pointer;

      :deep .v-btn__content {
        overflow: visible !important;
        @extend .align-at-center;
      }

      .v-icon {
        color: $primary !important;
        border-radius: 50%;
        padding: 18px;
        border: 14px solid $progress-bar-bg !important;
      }
    }
  }
}

.red--text {
  color: $danger !important;
}

:deep .v-progress-circular {
  border: 1px solid $gray-400;
  border-radius: 50%;

  svg {
    padding: 12px !important;
  }

  &__underlay {
    stroke: transparent !important;
  }

  &__content {
    color: $success !important;
  }
}

.text-green {
  color: $progress-stroke-color !important;
  caret-color: $progress-stroke-color !important;
}

.preview-wrapper {
  max-width: 420px;
  overflow: visible;
  position: relative;
  display: block;
  padding: 40px;

  @media (max-width: 600px) {
    padding: 40px 0;
  }

  img,
  iframe {
    display: block;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &.pdf-preview {
    min-width: calc(100% - 40px);
    min-height: 500px;
  }
}

.preview {
  padding-top: 10px;
  margin-bottom: -12px;
  line-height: $line-height-16;
  font-size: $font-size-sm;
}

.img-preview-quick-links {
  display: flex;

  & > div {
    flex: 1 1 50%;
  }

  .re-upload {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    color: $primary;
    font-weight: 600;
    margin-top: 0.5rem;
    position: absolute;

    .v-img {
      margin-right: 5px;
    }

    p {
      color: $primary;
    }
  }

  .rotate-images {
    margin-top: 0.5rem;
    display: flex;
    justify-content: right;
    gap: 5px;
    .rotate-left {
      transform: rotateY(180deg);
    }
  }
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pointer {
  cursor: pointer;
}
.crop-save,
.edit-image-text {
  font-size: 14px;
  // margin: 0 10px;
}

.primary {
  color: $primary;
}
</style>
