<template>
  <v-img
    :width="imageWidth"
    :height="imageHeight"
    :max-width="maxWidth"
    :max-height="maxHeight"
    aspect-ratio="1"
    :class="[
      { 'd-none d-sm-block': hideInMobile },
      { 'flex-grow-0': imageLeftAligned },
    ]"
    class="w-sm-100"
    :src="imageName"
  ></v-img>
</template>

<script>
import { reactive, onBeforeMount, ref } from "vue";

import useFormValue from "@/composables/useFormValue";
export default {
  name: "NetworkImageWidget",
  props: {
    params: Object,
    id: String,
  },
  setup(props) {
    const widgetId = reactive(props.id);
    const { value } = useFormValue(widgetId);

    let imageName = ref();

    onBeforeMount(() => {
      if (value.value instanceof File) {
        getBase64(value.value).then((data) => {
          imageName.value = data;
        });
      } else {
        imageName.value = value.value;
      }
    });

    function getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }

    const {
      imageWidth,
      imageHeight,
      iconName,
      maxHeight,
      maxWidth,
      hideInMobile = false,
      imageLeftAligned = true,
    } = reactive(props.params);

    return {
      imageWidth,
      imageHeight,
      imageName,
      iconName,
      hideInMobile,
      maxHeight,
      maxWidth,
      imageLeftAligned,
      value,
      widgetId,
    };
  },
};
</script>

<style scoped="scss">
@media (max-width: 768px) {
  .w-sm-100 {
    width: calc(100% - 40px) !important;
    margin-left: 20px;
  }
}
</style>
