import ActionHandler from "@/handlers/action";
import Compressor from "compressorjs";
import { isBase64 } from "./url";
import jwt_decode from "jwt-decode";
import store from "@/store/store";
import { initateChatBot } from "@/services/chatbot";

export function urltoFile(url, filename) {
  let mimeType = (url.match(/^data:([^;]+);/) || "")[1];
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    })
    .catch((e) => {
      console.error(e);
    });
}

function getFileTypeFromExtension(fileName) {
  const re = /(?:\.([^.]+))?$/;
  return re.exec(fileName)[1];
}

export function getBase64FileType(fileName) {
  const fileType = getFileTypeFromExtension(fileName);
  switch (fileType) {
    case "pdf":
      return "data:application/pdf;base64";
    case "png":
      return "data:image/png;base64";
    case "jpg":
    case "jpeg":
      return "data:image/jpeg;base64";

    default:
      return null;
  }
}

export const validImageDataURL = (dataURL) => {
  const regex = /data:(image\/(?=png|jpg|jpeg);*)/i;
  return typeof dataURL === "string" && dataURL.match(regex)
    ? dataURL.match(regex).length
    : false;
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

/* no longer used */
// export function isPWA() {
//   return window && window.matchMedia("(display-mode: standalone)").matches;
// }

export function checkToken(token) {
  try {
    const JWT = jwt_decode(token);
    const currentDate = Math.round(new Date().getTime() / 1000);
    if (JWT && JWT.exp > currentDate) {
      return JWT;
    }
    return null;
  } catch (error) {
    return null;
  }
}

export async function getBase64URL(
  screenId,
  cb,
  type = "data:image/png;base64" //getBase64 data:application/pdf;base64
) {
  let imageUrl = store.state.formData[screenId];
  var myHeaders = new Headers();
  myHeaders.append("pragma", "no-cache");
  myHeaders.append("cache-control", "no-cache");
  myHeaders.append("Origin", window.location.origin);
  myHeaders.append("Access-Control-Allow-Origin", window.location.origin);
  myHeaders.append("Access-Control-Allow-Methods", "GET");
  var init = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(imageUrl, init)
    .then((data) => {
      return data.blob();
    })
    .then((data) => {
      var reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = async function () {
        var base64data = reader.result;
        var imageData = [...base64data.split(",")];
        imageData[0] = type;
        let finalImage = imageData.join(", ");
        store.dispatch("setFormData", {
          key: screenId,
          value: finalImage,
        });
        cb(finalImage);
      };
    })
    .catch((e) => {
      console.error(e);
    });
}

export async function getBase64FromURL(
  id,
  type = "data:application/pdf;base64", //getBase64 "data:application/pdf;base64"
  cb
) {
  const url = store.state.formData[id];
  var myHeaders = new Headers();
  myHeaders.append("pragma", "no-cache");
  myHeaders.append("cache-control", "no-cache");
  myHeaders.append("Origin", window.location.origin);
  myHeaders.append("Access-Control-Allow-Origin", window.location.origin);
  myHeaders.append("Access-Control-Allow-Methods", "GET");
  var init = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(url, init)
    .then((data) => {
      return data.blob();
    })
    .then((data) => {
      var reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = async function () {
        var base64data = reader.result;
        var imageData = [...base64data.split(",")];
        imageData[0] = type;
        let finalImage = imageData.join(", ");
        cb(finalImage);
      };
    })
    .catch((e) => {
      console.error(e);
    });
}

// Need to refactor the code
export function fileToBase64(file, cb) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
}

export function convertUrlToBase64JPEG(
  imgUrl,
  id,
  type = "data:image/jpeg;base64"
) {
  var myHeaders = new Headers();
  myHeaders.append("pragma", "no-cache");
  myHeaders.append("cache-control", "no-cache");
  myHeaders.append("Origin", window.location.origin);
  myHeaders.append("Access-Control-Allow-Origin", window.location.origin);
  myHeaders.append("Access-Control-Allow-Methods", "GET");
  var init = {
    method: "GET",
    headers: myHeaders,
  };
  fetch(imgUrl, init)
    .then((data) => {
      return data.blob();
    })
    .then((data) => {
      var reader = new FileReader();
      const img = new Image();
      reader.onload = async function () {
        const value = reader.result;
        var binary = [...value.split(",")];
        binary[0] = type;
        let uri = binary.join(", ");
        img.src = uri;
        await img.decode();
        store.dispatch("setFormData", {
          key: id,
          value: img.src,
        });
      };
      reader.readAsDataURL(data);
    })
    .catch((e) => {
      console.error(e);
    });
}

export const getImage = (imageName) =>
  `https://cdn.angelone.in/kyc/images/${imageName}`;

export const convertBlobToBase64 = async (blob) => {
  // blob data
  return await blobToBase64(blob);
};

const blobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const base64ToBlob = (base64) => {
  const byteCharacters = atob(base64);
  const byteArray = new Uint8Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }

  return new Blob([byteArray]);
};

export const compressImage = (file, quality = 0.8) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: quality,
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });
};

export const decodeBase64 = (string) => {
  if (isBase64(string)) {
    return window.atob(decodeURIComponent(string));
  }
  return string;
};

export const truncate = (text, length, suffix) => {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
};

export function enableDisableButton(state1, state2) {
  if (state1 && state2) {
    const disable_btn = !!(state1 && state2);
    store.dispatch("setButtonToDisable", disable_btn ? false : true);
  }
}

export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function setNominee(nominees = [], mobileNumber) {
  let totalPercent = 0;
  sessionStorage.setItem("nominees", nominees);
  nominees.forEach((item) => {
    totalPercent += item.sharePercent;
  });
  sessionStorage.setItem("totalSharePercent", totalPercent);
  let sessionData = {
    totalSharePercent: totalPercent,
    nominees: nominees,
  };
  if (mobileNumber) {
    // sessionStorage.setItem("mobile", mobileNumber);
    sessionData.mobile = mobileNumber;
  }
  ActionHandler.setSessionDataFromOBJ(sessionData);

  store.dispatch("setTotalPercentOfNominees", totalPercent);
}

export function onlyNumbers(value) {
  if (value) {
    return value.replace(/[^0-9]/g, "");
  }
}

export const getConcatedValueFromStore = (items) => {
  let str = "";
  items.forEach((key) => {
    str += store.state.formData[key] || "";
  });
  return str;
};

export const customClosePopup = {
  segmentActivationPopup: (val) => {
    store.dispatch("setFormData", {
      key: "segmentSelection",
      value: [...val],
    });
  },
};

const SEGMENT_SELECTION_LABELS = {
  isEquity: "Equity & Mutual Funds",
  isFnO: "Futures & Option",
  isCurrency: "Currency",
  isCommodity: "Commodity",
};

export const getActiveSegments = (data) => {
  let activeSegments = [];
  activeSegments = Object.keys(data).reduce((acc, segment) => {
    if (data[segment]) {
      acc = [...acc, SEGMENT_SELECTION_LABELS[segment]];
    }
    return acc;
  }, []);
  return activeSegments;
};

export const activateSegmentsAnalytic = {
  "Futures & Option": {
    event_name: "segmentactivationF&O",
    event_id: "73.0.0.25.7",
  },
  Currency: {
    event_name: "segmentactivationCURR",
    event_id: "73.0.0.25.8",
  },
  Commodity: {
    event_name: "segmentactivationCOMM",
    event_id: "73.0.0.25.9",
  },
};

export function formatName(fullName) {
  if (!fullName) return null;
  const prefixes = [
    "Mr.",
    "Mrs.",
    "Dr.",
    "Ms.",
    "Fr.",
    "Sir",
    "Prof.",
    "Mr",
    "Mrs",
    "Dr",
    "Ms",
    "Fr",
    "Prof",
  ];
  const suffixes = ["Sr", "Jr", "I", "II", "III"];
  const specialWords = ["C/O", "D/O", "S/O", "S.O"];
  const degrees = ["B.Tech", "MBA", "BSc", "IAS", "IPS"];
  const names = fullName.trim().split(" ");
  let firstName =
    !prefixes.includes(names[0]) &&
    !suffixes.includes(names[0]) &&
    !specialWords.includes(names[0]) &&
    !degrees.includes(names[0])
      ? names[0]
      : names[1];

  const lastCharFirstName = firstName.charAt(firstName.length - 1);
  if (lastCharFirstName === "." || lastCharFirstName === ",") {
    firstName = firstName.substr(0, firstName.length - 1);
  }

  let otherNames = "";
  for (let i = 0; i < names.length; i++) {
    const name = names[i];
    if (
      !prefixes.includes(name) &&
      !suffixes.includes(name) &&
      !specialWords.includes(name) &&
      !degrees.includes(name) &&
      firstName != name
    ) {
      otherNames += " " + name.charAt(0);
      if (firstName.length > 15 || otherNames.trim().length > 3) break;
    }
  }
  if (firstName.length > 15) {
    firstName = firstName.substr(0, 15);
  }

  return firstName + otherNames;
}

export const checkPlatform = () => {
  const isAndroid = /(android)/i.test(navigator.userAgent.toLowerCase());
  const isIOS = /(ipod|iphone|ipad)/i.test(navigator.userAgent.toLowerCase());

  return isAndroid ? "android" : isIOS ? "IOS" : null;
};
export function getBrowserName() {
  // eslint-disable-next-line no-unused-vars
  var browserName = "";

  // The order matters here, and this may report false positives for unlisted browsers.

  if (navigator.userAgent.indexOf("Firefox") > -1) {
    browserName = "Mozilla Firefox";
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (navigator.userAgent.indexOf("SamsungBrowser") > -1) {
    browserName = "Samsung Internet";
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (
    navigator.userAgent.indexOf("Opera") > -1 ||
    navigator.userAgent.indexOf("OPR") > -1
  ) {
    browserName = "Opera";
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (navigator.userAgent.indexOf("Trident") > -1) {
    browserName = "Microsoft Internet Explorer";
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (navigator.userAgent.indexOf("Edge") > -1) {
    browserName = "Microsoft Edge (Legacy)";
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (navigator.userAgent.indexOf("Edg") > -1) {
    browserName = "Microsoft Edge (Chromium)";
    // Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36 Edg/91.0.864.64
  } else if (navigator.userAgent.indexOf("Chrome") > -1) {
    browserName = "Google Chrome or Chromium";
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (navigator.userAgent.indexOf("Safari") > -1) {
    browserName = "Apple Safari";
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    browserName = "unknown";
  }

  return browserName;
}

export const getHost = () => {
  return window.location.host;
};

export const randomString = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const calculateRollOutPercentage = (
  aapNumber,
  distributionPercentage
) => {
  if (aapNumber) {
    const userIdNum = Number(aapNumber.slice(-2));
    return userIdNum < Number(distributionPercentage);
  }
};

export const handleOpenChatBot = () => {
  initateChatBot();
  window.AlltiusSDK("render", { type: "speedDial", forceRerender: true });
};

export const handleCloseChatBot = () => {
  window.AlltiusSDK("removeSpeedDial");
};
