<template>
  <div class="container">
    <section>
      <div class="header">
        <v-icon style="margin-top: 2px; cursor: pointer" @click="goBack">
          mdi-chevron-left
        </v-icon>
        <span style="font-size: 18px; font-weight: 600; text-align: left">
          Confirm your bank Details
        </span>
      </div>
      <p class="label" style="margin-left: 25px">
        We have successfully linked your bank account you can try again if you
        want to change the bank linked to your account
      </p>
    </section>
    <section class="bankDetails">
      <p style="font-size: 16px; font-weight: 600">
        {{ bankName }}
      </p>
      <div>
        <p class="label">Account Name</p>
        <p class="subLabel">{{ bankAccountName }}</p>
      </div>
      <div>
        <p class="label">Account Number</p>
        <p class="subLabel">{{ bankAccountNumber }}</p>
      </div>
      <div>
        <p class="label">IFSC Code</p>
        <p class="subLabel">{{ ifsc }}</p>
      </div>
    </section>
    <div class="footer">
      <v-btn
        color="primary"
        flat
        size="large"
        class="continueBtn"
        @click="callConfirmRPD"
        :ripple="false"
        :loading="continueBtnLoader"
      >
        Continue
      </v-btn>
      <p style="color: #3f5bd9; cursor: pointer" @click="skipApiCall">
        Edit bank Details
      </p>
    </div>
  </div>
</template>

<script setup>
import CleaverTap from "@/composables/Clevertap";
import useMobileTabletDetector from "@/composables/useMobileDetector";
import LocalNavigator from "@/composables/useNavigation";
import {
  CONFIRM_RPD_ENDPOINT,
  D_WEB,
  M_WEB,
  SKIP_TO_BANK_MANUALLY_ENDPOINT,
} from "@/constants/bandRPDConstants";
import ActionHandler, { clearUploadKeys } from "@/handlers/action";
import store from "@/store/store";
import {
  bankConfirmationAnalytics,
  continueAPIAnalytics,
  continueClickAnalytics,
  editBankDetailsAnaltyics,
} from "@/utils/bankRPDUtil";
import { onMounted, ref } from "vue";

const props = defineProps({
  params: Object,
  id: String,
  layoutData: Object,
});

const widgetId = ref(props.id);

const bankName = ref(store.state.formData?.bankName);
const bankAccountNumber = ref(store.state.formData?.bankAccountNumber);
const ifsc = ref(store.state.formData?.ifsc);
const bankAccountName = ref(store.state.formData?.bankAccountName);

let continueBtnLoader = ref(false);
let isMobile = useMobileTabletDetector();

onMounted(() => {
  bankConfirmationAnalytics({
    appName: store.state.formData?.upiApp,
    bankName: store.state.formData?.bankName,
    ifsc: store.state.formData?.ifsc,
    platform: isMobile.value ? M_WEB : D_WEB,
  });
});

const goBack = () => {
  clearUploadKeys();
  LocalNavigator.pop();
};

const callConfirmRPD = async () => {
  continueClickAnalytics({
    appName: store.state.formData?.upiApp,
    platform: isMobile.value ? M_WEB : D_WEB,
  });
  continueBtnLoader.value = true;
  let confirmRPDAction = {
    api: CONFIRM_RPD_ENDPOINT,
    method: "POST",
  };

  const body = {
    bankAccountNumber: bankAccountNumber.value,
    ifsc: ifsc.value,
    bankFullName: bankAccountName.value,
  };

  try {
    await ActionHandler.handleAPI(confirmRPDAction, body, {}, widgetId);
    continueAPIAnalytics({
      status: true,
      appName: store.state.formData?.upiApp,
      platform: isMobile.value ? M_WEB : D_WEB,
    });
    CleaverTap.addEvents("KYC_CTA_Clicked", {
      event_type: "Confirm Click API",
      event_category: "KYC2.0",
      event_action: "bank_confirmation_RPD",
      AUC: store.state.formData.appNumber,
    });
  } catch (error) {
    console.log(error);
  }

  continueBtnLoader.value = false;
};

const skipApiCall = async () => {
  editBankDetailsAnaltyics({
    appName: store.state.formData?.upiApp,
    platform: isMobile.value ? M_WEB : D_WEB,
  });
  const navigateAction = {
    api: SKIP_TO_BANK_MANUALLY_ENDPOINT,
    method: "POST",
  };
  await ActionHandler.handleAPI(navigateAction, {}, {}, widgetId);
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
}

.bankDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  border: 1px solid #c5c6c7;
  width: 100%;
  gap: 6px;
  padding: 10px;
}

.label {
  color: #6a7582;
  text-align: justify;
  margin-top: 2px;
}

.subLabel {
  text-align: left;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-top: 16px;
}

@media (max-width: 468px) {
  .continueBtn {
    width: 100%;
  }
}

.header {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
</style>
