import "cropperjs/dist/cropper.css";
// getHyperVergeAuthentication
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import { createApp, defineAsyncComponent } from "vue";
import { createMemoryHistory, createRouter } from "vue-router";

// import "v-calendar/dist/style.css";
// import VCalendar from "v-calendar";
import ActionHandler from "./handlers/action";
import Analytics from "./composables/analytics";
import App from "./App.vue";
import CleaverTap from "./composables/Clevertap";
import LocalNavigator from "./composables/useNavigation";
import ScreenView from "@/components/ScreenView.vue";
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import Toast from "vue-toastification";
import { VueSignaturePad } from "vue-signature-pad";
import { clickStream } from "./composables/clickStreamAnalytics";
import { loadFonts } from "./plugins/webfontloader";
import store from "./store/store";
import vue3GoogleLogin from "vue3-google-login";
import vuetify from "./plugins/vuetify";

const NotFound = defineAsyncComponent(() =>
  import("./components/NotFound.vue")
);

// const firebaseConfig = {
//   apiKey: "AIzaSyCOTF5CCWThvMsSzrrh7arY3WOglmvI7Bk",
//   authDomain: "kyc-web-f04f7.firebaseapp.com",
//   projectId: "kyc-web-f04f7",
//   storageBucket: "kyc-web-f04f7.appspot.com",
//   messagingSenderId: "258028774338",
//   appId: "1:258028774338:web:da2b74aa8cb00cb0f22c5b",
//   measurementId: "G-4M7QMBVRHX",
// };

// Initialize Firebase
// const appFirebase = initializeApp(firebaseConfig);

// // Initialize Analytics and get a reference to the service
// const analytics = getAnalytics(appFirebase);

loadFonts();
new CleaverTap();

const router = createRouter({
  history: createMemoryHistory(),
  routes: [
    {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: NotFound,
    },
    {
      path: "/",
      redirect: "/login",
    },
  ],
});

const options = {
  timeout: 2000,
  transition: "Vue-Toastification__bounce",
  maxToasts: 5,
  newestOnTop: true,
  hideProgressBar: true,
  position: "top-right",
  icon: true,
};
/*============ BROSER BACK FUNCTIONALITY START ================*/
window.addEventListener(
  "popstate",
  async function () {
    const navStack = store?.state?.navStack;
    const topRoute = navStack[navStack.length - 1];
    history.pushState({}, "", `#${topRoute}`);
    setTimeout(async () => {
      await LocalNavigator.pop();
    }, 50);
  },
  false
);

window.onbeforeunload = function () {
  const currentHash = window.location.hash.replace("#", "");
  const networkType = navigator?.connection?.effectiveType || "";
  const csEvents = {
    event_screen: "",
    event_type: "click",
    event_sub_type: "button",
    event_name: "BrowserTabClose",
    event_id: "73.0.0.2.14",
    event_metadata: `Screen-name: ${currentHash}, network-type: ${networkType}`,
  };
  if (store.state.ignoreBrowserPromt) {
    return null;
  }
  // const clickStream = new ClickStreamAnalytics();
  clickStream.addEventList(csEvents, true);
  const events = {
    event_action: `browser_close_${currentHash}`,
    event_category: "browser_close",
    event_label: "browser_close_clicked",
  };
  Analytics.analyticsEventLog(events);
  return "Your work will be lost.";
};

LocalNavigator.useRouter(router);
ActionHandler.useRouter(router);

// store.subscribeAction((action, state) => {
//   console.log(`Action: ${action.type}`, {
//     payload: action.payload,
//     state,
//   });
// });

createApp(App)
  .use(router)
  .use(Toast, options)
  .use(vuetify)
  .use(VueSignaturePad)
  .use(store)
  .use(vue3GoogleLogin, {
    clientId: import.meta.env.VITE_GOOGLE_AUTH_CLIENTID,
  })
  // .use(VCalendar, {})
  // .provide("analytics", analytics)
  .component("ScreenView", ScreenView)
  .directive("visible", function (el, binding) {
    el.style.visibility = binding.value ? "visible" : "hidden";
  })
  .mount("#app");

// "goBackAlertKey": "bankEditCofirmation",
