import ActionHandler from "@/handlers/action";
import store from "@/store/store";

export default class LocalNavigator {
  static _router = null;
  static useRouter(router) {
    LocalNavigator._router = router;
  }

  static pushState(route) {
    store.dispatch("pushNav", route);
  }

  static pushStateless(route) {
    return LocalNavigator._router.replace(route);
  }

  static push(route) {
    if (!route) return;
    route = route.includes("/") ? route : "/" + route;
    if (!LocalNavigator._router) {
      // console.warn("router is undefined..");
    } else {
      let currentUrl = window.location;
      const currentSearch = window.location.toString().split("?");
      if (currentUrl.toString().includes("/#/login") && route == "/login") {
        if (
          currentSearch[1] == null ||
          currentSearch[1] == undefined ||
          currentSearch[1] == ""
        ) {
          LocalNavigator._router.push(route).then((failure) => {
            history.replaceState({}, "", `#${route}`);
            if (!failure) {
              store.dispatch("pushNav", route);
            } else {
              // console.warn("FAILED TO ADD ROUTE ");
            }
          });
        } else {
          let redirectUrl = currentUrl.toString().replace("/#/login", "");
          redirectUrl = redirectUrl.concat("#/login");
          window.location.replace(redirectUrl, "");
        }
      } else {
        LocalNavigator._router.push(route).then((failure) => {
          history.replaceState({}, "", `#${route}`);
          if (!failure) {
            store.dispatch("pushNav", route);
          } else {
            // console.warn("FAILED TO ADD ROUTE ");
          }
        });
      }
    }
  }

  static showBackAlert() {
    let goBackAlert;
    const state = store?.state;
    const navStack = state.navStack ? [...state.navStack] : [];
    const screens = state?.layout?.screens;
    if (navStack.length >= 1) {
      const topRoute = navStack[navStack.length - 1].replace("/", "");
      goBackAlert = screens[topRoute]?.goBackAlert === true ? true : false;
    }
    return goBackAlert;
  }

  static forcePop() {
    store?.dispatch("popNav");
    const navStack = store?.state?.navStack;
    if (navStack?.length >= 1) {
      const topRoute = navStack[navStack.length - 1];
      LocalNavigator._router.replace(topRoute);
      history.replaceState({}, "", `#${topRoute}`);
    }
  }
  static popToRoute(route) {
    store?.dispatch("popRouteToNav", route);
    const navStack = store?.state?.navStack;
    if (navStack?.length >= 1) {
      const topRoute = navStack[navStack.length - 1];
      LocalNavigator._router.replace(topRoute);
      history.replaceState({}, "", `#${topRoute}`);
    }
  }

  static pop() {
    const routes = [
      "aadhaar",
      "/aadhaar",
      "/selfie",
      "selfie",
      "panUpload",
      "/panUpload",
    ];
    const previousRoute = ["bank", "/bank"];
    const state = store?.state;
    const navStack = state?.navStack;
    const screens = state?.layout?.screens;
    function getPopup(currentRoute, previousPage) {
      if (
        routes.includes(currentRoute) &&
        previousRoute.includes(previousPage)
      ) {
        return "bankEditCofirmation";
      }
      return "backConfirmation";
    }
    return new Promise((resolve) => {
      if (!LocalNavigator._router) {
        // console.warn("router is undefined..");
        resolve(false);
      } else {
        if (LocalNavigator.showBackAlert()) {
          const routeKey = navStack[navStack.length - 1];

          const previousPage = navStack[navStack.length - 2];

          const routeKeyPopUp = routeKey.split("/")[1] ?? routeKey;
          const popUpKey = screens[routeKeyPopUp]?.goBackAlertKey
            ? screens[routeKeyPopUp]?.goBackAlertKey
            : getPopup(routeKey, previousPage);

          const backAction = {
            type: "openPopup",
            value: popUpKey,
          };
          Promise.all([ActionHandler.handleAction(backAction, routeKey)]).then(
            () => {
              resolve(true);
            }
          );
        } else {
          LocalNavigator.forcePop();
          resolve(true);
        }
      }
    });
  }
}
