export const API_RETRY_CONFIG = {
  RETRY_COUNT: 2,
  RETRY_ERROR_CODES: [500, 502, 503, 504],
  RETRY_DELAY: 2000,
};

export const HTTP_METHOD_GET = "GET";
export const HTTP_METHOD_POST = "POST";
export const HTTP_METHOD_PUT = "PUT";
export const HTTP_METHOD_DELETE = "DELETE";
export const TIMEOUT = 10000;
