<template>
  <div class="top-bank-list-comp">
    <p class="header">
      {{ header }}
    </p>
    <div class="bank-list">
      <div
        :class="['bank-box', { selected: item.BankName == bankN }]"
        @click="() => onClickAction(item)"
        v-for="(item, i) in items"
        :key="i"
      >
        <img :alt="item.BankName" :src="item.BankLogo" />
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/store";
import { getImage } from "@/utils/utils";
import { reactive } from "@vue/reactivity";
import { computed } from "vue";

export default {
  name: "TopBankListWidget",
  props: {
    params: Object,
  },
  setup(props) {
    const { items, border, requestAction, header, selectedBank } = reactive(
      props.params
    );
    const bankN = computed({
      get() {
        return store.state.formData[`${selectedBank}`];
      },
    });

    function onClickAction(item) {
      if (item) {
        store.dispatch("setFormData", {
          key: selectedBank,
          value: item.BankName,
        });
        store.dispatch("setFormData", {
          key: "bankCode",
          value: item.BankCode,
        });
      }
    }

    return {
      border,
      items,
      getImage,
      onClickAction,
      requestAction,
      header,
      bankN,
    };
  },
};
</script>

<style scoped lang="scss">
* {
  font-family: $font-base-reg;
  color: $gray-800;
}

.top-bank-list-comp {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .header {
    color: #5d5e63;

    font-family: $font-base-med;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.bank-list {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 450px) {
    /*Small smartphones [325px -> 425px]*/
    gap: 10px;
  }

  .bank-box {
    padding: 6px;
    border: 1px solid #e0e0ec;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 50px;
    height: 50px;

    &.selected {
      border: 2px solid #e0e0ec;
      -moz-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      -o-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
      transition: 0.2s all ease-in-out;
    }

    @media only screen and (max-width: 450px) {
      width: 35px;
      height: 35px;
    }

    cursor: pointer;

    &:hover {
      -moz-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      -o-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
      transition: 0.2s all ease-in-out;
    }

    img {
      height: 80%;
      width: 80%;
      max-width: 30px;
      max-height: 30px;
    }
  }
}
</style>
