import { showInfoMessage, showErrorMessage } from "@/services/HttpClient";
import store from "@/store/store";
const DEFAULT_STATE = true;
import useRuleParser from "./useRuleParser";

export function useMessageonSnackBar(condition, messageData) {
  if (!condition) return DEFAULT_STATE;
  if (condition.constructor !== String) {
    //TODO show a warning here
    return DEFAULT_STATE;
  }
  if (condition.includes("&&")) {
    const conditionValue = useRuleParser(condition);
    let messageJson = messageData.find((item) => {
      return item.conditionVal == conditionValue;
    });
    conditionValue && showInfoMessage(messageJson.message, "infoMessage", 3000);
  } else {
    const formData = store?.state;
    if (!formData) return DEFAULT_STATE;
    const fieldArray = condition.split(".");
    const derivedLHSValue = findAllByKey(formData, fieldArray);

    let messageJson = messageData.find((item) => {
      return item.conditionVal == derivedLHSValue;
    });

    switch (messageJson.type) {
      case "error":
        showErrorMessage(messageJson.message, "errorMessage");
        break;
      default:
        showInfoMessage(messageJson.message, "infoMessage", 3000);
    }
  }
}

export function findAllByKey(obj, keyArray) {
  if (keyArray.constructor == Array) {
    for (let i = 0; i < keyArray.length; i++) {
      let value = obj[keyArray[i]];
      if (typeof value == "object") {
        keyArray.splice(i, 1);
        return findAllByKey(value, keyArray);
        // break;
      } else if (typeof value == "string" || typeof value == "boolean") {
        return value;
      }
    }
  } else {
    return obj[keyArray];
  }
}
