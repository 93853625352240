<template>
  <div class="divWrapper">
    <div v-for="(rowData, i) in rowDatas" :key="i">
      <template v-for="(icon, j) in icons" :key="j">
        <div v-if="useRuleParser(icon.visibleWhen)">
          <v-icon size="16" :color="icon.iconColor" v-if="icon?.iconName">
            {{ icon?.iconName }}
          </v-icon>
          <ImageWidget
            :params="{
              isImage: true,
              imageName: icon?.imageName,
              imageHeight: icon?.imageHeight,
              imageWidth: icon?.imageWidth,
              maxWidth: icon?.imageWidth,
            }"
          ></ImageWidget>
        </div>
      </template>
      <div class="captionClass">
        <span class="ml-3" v-if="caption">{{ caption }}</span>
        <span class="ml-3" v-if="hasIndex">{{ i + 1 }}</span>
        <span class="ml-3" v-if="hasIndex">:</span>
        <strong class="ml-3">{{ rowData[dataKey] }}</strong>
      </div>
      <div class="ml-auto btn-flex">
        <v-btn
          id="primaryButton"
          v-if="primaryButton"
          flat
          class="ml-3"
          :color="primaryButton.btnColor"
          :elevation="primaryButton.btnElevation"
          :size="primaryButton.btnSize"
          :variant="primaryButton.btnVariant"
          :rounded="primaryButton.btnRoundedType"
          :ripple="false"
          type="button"
          v-on:click="editData(rowData, i)"
        >
          {{ primaryButton.title }}
        </v-btn>
        <v-btn
          id="secondaryButton"
          v-if="secondaryButton"
          flat
          class="ml-3"
          :color="secondaryButton.btnColor"
          :elevation="secondaryButton.btnElevation"
          :size="secondaryButton.btnSize"
          :variant="secondaryButton.btnVariant"
          :rounded="secondaryButton.btnRoundedType"
          :ripple="false"
          type="button"
          v-on:click="deleteData(rowData, i)"
        >
          {{ secondaryButton.title }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, computed } from "@vue/reactivity";
import { useStore } from "vuex";
import ActionHandler from "@/handlers/action";
import useRuleParser from "@/composables/useRuleParser";
import ImageWidget from "./ImageWidget.vue";
export default {
  name: "CardCollectionWidget",
  props: {
    id: String,
    params: Object,
  },
  components: { ImageWidget },
  setup(props) {
    const {
      tableData,
      cardConfig,
      editFields,
      editAction,
      deleteFields,
      deleteAction,
      editIndexKey,
      sharePecentStore,
      storeDeleteKeyData,
      uniqueID,
      isSessionStore,
    } = reactive(props.params);
    const {
      caption,
      hasIndex,
      icons,
      primaryButton,
      secondaryButton,
      dataKey,
      isImage,
    } = cardConfig;
    const widgetId = reactive(props.id);
    const store = useStore();
    let rowDatas = computed({
      get() {
        if (store?.state?.formData[tableData]) {
          return store?.state?.formData[tableData];
        }
        return [];
      },
    });
    function editData(data, index) {
      for (const key in editFields) {
        const value = editFields[key];
        store.dispatch("setFormData", {
          key: key,
          value: data[value],
        });
      }
      store.dispatch("setIndexValue", {
        key: editIndexKey,
        value: index,
      });
      if (editAction) {
        ActionHandler.handleAction(editAction, widgetId);
      }
    }
    function deleteData(data, index) {
      for (const key in deleteFields) {
        const value = deleteFields[key];
        store.dispatch("setFormData", {
          key: key,
          value: data[value],
        });
      }
      const tableArrayData = store.state.formData[tableData];
      const elementData = tableArrayData[index];
      const storeData =
        store.state.formData[storeDeleteKeyData] -
        elementData[sharePecentStore];
      store.dispatch("setFormData", {
        key: storeDeleteKeyData,
        value: storeData,
      });
      tableArrayData.splice(index, 1);
      store.dispatch("setFormData", {
        key: tableData,
        value: tableArrayData,
      });

      if (isSessionStore && tableArrayData.length != 0) {
        sessionStorage.setItem(tableData, JSON.stringify(tableArrayData));
        sessionStorage.setItem(storeDeleteKeyData, storeData);
      } else if (tableArrayData.length == 0 && isSessionStore) {
        sessionStorage.removeItem(tableData);
        sessionStorage.removeItem(storeDeleteKeyData);
        sessionStorage.removeItem(uniqueID); //mobile
      }

      // store.state.formData[tableData].splice(index, 1);
      if (deleteAction) {
        ActionHandler.handleAction(deleteAction, widgetId);
      }
    }
    return {
      rowDatas,
      tableData,
      caption,
      hasIndex,
      icons,
      primaryButton,
      secondaryButton,
      editFields,
      deleteFields,
      dataKey,
      editData,
      editAction,
      deleteAction,
      editIndexKey,
      sharePecentStore,
      storeDeleteKeyData,
      useRuleParser,
      deleteData,
      isImage,
      isSessionStore,
      uniqueID,
    };
  },
};
</script>
<style scoped lang="scss">
.divWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  & > div {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    padding: 20px 25px;
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
  header {
    padding-top: 16px;
    h2 {
      font-size: $font-size-base;
      padding: 7px 16px;
      line-height: 1;
    }
  }
}
.editColumn {
  color: $btn-variant-plain-color;
  margin: 10px;
  cursor: pointer;
}
.captionClass {
  color: $gray-800;

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// .btn-flex {
//   @media (max-width: 600px) {
//     justify-content: center;
//     display: flex;
//     align-items: center;
//   }
// }
</style>
