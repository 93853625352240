<template>
  <v-checkbox
    :color="checkBoxColor"
    :label="label"
    :disabled="isdisabled"
    v-model="value"
    :rules="rules"
    v-if="!checkboxesArr?.length && !isLinkCheckBox"
    :style="customStyles"
    :hide-details="hideDetails"
  >
  </v-checkbox>
  <div v-else-if="isLinkCheckBox" class="row" style="width: 100%">
    <v-checkbox
      v-model="value"
      :color="checkBoxColor"
      :rules="rules"
      :disabled="isdisabled"
      style="width: 8%"
    />
    <div class="row" style="width: 92%; text-align: justify">
      <span
        v-for="({ label, linkText, requestAction }, idx) in labelArr"
        :key="idx"
        :style="customLabelStyles"
      >
        <span>{{ label }}</span>
        <!-- <a :href="linkValue">{{ linkText }}</a> -->
        <span
          style="color: #3f5bd9; cursor: pointer"
          @click="onClickAction(requestAction)"
        >
          {{ linkText }}
        </span>
      </span>
    </div>
  </div>
  <div v-else style="width: 100%">
    <template
      v-for="(
        { label, subLabel, isDisabled, customSubLabelStyles, subLabelIcon }, idx
      ) in checkboxesArr"
      :key="idx + label"
    >
      <v-checkbox
        v-model="value"
        :color="checkBoxColor"
        :disabled="isDisabled"
        :rules="rules"
        :style="customCheckBoxStyles"
        :value="label"
        hide-details="false"
        @change="handleAnalyticsCallOnChange(label, $event)"
      >
        <template v-slot:label>
          <div style="width: 100%">
            <span :style="customLabelStyles">{{ label }}</span>
            <span :style="customSubLabelStyles">
              <v-icon v-if="subLabelIcon">{{ subLabelIcon }}</v-icon>
              {{ subLabel }}
            </span>
          </div>
        </template>
      </v-checkbox>
    </template>
  </div>
</template>
<script>
import useFormValue from "@/composables/useFormValue";
import useRuleParser from "@/composables/useRuleParser";
import { reactive, ref } from "@vue/reactivity";
import { useStore } from "vuex";

import useCreateValidationRule from "@/composables/useCreateValidationRule";
import ActionHandler from "@/handlers/action";

import {
  activateSegmentsAnalytic,
  getConcatedValueFromStore,
} from "@/utils/utils";
import { computed, onMounted, onUnmounted, watch } from "vue";

export default {
  name: "CheckBoxWidget",
  props: {
    params: Object,
    id: String,
    validations: Array,
  },
  setup(props) {
    const widgetId = ref(props.id);
    const store = useStore();

    const {
      label,
      checkBoxColor = "primary",
      defaultValue,
      isdisabled,
      dependentKeyRule,
      dependentKey,
      updateTextfieldId,
      textFieldStoreKeys = [],
      checkboxesArr,
      customLabelStyles,
      customCheckBoxStyles,
      disabledLabelOpacity = 0.38,
      isLinkCheckBox = false,
      labelArr,
      customStyles = "",
      hideDetails = false,
    } = reactive(props.params);
    const { value } = useFormValue(widgetId.value);
    const chk_start_value = computed({
      get() {
        const keyInState = widgetId.value in store.state.formData;
        if (keyInState) {
          return value.value;
        } else if (defaultValue) {
          return defaultValue;
        } else {
          return false;
        }
      },
    });

    function onClickAction(requestAction) {
      ActionHandler.handleAction(requestAction, "", {});
    }
    watch(
      () => store.state.formData[`${dependentKey}`],
      () => {
        value.value = useRuleParser(dependentKeyRule);
      }
    );

    const validations = reactive(props.validations);

    const { rules } = useCreateValidationRule(validations);
    onMounted(() => {
      // if (defaultValue && typeof value.value != "boolean") {
      //   value.value = defaultValue;
      // } else {
      //   store.dispatch("setFormData", {
      //     key: widgetId,
      //     value: false,
      //   });
      // }
      if (
        typeof defaultValue !== "undefined" &&
        defaultValue.toString() &&
        typeof defaultValue == "boolean"
      ) {
        store.dispatch("setFormData", {
          key: widgetId,
          value: defaultValue,
        });
      }

      value.value = chk_start_value.value;
    });

    const disableTextField = (valueToUpdate) => {
      if (updateTextfieldId) {
        store.dispatch("setTextFieldDisable", {
          [updateTextfieldId]: valueToUpdate,
        });
      }
    };

    const isCheckboxUpdateRequired = (currentCheckBoxValue) => {
      if (!currentCheckBoxValue) {
        const textFieldValue = store.state.formData[updateTextfieldId];
        if (textFieldValue == null) return false;

        store.dispatch("setFormData", {
          key: updateTextfieldId,
          value: "",
        });
        disableTextField(false);
        return false;
      }
      return true;
    };

    onUnmounted(() => {
      disableTextField(false);
    });

    const watchCheckBox = (currentCheckBoxValue) => {
      const updateRequired = isCheckboxUpdateRequired(currentCheckBoxValue);
      if (updateTextfieldId && textFieldStoreKeys.length && updateRequired) {
        const concatedValue = getConcatedValueFromStore(textFieldStoreKeys);

        if (concatedValue) {
          store.dispatch("setFormData", {
            key: updateTextfieldId,
            value: concatedValue,
          });
          disableTextField(true);
        }
      }
    };

    const handleAnalyticsCallOnChange = (label, event) => {
      const analyticObject = activateSegmentsAnalytic[label];
      const makeNewAnalyticObject = {
        cs: {
          bodyParameters: {
            event_screen: "s-annualincome",
            event_type: "click",
            event_sub_type: "checkbox",
            ...analyticObject,
          },
          event_metadata: {
            params: {
              action: event.target.checked ? "check" : "uncheck",
            },
          },
        },
        method: "POST",
        type: "callAnalyitcs",
      };

      ActionHandler.handleAction(makeNewAnalyticObject, {}, props.id);
    };
    watch(value, watchCheckBox);

    return {
      label,
      checkBoxColor,
      isdisabled,
      value,
      rules,
      dependentKeyRule,
      dependentKey,
      checkboxesArr,
      customLabelStyles,
      customCheckBoxStyles,
      disabledLabelOpacity,
      handleAnalyticsCallOnChange,
      isLinkCheckBox,
      labelArr,
      onClickAction,
      customStyles,
      hideDetails,
    };
  },
};
</script>
<style lang="scss" scoped>
* {
  font-family: $font-base-reg !important;
}
:deep {
  .v-selection-control {
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: auto;
    &__input {
      margin-top: -12px;
    }
    .v-label {
      text-align: left;
      white-space: normal;
      height: auto;
    }
  }
  .v-input__details {
    text-align: left;
    padding-left: 10px;
    margin-top: -12px;
  }
  .v-selection-control--disabled {
    opacity: v-bind(disabledLabelOpacity);
  }
}
</style>
