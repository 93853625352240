<template>
  <div class="container">
    <div
      v-for="holder in holderData"
      :key="holder.holderName"
      class="subContainer"
    >
      <text-element-w-value-widget
        :params="{
          hasIconWithElementText: true,
          imageName: 'dual_profile_icon_.svg',
          elementText: holder.holderName,
          customStyles: { display: 'flex' },
          'justify-content': 'flex-start',
        }"
      />
      <FormButton
        v-if="holder.showButton"
        :params="{
          btnText: 'Add more account holder',
          btnColor: 'default',
          btnSize: 'large',
          btnVariant: 'plain',
          customStyles: {
            'font-color': 'blue',
          },
          hasFullMobWidth: true,
          hasIcon: true,
          iconName: 'mdi-plus',
          imageLeftAligned: true,
          onSubmit: onAction,
          hasFullWidth: false,
        }"
      />
    </div>
  </div>
</template>

<script>
import TextElementWValueWidget from "./TextElementWValueWidget.vue";
import ActionHandler from "@/handlers/action";
import store from "@/store/store";
import { ref, watchEffect } from "vue";
import FormButton from "./FormButton.vue";
export default {
  components: { TextElementWValueWidget, FormButton },
  name: "JointAccountHolder",
  props: {
    params: Object,
  },
  setup(props) {
    let holderData = ref();
    let holderArr = ref();

    let isCkycRequired = store.state.features.isCKycEligible;
    if (isCkycRequired) {
      holderArr.value = [
        store.state.formData.firstHolderName || store.state.formData.cKycName,
      ];
      if (holderArr.value.length === 1) {
        if (
          store.state.formData.firstHolderName &&
          store.state.formData.firstHolderName !== store.state.formData.cKycName
        ) {
          holderArr.value = [
            ...holderArr.value,
            store.state.formData.secondHolderName ||
              store.state.formData.cKycName,
          ];
        } else if (store.state.formData.secondHolderName) {
          holderArr.value = [
            ...holderArr.value,
            store.state.formData.secondHolderName,
          ];
        }
      }
      if (holderArr.value.length === 2) {
        if (
          store.state.formData.secondHolderName &&
          store.state.formData.secondHolderName !==
            store.state.formData.cKycName &&
          store.state.formData.firstHolderName !== store.state.formData.cKycName
        ) {
          holderArr.value = [
            ...holderArr.value,
            store.state.formData.thirdHolderName ||
              store.state.formData.cKycName,
          ];
        } else if (store.state.formData.thirdHolderName) {
          holderArr.value = [
            ...holderArr.value,
            store.state.formData.thirdHolderName,
          ];
        }
      }
    } else {
      holderArr.value = [
        store.state.formData.firstHolderName ||
          store.state.formData.panFullName,
      ];
      if (holderArr.value.length === 1) {
        if (
          store.state.formData.firstHolderName &&
          store.state.formData.firstHolderName !==
            store.state.formData.panFullName
        ) {
          holderArr.value = [
            ...holderArr.value,
            store.state.formData.secondHolderName ||
              store.state.formData.panFullName,
          ];
        } else if (store.state.formData.secondHolderName) {
          holderArr.value = [
            ...holderArr.value,
            store.state.formData.secondHolderName,
          ];
        }
      }
      if (holderArr.value.length === 2) {
        if (
          store.state.formData.secondHolderName &&
          store.state.formData.secondHolderName !==
            store.state.formData.panFullName &&
          store.state.formData.firstHolderName !==
            store.state.formData.panFullName
        ) {
          holderArr.value = [
            ...holderArr.value,
            store.state.formData.thirdHolderName ||
              store.state.formData.panFullName,
          ];
        } else if (store.state.formData.thirdHolderName) {
          holderArr.value = [
            ...holderArr.value,
            store.state.formData.thirdHolderName,
          ];
        }
      }
    }
    store.dispatch("setFormData", {
      key: "jointAccountHolderNames",
      value: holderArr,
    });

    watchEffect(() => {
      holderData.value = holderArr.value.map((item, idx) => {
        let currItem = { holderName: item };
        if (
          idx == holderArr.value.length - 1 &&
          holderArr.value.length < 3 &&
          store.state.formData.isJointAccountSubmitted !== "Y"
        ) {
          currItem = { ...currItem, showButton: true };
        }
        return currItem;
      });
    });

    return {
      holderData,
      onAction: props.params.onAction,
    };
  },
};
</script>

<style scoped>
.subContainer {
  display: flex;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
