<template>
  <div id="signature">
    <VueSignaturePad
      border="1"
      width="100%"
      height="100%"
      :options="{
        onBegin,
        onEnd,
        backgroundColor,
        penColor: 'rgb(61, 79, 183)',
      }"
      ref="signaturePad"
      v-model="value"
    />
  </div>
  <div class="divAlignLeft" :class="[{ disabled: !value && !isEnabled }]">
    <v-img
      v-if="!value && !isEnabled"
      :src="getImage('reupload-disabled.svg')"
      width="20"
      height="20"
      class="flex-grow-0"
    ></v-img>
    <v-img
      v-if="value || isEnabled"
      :src="getImage('reupload.svg')"
      width="20"
      height="20"
      class="flex-grow-0"
    ></v-img>
    <span @click="clearSignature" class="clearImage">SIGN AGAIN</span>
  </div>
</template>

<script>
import { VueSignaturePad } from "vue-signature-pad";
import { ref } from "vue";
import { clickStream } from "@/composables/clickStreamAnalytics";
import { getImage } from "@/utils/utils";

export default {
  name: "SignaturePadWidget",
  props: {
    params: Object,
  },
  setup(_, { emit }) {
    const signaturePad = ref(null);
    const isEnabled = ref(false);
    let csEvents = {
      event_screen: "s-signyourapplication",
      event_type: "click",
      event_sub_type: "text",
      event_name: "doitagain",
      event_id: "73.0.0.28.2",
    };
    function clearSignature() {
      isEnabled.value = false;
      // let clickStream = new ClickStreamAnalytics();
      clickStream.addEventList(csEvents);
      return emit("clearSignature", signaturePad.value);
    }
    function onBegin() {
      isEnabled.value = true;
      emit("onBegin", signaturePad.value);
    }
    function onEnd() {
      emit("onEnd", signaturePad.value);
    }

    const backgroundColor = "#FFFFFF";

    return {
      clearSignature,
      onBegin,
      onEnd,
      signaturePad,
      backgroundColor,
      isEnabled,
      getImage,
    };
  },
  components: {
    VueSignaturePad,
  },
};
</script>

<style scoped lang="scss">
#signature {
  border: dashed 2px #c2c6cc;
  border-radius: 5px;
  background-clip: content-box, border-box;
  height: 180px;
  max-width: 550px;
  width: 550px;
  margin: 0 auto;
  padding: 0;
  @media (max-width: 768px) {
    max-width: 300px;
    width: 300px;
  }
}

.container {
  width: "100%";
  padding: 8px 16px;
}

.clearImage {
  font-family: $font-base-reg;
  font-size: $font-size-base;
  cursor: pointer;
  text-align: left;
  color: $primary;
  margin: 0px 4px;
}

.divAlignLeft {
  text-align: left;
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
  justify-content: center;
  &.disabled {
    .v-img,
    span {
      color: $btn-signin-disabled;
    }
  }
}
</style>
