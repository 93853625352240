import store from "@/store/store";

class Analytics {
  static analyticsEventLog(event_params) {
    window.dataLayer?.push({
      event: event_params?.event_name,
      eventCategory: event_params?.event_category,
      eventAction: event_params?.event_action,
      eventLabel: event_params?.event_label
        ? event_params?.event_label !== "NA"
          ? this.getEventLabel(event_params?.event_label)
          : "NA"
        : window.location.href,
      AUC: `${store?.state?.formData?.appNumber}`,
    });
  }
  static getEventLabel(ev) {
    let label;
    switch (ev) {
      case "yes":
        label = "yes";
        break;
      case "no":
      case "do_it_later":
        label = "no";
        break;
      case "android":
        label = "Android";
        break;
      case "ios":
        label = "iOS";
        break;
      default:
        var val = `${store?.state?.formData[ev]}`;
        label = val != "undefined" ? val : "NA";
    }
    return label;
  }
}

export default Analytics;
