<template>
  <h1>{{ textValue }}</h1>
</template>

<script>
import { reactive } from "@vue/reactivity";
export default {
  name: "HorizontalLineWidget",
  props: {
    params: Object,
  },
  setup(props) {
    const { textValue = "OR" } = reactive(props.params);

    return {
      textValue,
    };
  },
};
</script>
<style lang="scss" scoped>
h1 {
  overflow: hidden;
  text-align: center;
  width: 100%;
  font-size: $font-size-base;
  font-family: $font-base-reg;
  color: #5d5e63;
  opacity: 70%;
}

h1::before,
h1::after {
  background-color: #5d5e63;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 45%;
  font-size: $font-size-base;
  font-family: $font-base-reg;
  color: #5d5e63;
  opacity: 50%;

  @media only screen and (min-width: $device-width-md) {
    /*Small smartphones [325px -> 425px]*/
    width: 17%;
  }

  @media only screen and (max-width: $device-width-xs) {
    /*Small smartphones [325px -> 425px]*/
    width: 42%;
  }
}

h1::before {
  right: 0.5em;
  margin-left: -50%;
}

h1::after {
  left: 0.5em;
  margin-right: -50%;
}
</style>
