<template>
  <v-autocomplete
    v-model="value"
    :items="items"
    clearable
    :variant="fieldVariant"
    :density="fieldDensity"
    :item-title="titleField"
    :item-value="valueField"
    @update:search="onSearch"
    @keydown.enter="onEnter"
    @input="onInput"
    size="100"
    :rules="rules"
    :label="textLabel"
    :class="[
      textField,
      { 'closable-icon-position': appendIcon },
      { 'padding-right-7rem': appendIcon },
    ]"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          {{ helpText }}
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
  <div class="info-container" v-if="appendIcon">
    <p @click="handleAction(onApendTap, screenId, widgetId, formRef)">
      {{ searchText }}
    </p>
  </div>
</template>

<script>
import useFormValue from "@/composables/useFormValue";
import { computed, reactive, ref } from "vue";
import store from "@/store/store";
import useCreateValidationRule from "@/composables/useCreateValidationRule";
import ActionHandler from "@/handlers/action";
// import { loadingEnum } from "@/constants/enums";
export default {
  name: "AutoCompleteWidget",
  props: {
    params: Object,
    id: String,
    screenID: String,
    formRef: ref,
    validations: Array,
  },
  emits: ["input"],
  setup(props, { emit }) {
    const widgetId = reactive(props.id);
    const screenId = reactive(props.screenID);
    const ruleString = reactive(props.validations);
    let { value } = useFormValue(widgetId);
    const { rules } = useCreateValidationRule(ruleString);

    const { handleAction } = ActionHandler;
    const {
      textSuffix,
      textLabel,
      helpText,
      dataField,
      titleField,
      textField,
      valueField,
      titleFormat,
      appendIcon,
      appendIconColor = "blue",
      onApendTap,
      fieldVariant,
      fieldDensity,
      searchText = "Search",
    } = reactive(props.params);

    let items = computed(() => {
      const storedResponse = store.state.apiResponses[widgetId];
      const response = storedResponse ? storedResponse[dataField] : [];
      let result = JSON.parse(JSON.stringify(response));
      const joiner = " ";
      if (titleFormat && titleFormat.constructor === Array) {
        result = result.map((row) => {
          const title = titleFormat.reduce((acc, current) => {
            return acc + joiner + row[current];
          }, "");
          return {
            ...row,
            title,
          };
        });
      }
      return result;
    });

    // Update Saerch Field model
    function onSearch(value) {
      if (value) {
        emit("input", value);
      }
      store.dispatch("setFormData", {
        key: `search_${widgetId}`,
        value,
      });
    }

    function onEnter(event) {
      emit("input", event.target.value);
      items.value.map((i) => {
        if (i.ifsc.toUpperCase() == event.target.value.toUpperCase()) {
          value = i.ifsc.toUpperCase();
          store.dispatch("setFormData", {
            key: widgetId,
            value,
          });
          event.target.blur();
          return i.ifsc;
        }
      });
    }

    function onInput(event) {
      store.dispatch("setFormData", {
        key: widgetId,
        value: event.target.value.toUpperCase(),
      });
    }

    return {
      textSuffix,
      textLabel,
      helpText,
      items,
      model: null,
      search: null,
      value,
      onSearch,
      titleField,
      textField,
      valueField,
      rules,
      appendIcon,
      appendIconColor,
      onApendTap,
      handleAction,
      screenId,
      widgetId,
      fieldVariant,
      fieldDensity,
      onEnter,
      onInput,
      searchText,
    };
  },
};
</script>

<style scoped lang="scss">
.info-container {
  padding: 12px;
  margin-top: 0.4rem;
  position: absolute;
  right: 4rem;
  background-color: $white;

  @media (max-width: 600px) {
    right: 2rem;
  }

  p {
    text-transform: uppercase;
    color: $primary;
    font-weight: 600;
    cursor: pointer;
  }
}

.v-text-field {
  :deep {
    .v-autocomplete__selection {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .v-field--prepended {
      padding: 3px 20px !important;

      .v-field__prepend-inner {
        margin-right: -0.5rem;
      }
    }

    .v-field__append-inner {
      display: none;
    }

    .v-field--variant-outlined {
      .v-field__input {
        padding-left: 13px;
      }
    }

    .v-input__details {
      text-align: left;
    }

    .v-field {
      padding: 2px 15px 2px 7px;

      &.v-field--variant-underlined {
        padding-left: 0px;
        padding-right: 0px;
      }

      .v-field__outline {
        .v-field__outline__start,
        .v-field__outline__end,
        .v-field__outline__notch::before,
        .v-field__outline__notch::after {
          border-color: $border-color;
          opacity: 1;
        }
      }

      &--active {
        .v-field__outline {
          .v-field__outline__notch::before {
            border-color: $white;
          }
        }

        &.v-field--error {
          .v-field__outline__notch::before {
            border-color: $white !important;
          }
        }
      }

      &--error {
        .v-field__outline {
          .v-field__outline__start,
          .v-field__outline__end,
          .v-field__outline__notch::before,
          .v-field__outline__notch::after {
            border-color: $danger;
          }
        }
      }
    }
  }

  &.padding-right-7rem {
    :deep .v-field {
      padding-right: 9rem !important;
    }
  }

  &.field-has--error {
    :deep .v-field {
      .v-field-label {
        color: $danger;
        opacity: 1;
      }

      .v-field__outline__start,
      .v-field__outline__end,
      .v-field__outline__notch::before,
      .v-field__outline__notch::after {
        border-color: $danger;
      }

      &--active {
        .v-field__outline {
          .v-field__outline__notch::before {
            border-color: $white;
          }
        }
      }
    }
  }
}
</style>
