import { http, showErrorMessage } from "@/services/HttpClient";

import ActionHandler from "@/handlers/action";
import CleaverTap from "./Clevertap";
import { clickStream } from "@/composables/clickStreamAnalytics";
import store from "@/store/store";

// let clickStream = new ClickStreamAnalytics();

export default function useEsign() {
  async function onFinishEsign(to) {
    const currentUrl = window.location;
    const urlQuery = currentUrl.toString().split("?");
    if (
      currentUrl.toString().includes("personal") ||
      currentUrl.toString().includes("eSignConfirmation")
    ) {
      const urlParams = new URLSearchParams(urlQuery[urlQuery.length - 1]);
      let status =
        urlParams.get("status") || urlParams.get("a1SuccessCallBack");
      let message = urlParams.get("message")
        ? urlParams.get("message").split("#")[0]
        : "";
      let esp = urlParams.get("esp") || null;
      let type = urlParams.get("type") || null;
      let url = to.fullPath;
      let apiValidationAnalytics = {
        event_name: "Esign-getxmlapi",
        event_id: "73.0.0.42.4",
        event_type: "api",
        event_sub_type: "apirequest-response",
        // eslint-disable-next-line
			event_metadata: `{ url: ${url}, status: ${status}, message: ${message} }`,
      };

      if (["TRUE", "SUCCESS"].includes(status?.toUpperCase())) {
        store.dispatch("setEsignLoader", false);
        if (!esp || type !== "SETU") {
          await clickStream.addEventList(apiValidationAnalytics);
        } else {
          if (esp == "nsdl" && type === "SETU") {
            apiValidationAnalytics.event_name = "Esign-getxmlapi_setu_nsdl";
            apiValidationAnalytics.event_id = "73.0.0.42.6";
            await clickStream.addEventList(apiValidationAnalytics);
          } else if (esp == "emudhra" && type === "SETU") {
            apiValidationAnalytics.event_name = "Esign-getxmlapi_setu_emudhra";
            apiValidationAnalytics.event_id = "73.0.0.42.7";
            await clickStream.addEventList(apiValidationAnalytics);
          }
        }
        CleaverTap.addEvents("KYC_Esign_Status", {
          event_screen: "KYC Esign API",
          event_category: "KYC",
          event_action: "kyc_esign_api",
          url,
          status,
          message,
        });
        http
          .post("/v1/kyc/esign/confirmation", {})
          .then(async (res) => {
            ActionHandler.handleAPIData(res?.data?.data);
            ActionHandler.handleAPIAction(res?.data?.action);
            sessionStorage.removeItem("nominees");
            sessionStorage.removeItem("mobile");
          })
          .catch((error) => {
            showErrorMessage(error.message, "esignError");
          })
          .finally(() => {
            store.dispatch("setEsignLoader", true);
          });
      } else if (["FALSE", "FAIL", "FAILURE"].includes(status?.toUpperCase())) {
        store.dispatch("setEsignLoader", false);
        if (!esp) {
          await clickStream.addEventList(apiValidationAnalytics);
        } else {
          if (esp == "nsdl") {
            apiValidationAnalytics.event_name = "Esign-getxmlapi_setu_nsdl";
            apiValidationAnalytics.event_id = "73.0.0.42.6";
            await clickStream.addEventList(apiValidationAnalytics);
          } else if (esp == "emudhra") {
            apiValidationAnalytics.event_name = "Esign-getxmlapi_setu_emudhra";
            apiValidationAnalytics.event_id = "73.0.0.42.7";
            await clickStream.addEventList(apiValidationAnalytics);
          }
        }
        CleaverTap.addEvents("KYC_Esign_Status", {
          event_screen: "KYC Esign API",
          event_category: "KYC",
          event_action: "kyc_esign_api",
          url,
          status,
          message,
        });
        console.warn("Error message from Esign ", message);
        if (message != "User cancelled") {
          showErrorMessage(message, "esignError");
        }
        store.dispatch("setEsignLoader", true);
      }
    }
  }

  return {
    onFinishEsign,
  };
}
