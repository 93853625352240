<template>
  <div class="container">
    <div class="profileNameContainer">
      <text-element-w-value-widget :params="iconProps" />
    </div>
    <div class="esignContainer">
      <div class="cta-class" v-if="addNominee">
        <form-button
          :params="{
            btnText: 'Add nominees',
            btnVariant: 'plain',
            onSubmit: { type: 'navigateToRoute', value: 'nominees' },
            customStyles: 'justify-content:flex-end; text-transform: initial;',
            disabledRule: isOneESignDone,
          }"
        />
      </div>
      <div class="cta-class" v-if="!isKRAEsignCompleted">
        <form-button
          :id="kraEsignID"
          :params="{
            btnText: 'e-Sign KRA Form',
            btnVariant: 'plain',
            onSubmit: kraesignActionRef,
            customStyles: 'justify-content:flex-end; text-transform: initial;',
          }"
        />
      </div>
      <div class="cta-class" v-if="!isJointEsignCompleted">
        <form-button
          :params="{
            btnText: 'e-Sign Account Opening Form',
            btnVariant: 'plain',
            onSubmit: jointEsignActionRef,
            customStyles: 'justify-content:flex-end; text-transform: initial;',
          }"
        />
      </div>
      <div class="cta-class" v-if="isJointEsignCompleted">
        <text-element-widget
          :params="{
            isIconWithPara: true,
            imageName: 'filled-green-check.svg',
            elementText: 'Account Opening form e-Sign completed',
            customStyles:
              'font-size:14px;font-weight:400;cursor:pointer;display:flex;align-items:center;justify-content:end;color:#008F75;gap:0.5rem;',
          }"
        />
      </div>
      <div class="cta-class" v-if="isKRAEsignCompleted">
        <text-element-widget
          :params="{
            isIconWithPara: true,
            imageName: 'filled-green-check.svg',
            elementText: 'KRA form e-Sign completed',
            customStyles:
              'font-size:14px;font-weight:400;cursor:pointer;display:flex;align-items:center;justify-content:end;color:#008F75;gap:0.5rem;',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import FormButton from "./FormButton.vue";
import TextElementWidget from "./TextElementWidget.vue";
import TextElementWValueWidget from "./TextElementWValueWidget.vue";
import store from "@/store/store";
export default {
  name: "JointEsign",
  components: { TextElementWValueWidget, TextElementWidget, FormButton },
  props: {
    params: Object,
  },
  setup(props) {
    const kraEsignID = `${props.params.holderCount}holderKraEsignButton`;
    const iconProps = ref(props.params.iconParams);
    const addNominee = ref(props.params.addNomineeRoute);
    const isJointEsignCompleted = reactive(
      store.state.formData.jointAccountEsign[
        `${props.params.holderCount}HolderCombineEsignStatus`
      ]
    );
    const isKRAEsignCompleted = reactive(
      store.state.formData.jointAccountEsign[
        `${props.params.holderCount}HolderKraEsignStatus`
      ]
    );
    const isOneESignDone = reactive(
      store.state.formData.jointAccountEsign.firstHolderCombineEsignStatus ||
        store.state.formData.jointAccountEsign.secondHolderCombineEsignStatus ||
        store.state.formData.jointAccountEsign?.thirdHolderCombineEsignStatus ||
        store.state.formData.jointAccountEsign.firstHolderKraEsignStatus ||
        store.state.formData.jointAccountEsign.secondHolderKraEsignStatus ||
        store.state.formData.jointAccountEsign?.thirdHolderKraEsignStatus ||
        store.state.formData.nomineesSubmitted
    );

    return {
      iconProps,
      addNominee,
      isJointEsignCompleted,
      isKRAEsignCompleted,
      kraesignActionRef: props.params.kraesignAction,
      jointEsignActionRef: props.params.jointFormEsignAction,
      kraEsignID,
      isOneESignDone,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 500px) {
    height: 8%;
  }
}

.esignContainer {
  display: flex;
  flex-direction: row;
  max-width: 80%;
  align-items: center;
  gap: 1rem;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 0rem;
    justify-content: start;
  }
}

.profileNameContainer {
  width: 20%;
}

.cta-class {
  min-width: 30%;

  @media (max-width: 500px) {
    width: 100%;
  }
}
</style>
