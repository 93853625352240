import { API_RETRY_CONFIG } from "../constants";

const isNetworkError = (error) =>
  error.response && error.message.toLowerCase().indexOf("network error") !== -1;

const isTimeout = (error) =>
  !error.response && error.message.toLowerCase().indexOf("timeout") !== -1;

export const isRetryRequired = (error) => {
  const { config = {}, response = {} } = error;
  const { status } = response;

  if (config.retry) {
    // Set the variable for keeping track of the retry count
    config.__retryCount = config.__retryCount || 0;

    // Check if we've maxed out the total number of retries
    if (
      config.__retryCount >= (config.retryCount || API_RETRY_CONFIG.RETRY_COUNT)
    ) {
      return false;
    }

    const errorCodes =
      config.retryErrorCodes || API_RETRY_CONFIG.RETRY_ERROR_CODES;
    // if error code matches error codes provided in the config
    if (errorCodes.includes(status)) {
      return true;
    }
    // check for timeout or network error
    if (isTimeout(error) || isNetworkError(error)) {
      return true;
    }
  }
  return false;
};

export const getHttpErrorForAnalytics = (error) => {
  if (error.data) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return `server response: ${JSON.stringify(error.data)}, status: ${
      error.status
    }`;
  } else if (error.request) {
    // The request was made but no response was received
    return `no response received, reponseURL: ${error.request.responseURL}, status: ${error.status}`;
  }
  return error?.data?.message;
};
