<template>
  <v-row>
    <v-col
      class="d-flex flex-50"
      v-for="(item, i) in items"
      :key="i"
      :value="item"
    >
      <v-card
        class="pa-6 mb-8"
        color="secondary"
        :variant="cardVariant"
        :class="[{ selected: item.selected }]"
      >
        <div class="bankname">
          <img :src="getImage(item.imageName)" width="24" height="24" />
          <span>{{ item.bankName }}</span>
        </div>
        <v-row no-gutters>
          <v-col
            cols="12"
            :md="child.colWidth"
            class="mb-4"
            v-for="child in item.bankDetail"
            :key="child.id"
          >
            <label>{{ child.labelText }}</label>
            <div>
              <strong>{{ child.labelValue }}</strong>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { reactive } from "vue";
import { getImage } from "../utils/utils";

export default {
  name: "CardWidget",
  props: {
    params: Object,
    style: Object,
  },
  setup(props) {
    const {
      title,
      selected,
      cardVariant = "outlined",
      imgHeight,
      imageWidth,
      imageName,
      items,
      colWidth,
    } = reactive(props.params);

    return {
      title,
      selected,
      cardVariant,
      imgHeight,
      imageWidth,
      imageName,
      items,
      colWidth,
      getImage,
    };
  },
};
</script>

<style scoped lang="scss">
.v-card {
  border: 1px solid $gray-500;
  cursor: pointer;
  width: 100%;
  .v-row.v-row--no-gutters {
    > .v-col,
    > [class*="v-col-"] {
      text-align: left;

      label {
        color: $gray-800;
        font-size: $font-size-sm;
        + {
          strong {
            display: inline;
            line-height: 1.2rem;
            color: $gray-900;
            padding: 0.5rem 0 0 0;
          }
        }
      }
    }
  }
  .bankname {
    display: flex;
    gap: 0.7rem;
    align-items: center;
    margin-bottom: 1rem;
    em {
      border: 0px solid red;
      display: none;
      margin-left: auto;
      &:before {
        content: "";
        display: block;
        clear: both;
        width: 1rem;
        height: 1rem;
        background: url("https://cdn.angelone.in/kyc/images/icon-checked.svg")
          no-repeat;
        background-size: cover;
      }
    }
  }
  &.selected {
    border: 1px solid $primary;
    .bankname {
      em {
        display: block;
      }
    }
  }
}
</style>
