<template>
  <v-row>
    <v-col
      v-for="item in listCompleted"
      :key="item?.name"
      :cols="cols"
      :sm="col_sm"
      class="pa-2"
    >
      <div
        @click.prevent="onClick(item)"
        :class="[
          { borderd: true },
          {
            'borderd-selected':
              item?.type == selectedDocumentState && selectedDocumentFileState,
          },
          {
            check:
              item?.type == selectedDocumentState && selectedDocumentFileState,
          },
          // { disabled: item?.uploaded == true },
        ]"
        :style="customStyles"
        class="radio-button"
      >
        <v-img
          class="mr-3'"
          :src="getImage(item.imageName)"
          width="20"
          height="20"
        >
        </v-img>
        <span class="w-100 text-left"> {{ item?.name }}</span>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { reactive, computed, onMounted, onUpdated, watch } from "vue";
import { useStore } from "vuex";
import { showInfoMessage } from "@/services/HttpClient";
import {
  enableDisableButton,
  getBase64FileType,
  getBase64FromURL,
  getImage,
  urltoFile,
} from "@/utils/utils";
export default {
  name: "DocumentList",
  props: {
    params: Object,
    id: String,
    screenID: String,
    formRef: Object,
    childAction: Function,
    stateId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const widgetId = reactive(props.id);
    const screenId = reactive(props.screenID);
    const ref = reactive(props.formRef);
    const store = useStore();
    const {
      col_sm = 6,
      cols = 6,
      // stateId,
      customStyles,
      selectType,
      fileUrl,
      fileType,
      setFileUrl,
      fileName,
    } = reactive(props.params);
    const selectedDocumentState = computed({
      get() {
        return store.state.formData.derivativesType;
      },
    });
    const selectedDocumentFileState = computed({
      get() {
        return store.state.formData[`${setFileUrl}`];
      },
    });
    const listCompleted = computed({
      get() {
        let list = props.params?.list.map((item) => {
          // console.log(item);
          item.id = item?.type || null;
          return item;
        });
        let list2 = [];
        for (let index = 0; index < list?.length; index++) {
          list2.push(list[index]);
          // if (!list[index]?.uploaded) break;
        }
        return list2;
      },
    });
    async function onClick(item) {
      await store.dispatch("clearDerivativeFile");
      await store.dispatch("setFormData", {
        key: selectType,
        value: item?.type,
      });
      await store.dispatch("setFormData", {
        key: fileType,
        value: item?.type,
      });
      if (item && item?.popup && !item?.uploaded) {
        await store.dispatch("setFormData", {
          key: widgetId,
          value: item?.popup,
        });
      } else {
        if (item?.uploaded) {
          await showInfoMessage(
            "File already uploaded",
            "documentListFileUploaded",
            2000
          );
        }
      }
    }
    function downloadFile() {
      const fileTypeFromName = getBase64FileType(
        store.state.formData[fileName]
      );
      if (fileTypeFromName) {
        getBase64FromURL(fileUrl, fileTypeFromName, (value) => {
          if (value) {
            urltoFile(value, store.state.formData[fileName])
              .then(function (file) {
                if (file) {
                  store.dispatch("setFormData", {
                    key: setFileUrl,
                    value: file,
                  });
                }
              })
              .then(() => {
                store.dispatch("setFormData", {
                  key: selectType,
                  value: store.state.formData[fileType],
                });
              })
              .finally(() => {
                enableDisableButton(
                  store.state.formData[fileType],
                  store.state.formData[setFileUrl]
                );
              });
          }
        });
      }
    }
    onMounted(() => {
      downloadFile();
    });

    watch(
      () => store.state.formData[fileUrl],
      (value) => {
        if (value) {
          downloadFile();
        }
      }
    );

    onUpdated(() => {
      enableDisableButton(
        store.state.formData[fileType],
        store.state.formData[setFileUrl]
      );
    });

    return {
      widgetId,
      screenId,
      col_sm,
      cols,
      listCompleted,
      onClick,
      customStyles,
      ref,
      selectedDocumentState,
      selectedDocumentFileState,
      getImage,
    };
  },
};
</script>

<style scoped lang="scss">
:deep {
  .v-selection-control__wrapper {
    display: none;
  }
  .v-input__details {
    display: none;
  }
}
.radio-button {
  @media (max-width: 600px) {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    gap: 2px;
  }
  padding: 20px;
  height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
  transition: all 333ms ease-in;
  gap: 10px;
  &::after {
    background: url("https://cdn.angelone.in/kyc/images/icon-checked.svg")
      no-repeat;
    width: 25px;
    height: 25px;
    min-width: 25px;
    position: relative;
    right: 0;
    content: "";
    display: block;
    clear: both;
    opacity: 0;
  }
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    max-width: calc(100% - 20px);
    font-size: $font-size-sm;
    color: $gray-900;
    font-weight: 500;
    @media (min-width: $device-width-md) {
      font-size: $font-size-base;
    }
  }
}
.borderd {
  border: 1px solid $border-color;
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box;
  border-radius: 3px;
  position: relative;
  &-selected {
    border: 1px solid rgba(23, 97, 170, 0.7) !important;
  }
  &:hover {
    transition: all 333ms ease-in;
    border-color: rgba(23, 97, 170, 0.7) !important;
  }
  &.check {
    &::after {
      opacity: 1;
    }
  }
}
.mr-3 {
  margin-right: 5px;
}
</style>
