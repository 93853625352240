<template>
  <p
    :class="textCenteredAligned ? 'text-center w-100' : 'text-left'"
    :style="customStyles"
    v-if="isPara"
  >
    {{ elementText }}
  </p>
  <small
    :class="textCenteredAligned ? 'text-center w-100' : 'text-left'"
    v-if="isSmall"
  >
    {{ elementText }}
  </small>
  <p
    :class="textCenteredAligned ? 'text-center w-100' : 'text-left'"
    :style="customStyles"
    v-if="isBoldwithPara"
  >
    <b>{{ boldElementText }}</b
    ><span>{{ elementText }}</span>
  </p>
  <p
    :class="textCenteredAligned ? 'text-center w-100' : 'text-left'"
    :style="customStyles"
    v-if="isBoldInMiddle"
  >
    <span>{{ elementText }}</span
    ><b>{{ boldElementText }}</b
    ><span>{{ elementText1 }}</span>
  </p>
  <p
    :class="textCenteredAligned ? 'text-center w-100' : 'text-left'"
    :style="customStyles"
    v-if="isBluePartial"
  >
    <span>{{ elementText }}</span>
    <span style="color: #3f5bd9; cursor: pointer" @click="onClickAction">
      {{ blueElementText }}
    </span>
    <span>{{ elementText2 }}</span>
  </p>
  <p
    :class="textCenteredAligned ? 'text-center w-100' : 'text-left'"
    :style="customStyles"
    v-if="isDigilockerPara"
  >
    <span v-if="elementText">{{ elementText }}</span>
    <b v-if="boldElementText">{{ boldElementText }}</b>
    <span v-if="elementText1">{{ elementText1 }}</span>
    <a v-if="hyperlinkValue" :href="hyperlinkValue" class="hyperlinkClass">{{
      hyperlinkText
    }}</a>
    <span v-if="elementText2">{{ elementText2 }}</span>
  </p>
  <p
    :class="textCenteredAligned ? 'text-center w-100' : 'text-left'"
    :style="customStyles"
    v-if="isIconWithPara"
  >
    <img :src="getImage(imageName)" width="16" height="16" />
    <span>{{ elementText }}</span>
  </p>
  <p
    :class="textCenteredAligned ? 'text-center w-100' : 'text-left'"
    :style="customStyles"
    v-if="isBoldInMiddleWithIcon"
  >
    <img :src="getImage(imageName)" width="16" height="16" />
    <span>
      <span>{{ elementText }}</span
      ><b>{{ boldElementText }}</b
      ><span>{{ elementText1 }}</span></span
    >
  </p>
</template>

<script>
import ActionHandler from "@/handlers/action";
import { reactive } from "@vue/reactivity";
import { getImage } from "@/utils/utils";
export default {
  name: "TextElementWidget",
  props: {
    params: Object,
  },
  setup(props) {
    const {
      elementText,
      boldElementText,
      isBoldwithPara,
      isPara,
      isSmall,
      textCenteredAligned,
      customStyles,
      isBluePartial,
      blueElementText,
      isDigilockerPara,
      hyperlinkText,
      hyperlinkValue,
      elementText1,
      elementText2,
      requestAction,
      isBoldInMiddle,
      imageName,
      isIconWithPara,
      isBoldInMiddleWithIcon,
    } = reactive(props.params);

    function onClickAction() {
      ActionHandler.handleAction(requestAction, "", {});
    }

    return {
      elementText,
      boldElementText,
      isBoldwithPara,
      isPara,
      isSmall,
      textCenteredAligned,
      customStyles,
      isBluePartial,
      blueElementText,
      isDigilockerPara,
      hyperlinkText,
      hyperlinkValue,
      elementText1,
      elementText2,
      onClickAction,
      isBoldInMiddle,
      requestAction,
      imageName,
      isIconWithPara,
      getImage,
      isBoldInMiddleWithIcon,
    };
  },
};
</script>

<style lang="scss">
p {
  font-size: $font-size-base;
  line-height: 24px;
  font-weight: 400;
}

small {
  font-size: $font-size-sm;
}

.hyperlinkClass {
  color: #3f5bd9;
  text-decoration: none;
}
</style>
