<template>
  <div class="container">
    <p v-if="planName">
      Your plan is
      <span class="plan" @click="openBrokeragePopup">
        {{ planName }}
      </span>
    </p>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { ref } from "vue";
import { watchEffect } from "vue";
import { reactive } from "vue";
import ActionHandler from "@/handlers/action";

const props = defineProps({
  params: Object,
  id: String,
  layoutData: Object,
});

const widgetId = reactive(props.id);

const store = useStore();

const planName = ref("");
const clientType = ref("");

watchEffect(() => {
  clientType.value = store?.state?.formData?.clientType;
  planName.value = store?.state?.apiResponses?.[widgetId]?.["planName"] || "";
});

const openBrokeragePopup = () => {
  ActionHandler.handleAction(
    [
      {
        type: "openPopup",
        value: "brokeragePopup",
      },
    ],
    "",
    {}
  );
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  padding: 2px 2px 12px 2px;
}

.plan {
  color: $primary !important;
  cursor: pointer;
}
</style>
