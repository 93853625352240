<template>
  <p
    :class="textCenteredAligned ? 'text-center w-100' : 'text-left'"
    :style="customStyles"
    v-if="isPara"
  >
    {{ elementText }}
  </p>
  <small
    :class="textCenteredAligned ? 'text-center w-100' : 'text-left'"
    v-if="isSmall"
  >
    {{ elementText }}
  </small>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { useStore } from "vuex";
export default {
  name: "DynamicTextElementWidget",
  props: {
    params: Object,
  },
  setup(props) {
    const {
      formDataStateKey,
      isPara,
      isSmall,
      textCenteredAligned,
      customStyles,
    } = reactive(props.params);
    const store = useStore();
    const elementText = reactive(store.state.formData[formDataStateKey]);
    return {
      elementText,
      isPara,
      isSmall,
      textCenteredAligned,
      customStyles,
    };
  },
};
</script>

<style lang="scss">
// p,
// small {
//   text-align: left;
// }
p {
  font-size: $font-size-base;
  line-height: 24px;
  font-weight: 400;
}
small {
  font-size: $font-size-sm;
}
</style>
