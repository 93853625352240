import { http, showErrorMessage } from "@/services/HttpClient";

import ActionHandler from "@/handlers/action";
import { clickStream } from "@/composables/clickStreamAnalytics";
import store from "@/store/store";

// let clickStream = new ClickStreamAnalytics();

export default function useCams() {
  async function onFinishCams(to) {
    const currentUrl = window.location;
    const urlQuery = currentUrl.toString().split("?");
    if (currentUrl.toString().includes("cams")) {
      const urlParams = new URLSearchParams(urlQuery[urlQuery.length - 1]);
      let status = urlParams.get("redirection");
      let url = to.fullPath;
      let apiValidationAnalytics = {
        event_name: "Cams-getxmlapi",
        event_id: "73.0.0.43.7",
        event_type: "api",
        event_sub_type: "apirequest-response",
        // eslint-disable-next-line
          event_metadata: `{ url: ${url}, status: ${status} }`,
      };
      if (status === "true" || status === "True") {
        store.dispatch("setCamsLocader", false);
        http
          .post("/v1/kyc/cams/status", {})
          .then(async (res) => {
            ActionHandler.handleAPIData(res?.data?.data);
            ActionHandler.handleAPIAction(res?.data?.action);
            store.dispatch("setCamsLocader", true);
            if (res?.data?.data?.status == "failure") {
              showErrorMessage(res?.data?.data?.message, "camsError");
            }
            await clickStream.addEventList(apiValidationAnalytics);
          })
          .catch((error) => {
            showErrorMessage(error.message, "camsError");
            store.dispatch("setCamsLocader", true);
          })
          .finally(() => {
            store.dispatch("setCamsLocader", true);
          });
      } else if (status === "false" || status === "False") {
        // await clickStream.addEventList(apiValidationAnalytics);
        store.dispatch("setCamsLocader", true);
      }
    }
  }

  return {
    onFinishCams,
  };
}
